import React, { useContext, useEffect } from 'react';
import { Homepage } from '..';
import { PATHS, PROGRAM_STATUS } from 'state/config';
import { useHistory } from 'react-router';
import { AppStateContext } from 'state/context';
import { getActivePos, getModuleUrl } from 'state/helpers/timeline';
import { LoadingSpinner } from '@gymondo/frontend-core/components';
import { clearStorage, getStoredProductId, isStorageSet } from 'state/session';

const Dashboard: React.FC = () => {
    const { appState } = useContext(AppStateContext);
    const history = useHistory();

    /* these actions are needed if logo/startpage-url via React-Router <Link> is clicked, the page will be not reloaded (no re-init of app) */
    useEffect(() => {
        if (appState.user.isLoaded) {
            // redirect to payment if not purchased
            if (!appState.user.paymentCompleted && getStoredProductId() !== 0) {
                history.push(PATHS.PAYMENT);
                return;
            }
            // redirect to onboarding if not completed
            if (!appState.user.onboardingCompleted) {
                history.push(PATHS.ONBOARDING);
                return;
            }
            // if dashboard is empty -> user is navigated to Start you program on the new platform screen
            if (appState.user.paymentCompleted && appState.user.dashboard.plans.length === 0) {
                history.push(PATHS.NOT_FOUND);
                return;
            }
            // redirect to confirmation if current program completed or has old purchases
            if (
                appState.user.programStatus === PROGRAM_STATUS.EXPIRED ||
                (appState.user.programStatus === PROGRAM_STATUS.UNSET && appState.user.purchaseHistory.length > 0)
            ) {
                history.push(PATHS.PARTICIPATION_CONFIRMATION);
                return;
            }
        }
    }, [appState.user]);

    if (appState.isAuthenticated) {
        if (appState.user.isLoaded) {
            // the main action for timeline redirect to module if authenticated
            if (appState.user.resources.length > 0) {
                // clear preLogin storage
                if (isStorageSet()) {
                    clearStorage();
                }
                const activePos = getActivePos(appState.user.dashboard.schedules);
                const moduleUrl = getModuleUrl(activePos + 1);
                history.push(moduleUrl);
            }
        }
        return <LoadingSpinner type="page" />;
    }

    return <Homepage />;
};
export default Dashboard;
