import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@gymondo/frontend-core/typography';
import { GYM_12M_REWARD_PRODUCTS, PATHS } from 'state/config';
import {
    icCheck,
    ImgBenefits,
    LPNewBackfitHeader,
    LPNewBackfitHeaderMobile,
    LPNewBackfitLogo,
    LPNewBackfitPricing,
    LPNewBackfitProgram01,
    LPNewBackfitProgram02,
    LPNewBackfitProgram03,
    Seal,
    LPNewBackfitTrainer,
} from '../../assets';

import { cutAtChar } from 'utils/string';
import VideoPreview from './VideoPreview';
import useProgramPage from './useProgramPage';
import { getBackfitProgram } from 'state/helpers/program';
import ReimbursementRateCalculator from 'components/common/ReimbursementRateCalculator';
import ReimbursementInfo from 'components/common/ReimbursementInfo';
import style from './Backfit.module.scss';
import cn from 'classnames';
import { useMediaQuery } from '@gymondo/frontend-core/hooks';

const BackFit: React.FC = () => {
    const program_id = getBackfitProgram().ID;
    const course_id = getBackfitProgram().COURSE_ID;
    const { handleVideoPreview, isFreebieUser, JsonReader, showVideo, productId } = useProgramPage(program_id);
    const isRewardProgram = !!productId && GYM_12M_REWARD_PRODUCTS.includes(productId);
    const isXs = useMediaQuery('(max-width: 767px)');

    return (
        <>
            <div className={cn('container-fluid no-padding lp-hero first-container bg-white')}>
                <div className="pos-absolute">
                    <div className="wrapper">
                        <div className="row">
                            <div className="image-module col">
                                <div>
                                    <img
                                        className={cn('lp-header-image', style.logo)}
                                        src={LPNewBackfitLogo}
                                        alt="Rücken fit & gesund"
                                    />
                                    {!isXs && (
                                        <img
                                            className={style.seal}
                                            src={Seal}
                                            alt="Kostenerstattung durch deine Krankenkasse"
                                        />
                                    )}
                                </div>
                                <Typography className={style.description} as="p">
                                    {JsonReader.program__get('heroPane.description', isRewardProgram)}
                                    <Typography as="span" variant="h6" color="inverted">
                                        {' '}
                                        {JsonReader.program__get('heroPane.boldDescription', isRewardProgram)}
                                    </Typography>
                                </Typography>
                                <Typography as="div" variant="h6" color="inverted" className={style.headerText}>
                                    Kurs-ID: {course_id}
                                </Typography>
                                {isXs && (
                                    <img
                                        className={style.sealXs}
                                        src={Seal}
                                        alt="Kostenerstattung durch deine Krankenkasse"
                                    />
                                )}
                                <div className="cta-wrapper">
                                    <Link to={PATHS.REGISTRATION} className="btn-gym">
                                        {JsonReader.program__get('heroPane.ctaMain')}
                                    </Link>
                                    <div className="btn-gym btn--withicon" onClick={handleVideoPreview}>
                                        <svg x="0px" y="0px" viewBox="0 0 12 16">
                                            <polygon points="12,8 0,0 0,16 " />
                                        </svg>
                                        {JsonReader.program__get('heroPane.ctaPreview')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image__background">
                    <img className="lp-header-image" src={LPNewBackfitHeader} alt="Kopfzeile" />
                    <img
                        className={cn('lp-header-image mobile', style.mobile)}
                        src={LPNewBackfitHeaderMobile}
                        alt="Mobile Kopfzeile"
                    />
                </div>
            </div>
            <div className="bg-white">
                <div className="wrapper lp-programs">
                    <div className="row margin">
                        {JsonReader.program__getUsp().map((_elem, _index) => (
                            <div key={_index} className="col-xs-12 col-sm-4 col-md-4 box-program">
                                <img
                                    className="image"
                                    src={
                                        _index === 0
                                            ? LPNewBackfitProgram01
                                            : _index === 1
                                            ? LPNewBackfitProgram02
                                            : LPNewBackfitProgram03
                                    }
                                    alt="USP"
                                />
                                <h6>{_elem.title}</h6>
                                <p dangerouslySetInnerHTML={{ __html: _elem.description }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-white lp-price-padding">
                <div className="wrapper homepage-benefits lp-price bg-beige">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {!isFreebieUser && (
                                <div className="badge-price">
                                    <div>
                                        <span className="price">
                                            {cutAtChar(JsonReader.program__get('pricePane.price.amount'))}
                                            {JsonReader.program__get('pricePane.price.currency.symbol')}
                                        </span>
                                        <span className="small">
                                            {JsonReader.program__get('pricePane.price.taxes')}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <img className="image-benefits" src={LPNewBackfitPricing} alt="Deine Vorteile" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 padding-left">
                            <h4>{JsonReader.program__get('pricePane.title')}</h4>
                            {JsonReader.program__getPriceListItems(isRewardProgram).map((_elem, _index) => (
                                <div key={_index} className="entry">
                                    <img className="image" src={icCheck} alt="Vorteil" />
                                    <p>{_elem}</p>
                                </div>
                            ))}
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.program__get('pricePane.cta')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="wrapper homepage-programs lp-programs">
                    <ReimbursementInfo
                        JsonReader={JsonReader}
                        isFreebieUser={isFreebieUser}
                        isReward={isRewardProgram}
                    />
                </div>
            </div>
            <div className="bg-white">
                <ReimbursementRateCalculator JsonReader={JsonReader} isFreebieUser={isFreebieUser} />
                <div className="wrapper lp-coach">
                    <div className="row">
                        <div className="col-xs-12 col-sm-7 col-md-6 padding-left">
                            <h4>{JsonReader.program__get('trainerPane.title')}</h4>
                            {JsonReader.program__getTrainerContent().map((_elem, _index) => (
                                <p key={_index}>
                                    {_elem.title && <strong>{_elem.title}</strong>}
                                    {_elem.title && <br />}
                                    {_elem.description}
                                </p>
                            ))}
                            <div className="btn-gym btn--withicon" onClick={handleVideoPreview}>
                                <svg x="0px" y="0px" viewBox="0 0 12 16">
                                    <polygon points="12,8 0,0 0,16 " />
                                </svg>
                                {JsonReader.program__get('trainerPane.ctaPreview')}
                            </div>
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.program__get('trainerPane.ctaMain')}
                            </Link>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-6 padding-left">
                            <img className="image image-rounded" src={LPNewBackfitTrainer} alt="Patricio Escher" />
                        </div>
                    </div>
                </div>
                <div className="wrapper homepage-benefits lp-benefits">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <img className="image-benefits" src={ImgBenefits} alt="Vorteile" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 last">
                            <h4>{JsonReader.get('summaryPane.title')}</h4>
                            {JsonReader.getSummaryItems(isRewardProgram).map((_elem, _index) => (
                                <div key={_index} className="entry">
                                    <img className="image" src={icCheck} alt="Vorteil" />
                                    <p>{_elem}</p>
                                </div>
                            ))}
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.get('summaryPane.cta')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {showVideo && (
                <div className="overlay-video-player">
                    <div className="overlay-video-player__container">
                        <VideoPreview url={JsonReader.program__get('heroPane.videoURL')} />
                        <div className="overlay-video-player__container__close" onClick={handleVideoPreview}>
                            &times;
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default BackFit;
