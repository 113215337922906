import React from 'react';
import { Formik, Form } from 'formik';
import { Button } from '@gymondo/frontend-core/components';
import text from '../../lang';

interface ReplaceProgramProps {
    programName: string;
    handleAccept: () => void;
    handleReject: () => void;
}

const ReplaceProgramForm: React.FC<ReplaceProgramProps> = ({ programName, handleAccept, handleReject }) => {
    return (
        <>
            <p>
                Wir haben festgestellt, dass du bereits an einem aktiven Präventionsprogramm teilnimmst. Bitte beachte,
                dass du nur ein Präventionsprogramm gleichzeitig nutzen kannst. Wenn du ein neues Programm startest,
                dann geht dein Fortschritt im laufenden Programm verloren. Bist du dir sicher, dass du{' '}
                <b>{programName}</b> neu starten willst?
            </p>
            <Formik
                initialValues={{}}
                onSubmit={async () => {
                    try {
                        await handleAccept();
                    } catch (error: unknown) {
                        const _message = (error as Record<string, unknown>).message as string;
                        console.log(_message);
                    }
                }}
                onReset={handleReject}
            >
                {() => (
                    <Form>
                        <div className="row end-xs">
                            <Button htmlType="submit" onClick={handleAccept}>
                                {text('signup_replace_program_accept')}
                            </Button>
                            <Button htmlType="reset" className="btn--border" onClick={handleReject}>
                                {text('signup_replace_program_reject')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ReplaceProgramForm;
