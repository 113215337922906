import React, { useState } from 'react';
import { Field, FieldProps, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { FormSubmissionError } from '..';
import { getStoredProgramId } from '../../state/session';
import { PATHS } from '../../state/config';

interface SignInFormData {
    email: string;
    password: string;
}

interface SignInFormProps {
    onSubmit: (data: SignInFormData) => void;
    onClickResetPassword: () => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ onSubmit, onClickResetPassword }) => {
    const [formSubmissionError, setFormSubmissionError] = useState('');

    const programId = getStoredProgramId();

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validationSchema={Yup.object({
                email: Yup.string().required(text('validation_email_note')).email(text('validation_email_invalid')),
                password: Yup.string().required(text('validation_password_note')),
                //.min(8, text('validation_password_invalid')),
            })}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    await onSubmit(data);
                } catch (error: unknown) {
                    setSubmitting(false);
                    const _message = (error as Record<string, unknown>).message as string;
                    setFormSubmissionError(_message);
                }
            }}
        >
            {({ dirty, isSubmitting, isValid }) => (
                <Form>
                    {formSubmissionError && <FormSubmissionError message={formSubmissionError} className="" />}
                    <Field name="email">
                        {({ field, form: { errors, touched } }: FieldProps) => (
                            <Input
                                field={{
                                    ...field,
                                    placeholder: text('placeholder_your_email'),
                                    type: 'email',
                                }}
                                form={{
                                    errors: errors,
                                    touched: touched,
                                }}
                                multiline={false}
                            />
                        )}
                    </Field>
                    <Field name="password">
                        {({ field, form: { errors, touched } }: FieldProps) => (
                            <Input
                                field={{
                                    ...field,
                                    placeholder: text('placeholder_your_password'),
                                    type: 'password',
                                }}
                                form={{
                                    errors: errors,
                                    touched: touched,
                                }}
                                multiline={false}
                            />
                        )}
                    </Field>
                    <span className="reset-password-link" onClick={onClickResetPassword}>
                        {text('reset_password_text')}
                    </span>
                    <Button htmlType="submit" disabled={!dirty || isSubmitting || !isValid} fluid>
                        {text('signin_button')}
                    </Button>
                    {programId !== 0 && (
                        <div className="bottom-register">
                            {text('not_registered_text')}{' '}
                            <a href={PATHS.REGISTRATION}>{text('register_and_start_text')}</a>
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default SignInForm;
