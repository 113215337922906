import React, { useCallback, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
/* set region cookies for dev - needed for CookieConsent */
import { getEnvVar, isTestEnv } from 'utils/env';
import jsCookie from 'js-cookie';
import { COOKIE_NAMES } from '@gymondo/frontend-core/enums';
/**/
import { dispatch, DispatchActions, isAuthenticated } from 'state/actions';
import {
    getStoredProductId,
    initialStorage,
    initStorage,
    isStorageSet,
    storeChannelId,
    storePartnerKey,
    storeProductId,
    storeProgramId,
    storeShowVoucher,
    storeVoucherCode,
} from 'state/session';
import { AppProps, AppStateContext, initialAppState, initialUser, ProtectedAppProps, UserProps } from 'state/context';
import config, { GYM_12M_REWARD_PRODUCTS, PATHS, PROGRAM_STATUS } from './state/config';

import { Alert, CookieConsent, LoadingSpinner } from '@gymondo/frontend-core/components';

import RouterWrapper from './components/system/RouterWrapper';
import { ProtectedRoute, ProtectedRouteProps } from 'components/system/ExtendetRoute';
import {
    Account,
    Container,
    Dashboard,
    Faq,
    Ganzkoerper,
    Imprint,
    NotFound,
    Onboarding,
    ParticipationConfirmation,
    Payment,
    PaymentConfirmation,
    PurchaseNotAllowed,
    SignUp,
    Terms,
    Timeline,
    BackFit,
} from './components';
import { assertUserData } from 'state/helpers/user';
import { hasLocationPath, isLocationPath, redirect, useLocationSearchParam } from 'utils/system';
import { ApiError } from 'utils/error';
import {
    getBackfitProgram,
    getGanzkoerperProgram,
    getPartner,
    getProgramPath,
    isPartnerKeyExists,
} from 'state/helpers/program';
import PromptHealthInsurance from 'components/common/PromptHealthInsurance';

if (isTestEnv) {
    jsCookie.set(COOKIE_NAMES.USER_LOCALE, 'de_DE');
    jsCookie.set(COOKIE_NAMES.USER_REGION, 'DE');
}

const App: React.FC<AppProps> = ({ appState, refreshAppState }) => {
    const defaultProtectedRouteProps: ProtectedRouteProps = {
        isAuthenticated: appState.isAuthenticated,
        authenticationPath: PATHS.HOMEPAGE,
    };

    const GTM_ID = getEnvVar('GTM_ID');
    const GTM_LOAD_FALLBACK_URL = getEnvVar('GTM_LOAD_FALLBACK_URL');

    return (
        <AppStateContext.Provider value={{ appState, refreshAppState }}>
            <Router>
                <RouterWrapper>
                    <Switch>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.ACCOUNT}>
                            <Container>
                                <PromptHealthInsurance />
                                <Account />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.PAYMENT_CONFIRMATION}>
                            <Container>
                                <PromptHealthInsurance />
                                <PaymentConfirmation />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.PARTICIPATION_CONFIRMATION}>
                            <Container>
                                <PromptHealthInsurance />
                                <ParticipationConfirmation />
                            </Container>
                        </ProtectedRoute>
                        <Route exact path={PATHS.REGISTRATION}>
                            <Container smallFooter transparentHeader>
                                <SignUp />
                            </Container>
                        </Route>
                        <Route path={PATHS.REGISTRATION_PROGRAM}>
                            <Container smallFooter transparentHeader>
                                <SignUp />
                            </Container>
                        </Route>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.NOT_FOUND}>
                            <Container smallFooter>
                                <NotFound />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.PURCHASE_NOT_ALLOWED}>
                            <Container smallFooter>
                                <PurchaseNotAllowed />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.ONBOARDING}>
                            <Container smallHeader smallFooter transparentFooter>
                                <Onboarding />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.PAYMENT}>
                            <Container smallFooter>
                                <Payment />
                            </Container>
                        </ProtectedRoute>
                        <ProtectedRoute {...defaultProtectedRouteProps} exact path={PATHS.TIMELINE}>
                            <Container>
                                <PromptHealthInsurance />
                                <Timeline />
                            </Container>
                        </ProtectedRoute>
                        <Route exact path={getProgramPath(getBackfitProgram().NAME)}>
                            <Container transparentHeader textWhiteHeader>
                                <BackFit />
                            </Container>
                        </Route>
                        <Route exact path={getProgramPath(getGanzkoerperProgram().NAME)}>
                            <Container transparentHeader textWhiteHeader>
                                <Ganzkoerper />
                            </Container>
                        </Route>
                        <Route exact path={PATHS.TERMS}>
                            <Container>
                                <Terms />
                            </Container>
                        </Route>
                        <Route exact path={PATHS.IMPRINT}>
                            <Container>
                                <Imprint />
                            </Container>
                        </Route>
                        <Route exact path={PATHS.FAQ}>
                            <Container>
                                <Faq />
                            </Container>
                        </Route>
                        <Route path={PATHS.HOMEPAGE}>
                            <Container textWhiteHeader>
                                <Dashboard />
                            </Container>
                        </Route>
                    </Switch>
                </RouterWrapper>
            </Router>
            <CookieConsent locale="de" GTMId={GTM_ID} GTMLoadFallbackUrl={GTM_LOAD_FALLBACK_URL} />
        </AppStateContext.Provider>
    );
};

const ProtectedApp: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [initialHasRun, setInitialHasRun] = useState(false);
    const [appState, setAppState] = useState<ProtectedAppProps>(initialAppState);

    const refreshAppState = useCallback((newAppState: ProtectedAppProps) => {
        setAppState(newAppState);
    }, []);

    useEffect(() => {
        // set session program id and product id, returning program from config
        const initSession = () => {
            // first init
            if (!isStorageSet()) {
                initStorage();
            }
            // set partner from url parameter
            let _partner_key = String(useLocationSearchParam('partner')).toLowerCase();
            const _partner_key_exists = isPartnerKeyExists(_partner_key);
            if (_partner_key !== '' && _partner_key_exists) {
                storePartnerKey(_partner_key);
            }
            // set program id from url parameter
            const _program_id = Number(useLocationSearchParam('program_id'));
            if (config.PROGRAMS.map((p) => p.ID).includes(_program_id)) {
                storeProgramId(_program_id);
                if (!_partner_key_exists) {
                    _partner_key = initialStorage.partnerKey;
                }
                const partner = getPartner(_program_id, _partner_key);
                if (partner) {
                    storeProductId(partner.PRODUCT_ID);
                }
            }

            const channelId = Number(useLocationSearchParam('channel_id'));
            if (channelId) {
                storeChannelId(channelId);
            }

            // set voucher from url parameter
            const _voucher_code = String(useLocationSearchParam('voucher_code'));
            if (_voucher_code) {
                storeVoucherCode(_voucher_code);
            }
            const _show_voucher = Boolean(useLocationSearchParam('show_voucher'));
            if (_show_voucher) {
                storeShowVoucher(_show_voucher);
            }
        };
        const initUser = async () => {
            try {
                setInitialHasRun(true);
                const user: UserProps = await assertUserData();
                setAppState({ ...appState, user: user });
                /* REDIRECTS */
                if (user.isLoaded) {
                    const isStaticProgramPage =
                        isLocationPath(getProgramPath(getGanzkoerperProgram().NAME)) ||
                        isLocationPath(getProgramPath(getBackfitProgram().NAME));
                    const isNotFoundPage = isLocationPath(PATHS.NOT_FOUND);
                    const isPurchaseNotAllowed = isLocationPath(PATHS.PURCHASE_NOT_ALLOWED);
                    const isOnboardingPage = isLocationPath(PATHS.ONBOARDING);
                    const isPaymentPage = isLocationPath(PATHS.PAYMENT);
                    const isTimeline = hasLocationPath(PATHS.TIMELINE.replace(':moduleId', ''));
                    const isLegalPages =
                        isLocationPath(PATHS.TERMS) || isLocationPath(PATHS.IMPRINT) || isLocationPath(PATHS.FAQ);
                    const isConfirmationPages =
                        isLocationPath(PATHS.PAYMENT_CONFIRMATION) || isLocationPath(PATHS.PARTICIPATION_CONFIRMATION);

                    // GYMONDO USER LOGIN - NO PREVENTION PLANS
                    if (
                        user.purchaseHistory.length === 0 &&
                        user.planId === initialUser.planId &&
                        !user.paymentCompleted &&
                        getStoredProductId() === 0 &&
                        !isNotFoundPage &&
                        !isPaymentPage
                    ) {
                        redirect(PATHS.NOT_FOUND);
                        return;
                    }

                    // OLD PP USERS TRYING TO REGISTER - THERE'S PAYMENT CONTEXT
                    if (
                        user.purchaseHistory.length > 0 &&
                        user.planId === initialUser.planId &&
                        !user.paymentCompleted &&
                        !user.hasEndedPlans &&
                        getStoredProductId() != 0 &&
                        !isPaymentPage
                    ) {
                        redirect(PATHS.PAYMENT);
                        return;
                    }

                    // OLD PP USERS SIGNING IN - NO PAYMENT CONTEXT
                    if (
                        user.purchaseHistory.length > 0 &&
                        user.planId === initialUser.planId &&
                        getStoredProductId() === 0 &&
                        !isConfirmationPages
                    ) {
                        redirect(PATHS.PARTICIPATION_CONFIRMATION);
                        return;
                    }

                    // first check if payment completed, only redirect if there's payment context
                    if (!user.paymentCompleted && getStoredProductId() != 0 && !isPaymentPage && !isLegalPages) {
                        if (
                            GYM_12M_REWARD_PRODUCTS.includes(getStoredProductId()) &&
                            user.account.roles?.includes('ROLE_USER')
                        ) {
                            // if reward program, only users with NO active subscription can purchase
                            // let 'after handleSignUp' in Signup component handle the logic
                            return;
                        }
                        redirect(PATHS.PAYMENT);
                        return;
                    }
                    // if payment completed, then check if onboarding completed
                    if (user.paymentCompleted && !user.onboardingCompleted && !isOnboardingPage && !isLegalPages) {
                        redirect(PATHS.ONBOARDING);
                        return;
                    }

                    // if payment and onboarding completed
                    if (user.paymentCompleted && user.onboardingCompleted) {
                        // do not allow to get this preLogin pages
                        if (isPaymentPage || isOnboardingPage || isStaticProgramPage || isPurchaseNotAllowed) {
                            redirect(PATHS.HOMEPAGE);
                            return;
                        }
                    }
                    // program expired
                    if (user.programStatus === PROGRAM_STATUS.EXPIRED && isTimeline) {
                        redirect(PATHS.PARTICIPATION_CONFIRMATION);
                        return;
                    }
                }
                setLoading(false);
            } catch (error: unknown) {
                const _err = error as Record<string, unknown>;
                console.log(_err);
                const apiError = new ApiError(_err);
                setError(apiError.message);
            }
        };
        const readStaticData = async () => {
            try {
                const staticData = await dispatch(DispatchActions.getContentPreLogin);
                setAppState({ ...appState, staticData });
                setLoading(false);
            } catch (error: unknown) {
                const _err = error as Record<string, unknown>;
                console.log(_err);
                const apiError = new ApiError(_err);
                setError(apiError.message);
            }
        };
        // not authenticated - read token from storage
        if (!appState.isAuthenticated) {
            if (isAuthenticated() === true) {
                setAppState({ ...appState, isAuthenticated: true });
            } else {
                initSession();
                /* read static data */
                if (Object.keys(appState.staticData).length === 0) {
                    readStaticData();
                }
            }
        } else {
            // authenticated - read users data on first run
            if (initialHasRun) {
                return;
            }
            console.log('initial run');
            initUser();
        }
    }, [initialHasRun, appState]);

    if (error) {
        return (
            <div className="main-container bg-white">
                <div className="wrapper container-modules first-container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box-row">
                                <Alert>{error}</Alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <>{loading ? <LoadingSpinner type="page" /> : <App appState={appState} refreshAppState={refreshAppState} />}</>
    );
};

export default ProtectedApp;
