import React from 'react';
import { Icon } from '@gymondo/frontend-core/components';
import { ReactComponent as checkIcon } from '@gymondo/frontend-core/svg/u-tick.svg';

interface SuccessIconProps {
    isValid: boolean;
}

const SuccessIcon: React.FC<SuccessIconProps> = ({ isValid, children }) => (
    <div className="field-wrapper">
        {isValid && (
            <div className="check-icon-wrapper">
                <Icon data={checkIcon} className="check-icon" />
            </div>
        )}
        {children}
    </div>
);

export default SuccessIcon;
