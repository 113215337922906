import React from 'react';
import { Footer as FooterContainer, FooterList, FooterListItem, FooterLogo } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { FooterNote } from '..';
import { Seal, Seal2 } from '../../assets';
import { Link } from 'react-router-dom';
import { PATHS } from 'state/config';

const Footer: React.FC = () => {
    return (
        <FooterContainer>
            <section className="content-wrapper">
                <div className="row">
                    <div className="col-xs-12 col-md-9">
                        <div className="box box-container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box-first box-container">
                                        <div className="footer-header">
                                            <Link to={PATHS.HOMEPAGE}>
                                                <FooterLogo />
                                            </Link>
                                        </div>
                                        <div className="footer-copy">
                                            Copyright &copy;{new Date().getFullYear()}. Alle Rechte vorbehalten
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-3">
                        <div className="box box-container">
                            <div className="row">
                                <div className="col-xs-12">
                                    <div className="box-first box-container">
                                        <div className="row">
                                            <div className="col-xs-12 col-md-4">
                                                <FooterList>
                                                    <FooterListItem>
                                                        <Link to={PATHS.TERMS}>{text('terms')}</Link>
                                                    </FooterListItem>
                                                    <FooterListItem>
                                                        <Link to={PATHS.IMPRINT}>{text('imprint')}</Link>
                                                    </FooterListItem>
                                                    <FooterListItem>
                                                        <a
                                                            href="https://www.gymondo.com/de/privacy/"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {text('privacy')}
                                                        </a>
                                                    </FooterListItem>
                                                </FooterList>
                                                <div className="box-nested"></div>
                                            </div>
                                            <div className="col-xs-12 col-md-4">
                                                <FooterList>
                                                    <FooterListItem>
                                                        <a
                                                            href="https://www.gymondo.com/de/community/facebook/?_ga=2.216858198.1378571578.1616406267-1839572363.1607941894"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {text('community')}
                                                        </a>
                                                    </FooterListItem>
                                                    <FooterListItem>
                                                        <a
                                                            href="https://www.gymondo.com/magazin/?_ga=2.216858198.1378571578.1616406267-1839572363.1607941894"
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {text('magazine')}
                                                        </a>
                                                    </FooterListItem>
                                                </FooterList>
                                                <div className="box-nested"></div>
                                            </div>
                                            <div className="col-xs-12 col-md-4">
                                                <FooterList>
                                                    <FooterListItem>
                                                        <Link to={PATHS.FAQ}>{text('faq')}</Link>
                                                    </FooterListItem>
                                                    <FooterListItem>
                                                        <Link to={`${PATHS.FAQ}#help`}>{text('help')}</Link>
                                                    </FooterListItem>
                                                </FooterList>
                                                <div className="box-nested"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="footer-groups">
                <section className="content-wrapper">
                    <div className="row">
                        <div className="col-xs-12 center-xs">
                            <div className="box-row clearfix">
                                <div className="footer-note-img">
                                    <img src={Seal} />
                                    <img src={Seal2} className="seal2" />
                                </div>
                                <div className="footer-note-text">
                                    <FooterNote />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </FooterContainer>
    );
};
export default Footer;
