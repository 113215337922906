import React, { useCallback, useState } from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import { Button, Checkbox } from '@gymondo/frontend-core/components';

const CHECKBOX_AGREE = 'yes';

interface EndUnitFormProps {
    checkboxLabel: string;
    buttonLabel: string;
    handleAccept: () => void;
}

const EndUnitForm: React.FC<EndUnitFormProps> = ({ checkboxLabel, buttonLabel, handleAccept }) => {
    const [confirm, setConfirm] = useState(false);
    const handleChangeCheckbox = useCallback(() => {
        setConfirm(!confirm);
    }, [confirm]);
    return (
        <>
            <Formik initialValues={{}} onSubmit={handleAccept}>
                {() => (
                    <Form>
                        <Field name="checkbox_confirmation">
                            {({ field, form: { errors, touched } }: FieldProps) => (
                                <Checkbox
                                    field={{
                                        ...field,
                                        value: CHECKBOX_AGREE,
                                        checked: !!confirm,
                                        onChange: handleChangeCheckbox,
                                    }}
                                    form={{
                                        errors: errors,
                                        touched: touched,
                                    }}
                                    label={checkboxLabel}
                                />
                            )}
                        </Field>
                        <div className="row end-xs">
                            <Button htmlType="submit" disabled={!confirm}>
                                {buttonLabel}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EndUnitForm;
