import { Alert } from '@gymondo/frontend-core/components';
import React from 'react';

interface FormSubmissionErrorProps {
    message: string;
    className?: string;
}

const FormSubmissionError: React.FC<FormSubmissionErrorProps> = ({ message, className }) => {
    return <Alert className={className}>{message}</Alert>;
};

export default FormSubmissionError;
