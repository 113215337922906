import React, { useEffect, useState } from 'react';
import { ReaderPreLogin } from 'state/helpers/json';
import { Tracker } from '@gymondo/frontend-core/utils';

interface ReimbursementRateCalculatorProps {
    isFreebieUser: boolean;
    JsonReader: ReaderPreLogin;
    action?: JSX.Element;
}

const ReimbursementRateCalculator: React.FC<ReimbursementRateCalculatorProps> = ({
    isFreebieUser,
    JsonReader,
    action,
}) => {
    const ReimbursementRates = JsonReader.getReimbursementRates();
    const defaultReimbursementRate = ReimbursementRates[0]?.rate ?? '100%';

    const [reimbursementRate, setReimbursementRate] = useState<{ rate: string; company: string }>();

    useEffect(() => {
        if (reimbursementRate?.company) {
            Tracker.push({
                event: Tracker.EVENTS.TRACKING,
                eventCategory: 'prevention',
                eventAction: 'change_company',
                eventLabel: reimbursementRate?.company,
            });
        }
    }, [reimbursementRate?.company]);

    return (
        <>
            {!isFreebieUser && (
                <div className="wrapper homepage-partner">
                    <div className="row flex">
                        <div className="col-xs-12 center-xs ">
                            <h4>{JsonReader.get('insurancePane.title')}</h4>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: JsonReader.get('insurancePane.description'),
                                }}
                            ></p>

                            <select
                                className="select select--primary"
                                onChange={(event) =>
                                    setReimbursementRate({
                                        rate: event.target.value,
                                        company: event.target.options[event.target.selectedIndex].text,
                                    })
                                }
                            >
                                {ReimbursementRates.map((_elem, _index) => (
                                    <option key={_index} value={_elem.rate}>
                                        {_elem.name}
                                    </option>
                                ))}
                            </select>
                            <div
                                className="tt-erstattung"
                                style={{
                                    width: '250px',
                                }}
                            >
                                {JsonReader.get('insurancePane.reimbursementRate.title')}:
                                <div className="prozent">{reimbursementRate?.rate || defaultReimbursementRate}</div>
                            </div>
                            <div className="notice">{JsonReader.get('insurancePane.reimbursementRate.notice')}</div>

                            {action}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ReimbursementRateCalculator;
