import React, { useContext } from 'react';
import { FaqBackround, FaqBackroundMobile } from '../../assets';
import { getStoredProductId } from 'state/session';
import { PARTNER_KEYS } from 'state/config';
import { getPartnerByProductId } from 'state/helpers/program';
import { AppStateContext } from 'state/context';

const Faq: React.FC = () => {
    const {
        appState: { user },
    } = useContext(AppStateContext);

    const isViactiv =
        (getStoredProductId() > 0 && getPartnerByProductId(getStoredProductId())?.KEY === PARTNER_KEYS.VIACTIV) ||
        (user.productId > 0 && getPartnerByProductId(user.productId)?.KEY === PARTNER_KEYS.VIACTIV);

    return (
        <>
            <div className="container-fluid no-padding page-faq first-container">
                <div className="row center-xs">
                    <div className="image-module col">
                        <h1>
                            FAQ
                            <br />
                            Häufig gestellte Fragen
                        </h1>
                        <div className="image__background">
                            <img src={FaqBackround} />
                            <img src={FaqBackroundMobile} className="background-mobile" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container_ wrapper">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                        <h6>
                            Wie funktionieren die Präventionskurse{' '}
                            <strong>Rücken fit & gesund (Kräftigung) – Onlinekurs mit Gymondo</strong> und{' '}
                            <strong>Funktionelle Ganzkörperkräftigung - Onlinetraining mit Gymondo</strong>?
                        </h6>
                        <p className="gym-p">
                            Uns ist es wichtig, dass du mit unseren Präventionskursen deine Gesundheit nachhaltig
                            unterstützt. Nachdem du alle Übungen der Woche absolviert hast, beantworte bitte die
                            gestellte Quizfrage. Sie dient der Reflexion der aktuellen Trainingseinheit. Hast du die
                            richtige Antwort zum Quiz abgesendet, dann kannst du die entsprechende Wocheneinheit
                            erfolgreich abschließen. Du hast insgesamt 12 Wochen Zeit, um die 10 Wocheneinheiten zu
                            beenden.
                            <br />
                            Und so geht’s:
                        </p>
                        <ul className="custom-list">
                            <li>
                                Aktuelles Wochenmodul öffnen, die theoretischen Lerninhalte und Übungen anschauen und
                                das Workout-Video starten
                            </li>
                            <li>Workout absolvieren</li>
                            <li>
                                Quizfrage beantworten und nach der richtigen Antwort auf
                                <strong> Absenden </strong>
                                klicken
                            </li>
                            <li>Erfolgreiche Durchführung des Wochenprogramms per Klick bestätigen</li>
                            <li>Alles erledigt? Dann wird das nächste Wochenmodul für dich freigeschaltet.</li>
                        </ul>
                        <h6>Für wen sind die Präventionskurse geeignet?</h6>
                        <p className="gym-p">
                            Grundsätzlich kann jeder an unseren Präventionskursen teilnehmen. Du benötigst lediglich
                            einen Internetzugang für das Abrufen der Kursinhalte sowie eine E-Mail-Adresse.
                            Weiterführende Informationen, Erinnerungen an dein Training und die Teilnahmebestätigung
                            nach Beendigung des Programms erhältst du per E-Mail. Bitte beachte, dass die Übungen und
                            Maßnahmen, die in diesem Präventionskurs empfohlen werden, nicht für jeden geeignet sind und
                            dass dieses Online-Programm auf eigene Gefahr genutzt wird. Im Zweifelsfall sollte vor
                            Beginn des Kurses medizinisches Fachpersonal (Arzt oder Physiotherapeut) konsultiert werden.
                            Die Übungen des Präventionskurses sind kein Ersatz für eine ärztliche Untersuchung oder
                            Behandlung. Mögliche Einschränkungen aufgrund chronischer oder akuter Erkrankungen sowie für
                            Frauen während und nach der Schwangerschaft sind zu beachten.
                        </p>
                        <h6>Kann ich mehrere Präventionskurse gleichzeitig starten?</h6>
                        <p className="gym-p">
                            Wir bieten momentan zwei verschiedene Online-Präventionskurse an. Du kannst dich mit deinem
                            regulären Gymondo-Account auch für beide Präventionskurse registrieren. Beachte dabei, dass
                            du nicht beide Präventionskurse gleichzeitig nutzen kannst, sondern nacheinander. Gefällt
                            dir dein aktueller Kurs nicht, kannst du ihn jederzeit wechseln.
                            <br />
                            <br />
                            <b>Hinweis:</b> Wechselst du deinen Kurs vor Beendigung aller Wochenmodule, dann geht dein
                            Fortschritt inklusive aller abgeschlossenen Workouts und beantworteter Quizfragen verloren
                            und dir werden die Kosten für den neuen Kurs berechnet. Deshalb empfehlen wir dir, einen
                            Kurs pro Jahr zu absolvieren oder bei deiner Krankenkasse nachzufragen, ob eine
                            Kostenerstattung auch bei der Teilnahme an mehreren Kursen möglich ist.
                        </p>
                        <h6>Welche Hilfsmittel benötige ich?</h6>
                        <p className="gym-p">
                            Du brauchst keinerlei Trainingsgeräte oder Hilfsmittel außer bequemer Kleidung und einer
                            Trainingsmatte.
                        </p>
                        <h6>Wie hoch ist der Zuschuss meiner Krankenkasse?</h6>
                        <p className="gym-p">
                            In der Regel liegt die Höhe der Bezuschussung durch deine Krankenkasse zwischen 80 und 100%.
                            Den genauen Erstattungsbetrag kannst du direkt bei deiner Krankenkasse erfragen.
                        </p>
                        <h6>Wie erfolgt die Erstattung der Kursgebühr?</h6>
                        {!isViactiv ? (
                            <p className="gym-p">
                                Die Kosten für unsere Präventionskurse werden in der Regel ganz oder teilweise von
                                deiner gesetzlichen Krankenkasse erstattet. Damit deine Krankenkasse die Kursgebühr
                                erstatten kann, musst du alle Wochenmodule erfolgreich abschließen. Dies bestätigst du
                                durch das Beantworten der jeweiligen Quizfrage sowie das Ankreuzen deiner Teilnahme am
                                Ende jedes Wochenmoduls.
                                <br />
                                Deine Teilnahmebestätigung kannst du nach Abschluss des Kurses bei deiner Krankenkasse
                                mit der Bitte zur Kostenerstattung einreichen.
                                <br />
                                Solltest du nichts für den Kurs bezahlt haben, so wurde dieser bereits direkt von deiner
                                Krankenkasse oder deinem Arbeitgeber übernommen und es ist entsprechend keine Erstattung
                                mehr möglich.
                                <br />
                                Bei einigen Krankenkassen und Arbeitgebern kann das Zertifikat trotzdem noch im Rahmen
                                von Bonusprogrammen oder ähnlichem eingereicht werden, deshalb lade es dir am besten
                                herunter.
                            </p>
                        ) : (
                            <p className="gym-p">
                                Als VIACTIV-Versicherte*r ist die Teilnahme am Präventionskurs für dich kostenfrei, eine
                                Erstattung ist demnach nicht notwendig. Um Zugang zum Kurs zu erhalten, benötigst du nur
                                deine Versichertennummer. Zusätzlich kannst du dir den Präventionskurs auf den
                                Gesundheitbonus der VIACTIV anrechnen lassen, deshalb lade dir das Teilnahmezertifikat
                                am besten herunter.
                            </p>
                        )}
                        <h6>Was ist, wenn ich krank werde oder Urlaub habe?</h6>
                        <p className="gym-p">
                            Du kannst verpasste Trainingseinheiten problemlos in der folgenden Woche nachholen. Du hast
                            insgesamt 12 Wochen Zeit, um das 10-Wochen-Programm abzuschließen.
                        </p>
                        <h6>Was ist, wenn beim Training Schmerzen auftreten?</h6>
                        <p className="gym-p">
                            Schmerzen sollten weder beim Training noch bei den Präventionsübungen vorkommen. Wir
                            empfehlen, die Ursache der Schmerzen von einem Arzt oder medizinischem Fachpersonal abklären
                            zu lassen.
                            <br />
                            Es gibt körperliche Alarmsignale, bei denen du das Training sofort abbrechen solltest. Dazu
                            gehören subjektive Erschöpfung, Atemnot, Herzstolpern, Schwindel, Zittern oder plötzliche
                            Schweißausbrüche.
                        </p>
                        <h6 id="help">Hilfe &amp; Support</h6>
                        <p className="gym-p">
                            Bitte kontaktiere bei Fragen oder Anregungen unseren Kundenservice unter{' '}
                            <a href="mailto:service@gymondo.de" className="link">
                                service@gymondo.de
                            </a>
                            . Bei technischen Problemen kannst du uns eine E-Mail an{' '}
                            <a href="mailto:technical-support@gymondo.de" className="link">
                                technical-support@gymondo.de
                            </a>{' '}
                            schicken.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Faq;
