/* eslint-disable @typescript-eslint/no-explicit-any */
import AdyenCheckout from '@adyen/adyen-web';
import { ADYEN_CONFIG } from '../state/configPayment';

declare global {
    interface Window {
        AdyenCheckout: any;
        google: any;
    }
}

export const isApplePayAvailable = (checkMakePayment?: any) => {
    const applePaySession = window.ApplePaySession;
    try {
        // TODO: fix apple pay
        // return Boolean(applePaySession && checkMakePayment ? applePaySession.canMakePayments() : !!applePaySession);
        return Boolean(applePaySession && checkMakePayment ? false : !!applePaySession);
    } catch (error: unknown) {
        return false;
    }
};

export const isGooglePayAvailable = async (googleIsReadyToPayRequest: any) => {
    const googlePaySession = window.google;
    const paymentsClient = new googlePaySession.payments.api.PaymentsClient();
    if (paymentsClient) {
        try {
            await paymentsClient.isReadyToPay(googleIsReadyToPayRequest);
            return true;
        } catch (error: unknown) {
            return false;
        }
    }

    return false;
};

export const createAdyenCheckout = (additinalConfig: Record<string, unknown>, session: any) =>
    AdyenCheckout({
        ...ADYEN_CONFIG,
        ...additinalConfig,
        session,
    });
