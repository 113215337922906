import React, { useEffect, useRef } from 'react';

import 'video.js/dist/video-js.min.css';
import '@gymondo/gymondo-web-video-player/dist/gym-player.css';

import videojs from 'video.js';
import 'videojs-flash';
import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import 'videojs-markers';
import 'videojs-overlay';
import Player, { VideoPlayerOptions, VideoPlayerFramework } from '@gymondo/gymondo-web-video-player';

export type VideoPlayer = videojs.Player;

export type Listeners = {
    [key: string]: (player: VideoPlayer, props: unknown) => void;
};
export interface VideoPlayerProps {
    options: VideoPlayerOptions;
    videoFrameworkOptions?: VideoPlayerFramework;
    listeners?: Listeners;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ options, videoFrameworkOptions, listeners }: VideoPlayerProps) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoTag = videoRef.current;
        const player = Player.gymPlayer(videojs, videoTag, options, videoFrameworkOptions);

        player.on('ready', () => {
            if (listeners) {
                Object.keys(listeners).forEach((key) =>
                    player.on(key, (...args: unknown[]) => listeners[key](player, args)),
                );
            }
        });
        /**
         * For Apple devices the native player doesn't dispatch when the fullscreen is closed
         */
        if (videoTag) {
            videoTag.addEventListener('webkitendfullscreen', (evt) => {
                if (listeners) {
                    listeners.fullscreenchange(player, evt);
                }
            });
        }
    }, [videoRef, options, videoFrameworkOptions, listeners]);

    return (
        <div id="video-player">
            <div data-vjs-player className="gym-player video-js">
                <video ref={videoRef} />
            </div>
        </div>
    );
};

export default VideoPlayer;
