import { getWeeksRemaining } from 'utils/string';
import { dispatch, DispatchActions } from '../actions';
import config, { PROGRAM_STATUS } from '../config';
import { AccountProps, initialUser, PurchaseHistoryItemProps, ResourceProps, UserProps } from '../context';
import { getPartnerByProductId, getProgram } from './program';
import { Product } from '../api.types';

export const assertUserData = async () => {
    // initiate user clone object from initialUser
    const user: UserProps = Object.assign({}, initialUser);
    try {
        // the below numbered order is important!
        // 0) read onboarding status
        const onboardingStatus = await dispatch(DispatchActions.onboardingStatus);
        user.onboardingCompleted = onboardingStatus.status === config.STATUSES.ONBOARDING.FINISHED;

        // 1) read purchase history
        const _purchaseHistory = await dispatch(DispatchActions.purchaseHistory);
        user.purchaseHistory = _getFormattedPurchaseHistory(_purchaseHistory.content);

        // set award rates to old plans (important for old certificates generation)
        const products: Product[] = await dispatch(DispatchActions.getProducts);
        user.products = products;
        user.purchaseHistory.map((_purchase) => {
            const purchaseRate = products.find((_product) => _purchase.productId === _product.id);
            _purchase.awardRate = purchaseRate?.awardRate ?? 1;

            return _purchase;
        });

        // 2) load user dashboard
        try {
            user.dashboard = await dispatch(DispatchActions.getDashboard);
        } catch (error: unknown) {
            user.isLoaded = true;
            return user;
        }

        // Get the user ended plans - No ended plans & purchase history > 0 = old prevention user
        const endedPlans = await dispatch(DispatchActions.getEndedPlans);
        user.hasEndedPlans = endedPlans.content.length > 0;

        // 2.1) get user account
        const account = await dispatch(DispatchActions.getAccount);
        // load user account, to set user.isLoaded for checks/redirects in initUser() in App.tsx
        user.account = _getFormattedAccount(account);

        // 3) load current plan id
        const plan = user.dashboard.plans.find((pl) => pl.status === config.STATUSES.PLAN.RUNNING);
        if (!plan) {
            user.isLoaded = true;

            return user;
        }
        user.planId = plan.id;

        // 4) load user program done rate
        const ph = user.purchaseHistory.find((ph) => ph.resourceId === plan.program.id);
        if (ph) {
            user.programId = ph.resourceId;
            user.productId = ph.productId;
            user.channelId = ph.channelId;

            const currentAwardRate = products.find((_product) => user.productId === _product.id);
            user.awardRate = currentAwardRate?.awardRate ?? 1;
        }

        // 5) get last schedule based on user award rate
        const programEndIndex = Math.round(user.dashboard.schedules.length * user.awardRate) - 1;
        const endSchedule = user.dashboard.schedules[programEndIndex];

        // 6) load past and present units and resources
        const resources: ResourceProps[] = [];
        for (const _schedule of user.dashboard.schedules) {
            // do not load future units
            if (_schedule.interval > config.TIMELINE.MIN_ALLOWED_INTERVAL) {
                // set default value
                _schedule.quizDone = false;
                continue;
            }
            const resource_id = _schedule.workout.id;
            const _res: ResourceProps = await dispatch(DispatchActions.getResource, { resource_id });
            resources.push(_res);
            // get quizzes and add to dashboard schedules
            try {
                await dispatch(DispatchActions.getQuiz, { schedule_id: _schedule.id });
                _schedule.quizDone = true;
            } catch (error: unknown) {
                _schedule.quizDone = false;
            }

            // check if program not finished in 12 weeks
            if (
                _schedule.position === 0 &&
                getWeeksRemaining(config.TIMELINE.WEEKS_REMAINING, _schedule.doneAt ?? 0) <= 0 &&
                !account.username.includes('prevention-gymondo')
            ) {
                user.programStatus = PROGRAM_STATUS.EXPIRED;
                // end program plan
                await dispatch(DispatchActions.endProgramPlan, { plan_id: user.planId });
            }

            // check program finished if last unit done
            if (_schedule.position === endSchedule.position && _schedule.doneAt !== null && _schedule.quizDone) {
                user.programStatus = PROGRAM_STATUS.FINISHED;
            }
        }
        user.resources = resources;

        // 7) set main user state data
        // select actual item from purchase history by running plan from dashboard
        if (ph) {
            const partner = getPartnerByProductId(ph.productId);
            if (ph.status === PROGRAM_STATUS.PURCHASED) {
                user.paymentCompleted = true;
            }
            user.isFreebie = partner ? partner.IS_FREEBIE : false;

            // if program status not set (above, as expired or finished)
            if (user.programStatus === initialUser.programStatus) {
                user.programStatus = ph.status;
            }
            // check if user can purchase, by last finished programm date
            const purchaseInterval = partner ? partner.PURCHASE_INTERVAL * 24 * 60 * 60 * 1000 : 0;
            if (user.programStatus === PROGRAM_STATUS.FINISHED && purchaseInterval > 0) {
                user.canPurchaseOn = ph.purchaseDate + purchaseInterval;
            }

            // add data to running purchase: set total count of modules and done count (for some partners participation confirmation)
            // this is needed for participation confirmation (dynamic element shown on user award rate reached, not from ended plans)
            ph.totalUnits = user.dashboard.schedules.length;
            const lastDoneSchedule = [...user.dashboard.schedules]
                .reverse()
                .find((_s) => _s.doneAt !== null && _s.quizDone);
            if (lastDoneSchedule) {
                ph.lastDoneUnit = lastDoneSchedule.position + 1;
            }
            // set start and end date for running program
            ph.startDate = user.dashboard.schedules[0].doneAt;
            ph.endDate = endSchedule.doneAt;
        }
    } catch (error: unknown) {
        throw error;
    }
    user.isLoaded = true;
    return user;
};

const _getFormattedPurchaseHistory = (content: Record<string, unknown>[]) => {
    const items: PurchaseHistoryItemProps[] = [];
    content.forEach((_content) => {
        const product = _content.storeProduct as Record<string, unknown>;
        const program = getProgram(product.resourceId as number);
        const trainer = program?.TRAINER as Record<string, string>;
        const item: PurchaseHistoryItemProps = {
            id: _content.id as number,
            courseId: program?.COURSE_ID as string,
            endDate: _content.endDate as number,
            purchaseDate: _content.purchaseDate as number,
            paidPurchase: _content.paidPurchase as boolean,
            startDate: _content.startDate as number,
            status: _content.status as PurchaseHistoryItemProps['status'],
            partner: product.partner as string,
            price: product.price as number,
            currency: product.currency as { code: string; symbol: string },
            productId: product.id as number,
            resourceId: product.resourceId as number,
            title: product.title as string,
            type: product.type as string,
            trainer: { name: trainer.NAME as string, qualification: trainer.QUALIFICATION as string },
            totalUnits: 10,
            lastDoneUnit: 0,
            awardRate: 1, // fallback default value, will be reset when rate are fetched
            channelId: _content.channelId as number,
        };
        items.push(item);
    });
    return items;
};

const _getFormattedAccount = (data: Record<string, unknown>) => {
    const account: AccountProps = {
        id: data.id as number,
        username: data.username as string,
        firstName: data.firstName as string,
        lastName: data.lastName as string,
        birthDate: data.birthDate as number,
        gender: data.gender as string,
        roles: data.roles as string[],
    };
    if (data.healthInsurance) {
        account.healthInsurance = (data.healthInsurance as string) || '';
    }
    if (data.address) {
        const _addr = data.address as Record<string, unknown>;
        account.address = {
            id: (_addr.id as number) || 0,
            street: (_addr.street as string) || '',
            number: (_addr.number as string) || '',
            location: {
                id: ((_addr.location as Record<string, unknown>).id as number) || 0,
                location: ((_addr.location as Record<string, unknown>).location as string) || '',
                postalCode: (_addr.location as Record<string, unknown>).postalCode as string,
            },
        };
    }
    return account;
};
