import React, { useContext } from 'react';
import * as Yup from 'yup';
import text from 'lang';
import { Button, Input, Modal } from '@gymondo/frontend-core/components';
import { Field, FieldProps, Form, Formik } from 'formik';
import Typography from '@gymondo/frontend-core/typography';
import style from './PromptHealthInsurance.module.scss';
import { dispatch, DispatchActions } from 'state/actions';
import { AppStateContext } from 'state/context';
import { getPartnerByProductId } from 'state/helpers/program';

const PromptHealthInsurance: React.FC = () => {
    const context = useContext(AppStateContext);

    const promptInsuranceNumber = !!(
        getPartnerByProductId(context.appState.user.productId)?.HAS_INSURANCE &&
        !context.appState.user.account.healthInsurance
    );

    const validationSchema = Yup.object().shape({
        insurance_number: Yup.string()
            .required(text('validation_insurance_number_note'))
            .matches(/^[A-Z][0-9]{9}$/, text('validation_insurance_number_invalid')),
    });

    if (!promptInsuranceNumber) {
        return null;
    }

    return (
        <Modal header={text('health_insurance_modal_header')} persistent dialogClassName={style.modal}>
            <Formik
                initialValues={{ insurance_number: '' }}
                validationSchema={validationSchema}
                onSubmit={async ({ insurance_number }, { setSubmitting }) => {
                    const patchedUser = {
                        ...context.appState.user.account,
                        healthInsurance: insurance_number,
                    };

                    await dispatch(DispatchActions.patchInsurance, { account: patchedUser, refresh: true }, context);
                    setSubmitting(false);
                }}
            >
                {({ isSubmitting, isValid }) => (
                    <Form>
                        <Typography as="div" color="default" variant="caption" className={style.description}>
                            {text('health_insurance_modal_description')}
                        </Typography>
                        <Field name="insurance_number" disabled={isSubmitting}>
                            {({ field, form: { errors, touched } }: FieldProps) => (
                                <Input
                                    field={{
                                        ...field,
                                        placeholder: text('health_insurance_modal_field'),
                                        type: 'text',
                                    }}
                                    form={{
                                        errors: errors,
                                        touched: touched,
                                    }}
                                    multiline={false}
                                />
                            )}
                        </Field>
                        <div className="row end-xs">
                            <Button htmlType="submit" disabled={isSubmitting || !isValid}>
                                {text('health_insurance_modal_cta')}
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
};

export default PromptHealthInsurance;
