import React from 'react';
import text from '../../lang';
import { PaymentMapProps, PAYMENT_METHODS } from '../../state/configPayment';
import Logos from './Logos';

interface MethodsProps {
    paymentMap: PaymentMapProps[];
    isApplePay: boolean;
    isGooglePay: boolean;
    callbackSelect: (key: string) => void;
}

const Methods: React.FC<MethodsProps> = ({ paymentMap, isApplePay, isGooglePay, callbackSelect }) => {
    const getFilteredPaymentMap = () => {
        return paymentMap.filter((elem) => {
            switch (elem.name) {
                case PAYMENT_METHODS.APPLE_PAY:
                    return isApplePay;
                case PAYMENT_METHODS.GOOGLE_PAY:
                    return isGooglePay;
                default:
                    return true;
            }
        });
    };

    return (
        <ul className="payment-methods-wrapper">
            {getFilteredPaymentMap().map((elem) => (
                <li key={elem.name as React.Key} onClick={() => callbackSelect(elem.name)} data-value={elem.name}>
                    <div className="payment-method">
                        <span className="payment-name">{text(`subscribe_method_title_${elem.name}`)}</span>
                        <Logos types={elem.types} />
                    </div>
                </li>
            ))}
        </ul>
    );
};

export default Methods;
