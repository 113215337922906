import React from 'react';
import { ReaderPreLogin } from 'state/helpers/json';
import { icArrowBlue, icCertificate, icMoneyBack, icRegister } from 'assets';

interface ReimbursementInfoProps {
    isFreebieUser: boolean;
    isReward?: boolean;
    JsonReader: ReaderPreLogin;
}

const ReimbursementInfo: React.FC<ReimbursementInfoProps> = ({ isFreebieUser, isReward = false, JsonReader }) => (
    <div className="row flex">
        <div className="col-xs-12 center-xs">
            <h4
                dangerouslySetInnerHTML={{
                    __html: isFreebieUser
                        ? JsonReader.get('reimbursementPane.partnerUsers.title')
                        : JsonReader.get('reimbursementPane.payingUsers.title'),
                }}
            />
        </div>
        {JsonReader.getReimbursements(isFreebieUser, isReward).map((_elem, _index) => (
            <div className="col-xs-12 col-sm-4 col-md-4 boxes" key={_index}>
                <div className="col-xs-12 col-sm-12 col-md-12 box-krankenkasse">
                    <img
                        className="image"
                        src={_index === 0 ? icRegister : _index === 1 ? icCertificate : icMoneyBack}
                        alt={`Schritt ${_index + 1}`}
                    />
                    <p>{_elem.description}</p>
                </div>
                {_index < JsonReader.getReimbursements(isFreebieUser, isReward).length - 1 && (
                    <div className="box-divider">
                        <img className="image" src={icArrowBlue} alt="nächste" />
                    </div>
                )}
            </div>
        ))}
    </div>
);

export default ReimbursementInfo;
