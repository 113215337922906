import React from 'react';
import { getModuleUrl } from '../../state/helpers/timeline';
import { ConditionalLink } from '../';
import { ScheduleProps } from '../../state/context';
import config from '../../state/config';

interface CardProps {
    card: ScheduleProps;
    className?: string;
    selected?: boolean;
    allowed?: boolean;
}

const Card: React.FC<CardProps> = ({ card, className, selected = false, allowed = false }) => {
    const week = card.position + 1;
    const _moduleDone = card.doneAt !== null;
    const _quizDone = card.quizDone;

    const done = _moduleDone && _quizDone;
    const pending = _moduleDone ? !_quizDone : _quizDone;
    const missed = card.interval < config.TIMELINE.MIN_ALLOWED_INTERVAL && allowed && !_moduleDone && !_quizDone;
    const linked = allowed && !selected;
    const extraClasses = [];
    if (className) {
        extraClasses.push(className);
    }
    if (selected) {
        extraClasses.push('selected');
    }
    if (done) {
        extraClasses.push('done');
    }
    if (!allowed) {
        extraClasses.push('locked');
    }
    if (pending) {
        extraClasses.push('pending');
    }
    if (missed) {
        extraClasses.push('missed');
    }
    if (linked) {
        extraClasses.push('link');
    }
    return (
        <ConditionalLink to={getModuleUrl(week)} condition={linked}>
            <div className={`${extraClasses.join(' ')}`}>
                <div className="image">
                    <div className="overlay">
                        <div className="icon"></div>
                    </div>
                    <img src={card.workout.images.web.thumbnail.medium} />
                </div>
                <div className="text">
                    <div className="module-week">Woche {week}</div>
                    <div className="module-sub">{card.workout.goal.name}</div>
                    <div className="module-title">{card.workout.title}</div>
                </div>
            </div>
        </ConditionalLink>
    );
};

export default Card;
