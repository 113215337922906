import React from 'react';
import { VideoPlayer } from './../';

const VideoPreview: React.FC<{ url: string }> = ({ url }) => {
    const src = [
        {
            language: '',
            category: '',
            type: '',
            url: url,
        },
    ];
    return (
        <VideoPlayer
            options={{
                audioLanguage: 'de',
                playerLanguage: 'de',
                sources: src,
                controlBarItems: ['progressControl', 'audioSelector', 'fullscreenToggle'],
                audioSelector: ['musicVolume'],
            }}
            videoFrameworkOptions={{
                autoplay: true,
            }}
        />
    );
};

export default VideoPreview;
