import { Alert, Button, LoadingSpinner, Modal } from '@gymondo/frontend-core/components';
import React, { useCallback, useContext, useEffect, useMemo, useReducer, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Card, VideoPlayer } from '..';
import { AppStateContext, ResourceProps, ScheduleProps, UnitStaticDataProps } from 'state/context';

import Player from '@gymondo/gymondo-web-video-player';

import { iconVideoPlay, icVerified, icVerifiedGreen, iconQuestionWhite, ModalProgramComplete } from 'assets';
import config, { PATHS, PROGRAM_STATUS } from 'state/config';
import { fetchState, getActivePos, getVListeners, isAllowed } from 'state/helpers/timeline';
import { getDatePlusInterval, getFormattedDuration, sprintf } from 'utils/string';
import EndUnitForm from '../forms/EndUnitForm';
import Swipe from './Swipe';
import RewardBanner from './reward-banner/RewardBanner';
import { dispatch, DispatchActions } from 'state/actions';
import { ReaderPostLogin } from 'state/helpers/json';
import { getProgram } from 'state/helpers/program';
import text from '../../lang';

/* modals */
enum ModalActions {
    ShowPlayer = 'SHOW_PLAYER',
    ShowQuizQuestion = 'SHOW_QUIZ_QUESTION',
    ShowModuleFinishConfirm = 'SHOW_MODULE_FINISH_CONFIRM',
    ShowModuleFinished = 'SHOW_MODULE_FINISHED',
    ShowProgramFinished = 'SHOW_PROGRAM_FINISHED',
}

enum ModalTypes {
    Player = 'PLAYER',
    QuizQuestion = 'QUIZ_QUESTION',
    ModuleFinishConfirm = 'MODULE_FINISH_CONFIRM',
    ModuleFinished = 'MODULE_FINISHED',
    ProgramFinished = 'PROGRAM_FINISHED',
}

const initialModalType = '';

function modalReducer(state: string, action: { type?: string }) {
    switch (action.type) {
        case ModalActions.ShowPlayer:
            return ModalTypes.Player;
        case ModalActions.ShowQuizQuestion:
            return ModalTypes.QuizQuestion;
        case ModalActions.ShowModuleFinishConfirm:
            return ModalTypes.ModuleFinishConfirm;
        case ModalActions.ShowModuleFinished:
            return ModalTypes.ModuleFinished;
        case ModalActions.ShowProgramFinished:
            return ModalTypes.ProgramFinished;
        default:
            return initialModalType;
    }
}

/* end modals */

const initialState = {
    schedule: {} as ScheduleProps,
    resource: {} as ResourceProps,
    staticData: {} as UnitStaticDataProps,
    activePos: config.TIMELINE.ACTIVE_POS,
    weeksRemaining: config.TIMELINE.WEEKS_REMAINING,
};

const Timeline: React.FC = () => {
    const [state, setState] = useState<{
        schedule: ScheduleProps; // actual unit
        resource: ResourceProps;
        staticData: UnitStaticDataProps;
        activePos: number; // active unit position (current or first missed)
        weeksRemaining: number; // weeks remaining of 12 weeks from program start
    }>(initialState);
    const [modalType, dispatchModalType] = useReducer(modalReducer, initialModalType);
    const [error, setError] = useState('');
    const [quizAnswer, setQuizAnswer] = useState<number | null>(null);
    const [quizError, setQuizError] = useState('');

    const { moduleId } = useParams<{ moduleId: string }>();
    const history = useHistory();
    const context = useContext(AppStateContext);
    const user = context.appState.user;

    // get module that will be taken as program end
    const _programEndIndex = Math.round(user.dashboard.schedules.length * user.awardRate) - 1;
    const endSchedule = user.dashboard.schedules[_programEndIndex];

    const PROGRAM = getProgram(user.programId);
    const ASSETS_PATH = `/assets/program/${PROGRAM?.NAME}`;

    /* main data loader */
    useEffect(() => {
        const fetchData = async () => {
            try {
                // clear quiz answer state
                setQuizAnswer(null);
                const newState = await fetchState(moduleId, context);
                setState(newState);
            } catch (error: unknown) {
                const _message = (error as Record<string, unknown>).message as string;
                console.log(`Error: ${_message}, will be redirected`);
                history.push(PATHS.HOMEPAGE);
            }
        };
        /* reset data on module change */
        if (state.resource.id) {
            setState(initialState);
        }
        fetchData();
    }, [moduleId]);

    // module or program done end modal (after quiz answer only, not on load of module that already done)
    useEffect(() => {
        if (quizAnswer !== null && state.schedule.doneAt !== null && state.schedule.quizDone) {
            // show program finish only once on module from awardRate is finished
            if (state.schedule.position === endSchedule.position && user.programStatus === PROGRAM_STATUS.FINISHED) {
                handleModals(ModalActions.ShowProgramFinished);
            } else {
                handleModals(ModalActions.ShowModuleFinished);
            }
        }
    }, [user, state.schedule, quizAnswer]);

    const handleModals = useCallback(
        (type?: string) => {
            dispatchModalType({ type: type });
        },
        [modalType],
    );

    const handleSetQuizAnswer = useCallback(
        (val: number) => {
            if (quizError && val !== quizAnswer) {
                setQuizError('');
            }
            setQuizAnswer(val);
        },
        [quizError, quizAnswer],
    );

    const handleSubmitQuiz = useCallback(async () => {
        const correct = JsonReader.get('module.quizPane.correctIndex');
        if (quizAnswer === Number(correct)) {
            if (state.schedule.doneAt !== null) {
                // id video done, goto module end confirmation
                handleModals(ModalActions.ShowModuleFinishConfirm);
            } else {
                // set quiz as done
                handleSetQuiz();
            }
        } else {
            const error = JsonReader.get('timeline.quizErrorPane.description');
            setQuizError(error);
        }
    }, [quizAnswer, state]);

    // on the quiz end if video is done
    const handleSetQuiz = useCallback(async () => {
        try {
            await dispatch(DispatchActions.setQuiz, { schedule_id: state.schedule.id });
            updateScheduleState(state.schedule.doneAt, true);
        } catch (error: unknown) {
            const _message = (error as Record<string, unknown>).message as string;
            setError(_message);
        }
    }, [state]);

    const updateScheduleState = useCallback(
        (_doneAt: number, _quizDone = false) => {
            const _newSchedules = user.dashboard.schedules.map((_s) => {
                if (_s.id === state.schedule.id) {
                    if (_doneAt !== null) {
                        _s.doneAt = _doneAt;
                    }
                    if (_quizDone) {
                        _s.quizDone = true;
                    }
                }
                return _s;
            });
            const newState = {
                ...state,
                activePos: getActivePos(_newSchedules),
            };
            if (_doneAt !== null) {
                newState.schedule.doneAt = _doneAt;
            }
            if (_quizDone) {
                newState.schedule.quizDone = true;
            }
            setState(newState);

            // set app global state: user schedules
            const _newAppState = {
                ...context.appState,
                user: { ...user, dashboard: { ...user.dashboard, schedules: _newSchedules } },
            };
            if (
                newState.schedule.position === endSchedule.position &&
                newState.schedule.doneAt !== null &&
                newState.schedule.quizDone
            ) {
                _newAppState.user.programStatus = PROGRAM_STATUS.FINISHED;
            }
            context.refreshAppState(_newAppState);
        },
        [context, state],
    );

    const getDaysUntilNextModule = useMemo(() => {
        const nextModule = user.dashboard.schedules.find((_s) => {
            return !_s.doneAt;
        });

        return nextModule && nextModule.interval > config.TIMELINE.MIN_ALLOWED_INTERVAL ? nextModule.interval : null;
    }, [user.dashboard.schedules]);

    /* do not re-render player! */
    const MemorizedVideoPlayer = useMemo(() => {
        if (!state.resource.urls) {
            return <></>;
        }
        return (
            <VideoPlayer
                options={{
                    audioLanguage: 'de',
                    playerLanguage: 'de',
                    sources: Player.utils.convertToSource(state.resource.urls),
                    annotations: state.resource.annotations,
                    playerItems: ['playToggle', 'annotations', 'exerciseTopBar', 'cuePoints', 'gradients', 'markers'],
                }}
                videoFrameworkOptions={{
                    autoplay: true,
                }}
                listeners={getVListeners(async (timestamp: number) => {
                    try {
                        let _doneAt = state.schedule.doneAt;
                        if (_doneAt === null) {
                            _doneAt = timestamp;
                            await dispatch(DispatchActions.setWorkoutCompleted, {
                                schedule_id: state.schedule.id,
                                program_id: user.programId,
                                resource_id: state.resource.id,
                                finish_date: _doneAt,
                            });
                            updateScheduleState(_doneAt, state.schedule.quizDone);
                        }
                    } catch (error: unknown) {
                        const _message = (error as Record<string, unknown>).message as string;
                        setError(_message);
                    }
                })}
            />
        );
    }, [state.resource, state.schedule]);

    if (error) {
        return (
            <div className="main-container bg-white">
                <div className="wrapper container-modules first-container">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="box-row">
                                <Alert>{error}</Alert>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    if (!state.resource.id) {
        return <LoadingSpinner type="page" />;
    }

    /* AFTER loading of staticData */
    const JsonReader = new ReaderPostLogin(state.staticData);

    return (
        <>
            {user.isFreebie && user.awardRate < 1 && (
                <div className="banner-partner-user">{text('timeline_partner_banner')}</div>
            )}
            <RewardBanner />
            <div className="main-container bg-white">
                <div className="wrapper container-modules first-container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-8 left">
                            <h5>{state.schedule.workout.goal.name}</h5>
                            <h1>{state.schedule.workout.title}</h1>
                            {state.schedule.interval < config.TIMELINE.MIN_ALLOWED_INTERVAL &&
                                !state.schedule.quizDone &&
                                state.schedule.doneAt === null && (
                                    <Alert>
                                        {sprintf(
                                            JsonReader.get('timeline.missedModulePane.description'),
                                            String(state.weeksRemaining),
                                        )}
                                    </Alert>
                                )}
                            {state.schedule.quizDone && state.schedule.doneAt === null && (
                                <Alert severity="warning">
                                    {JsonReader.get('timeline.missedWorkoutPane.description')}
                                </Alert>
                            )}
                            {state.schedule.doneAt !== null && !state.schedule.quizDone && (
                                <Alert severity="warning">
                                    {JsonReader.get('timeline.missedQuizPane.description')}
                                </Alert>
                            )}
                            {getDaysUntilNextModule !== null && state.schedule.quizDone && (
                                <Alert severity="info">
                                    {sprintf(
                                        JsonReader.get('timeline.nextModulePane.description'),
                                        `am ${getDatePlusInterval(getDaysUntilNextModule)}`,
                                    )}
                                </Alert>
                            )}
                            <div className={`container-video${state.schedule.doneAt !== null ? ' done' : ''}`}>
                                <div className="overlay">
                                    <div className="content">
                                        <div className="icon"></div>
                                        <div className="text">
                                            {JsonReader.get('timeline.videoDonePane.description')}
                                        </div>
                                        <div
                                            className="btn-gym btn--flex btn--inverse"
                                            onClick={() => handleModals(ModalActions.ShowPlayer)}
                                        >
                                            {JsonReader.get('timeline.videoDonePane.button')}
                                        </div>
                                    </div>
                                </div>
                                {state.schedule.doneAt === null && (
                                    <div className="video-duration">
                                        {getFormattedDuration(state.schedule.workout.durationSeconds)}
                                    </div>
                                )}
                                <div className="video-wrapper" onClick={() => handleModals(ModalActions.ShowPlayer)}>
                                    {state.schedule.doneAt === null && (
                                        <div className="btn-video-play">
                                            <img className="image" src={iconVideoPlay} />
                                        </div>
                                    )}
                                    <img
                                        className="video-thumbnail"
                                        src={state.schedule.workout.images.web.thumbnail.large}
                                    />
                                </div>
                            </div>
                            <div className="text-video">
                                <h6>{JsonReader.get('module.videoPane.title')}</h6>
                                <p>{JsonReader.get('module.videoPane.description')}</p>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4 col-md-4 right">
                            <h6>{JsonReader.get('timeline.timelinePane.title')}</h6>
                            <div className="container-entries">
                                <div className="scroll-container">
                                    <Swipe className="entries">
                                        {user.dashboard.schedules.map((_card, _index) => {
                                            const selected = _card.position === state.schedule.position;
                                            const allowed = isAllowed(_card, state.activePos);
                                            return (
                                                <Card
                                                    key={_card.id as React.Key}
                                                    card={_card}
                                                    className={`module-entry${
                                                        user.dashboard.schedules.length === _index + 1 ? ' last' : ''
                                                    }`}
                                                    selected={selected}
                                                    allowed={allowed}
                                                />
                                            );
                                        })}
                                    </Swipe>
                                </div>
                                <div className="bottom-fade"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container bg-rosa">
                <div className="wrapper container-infos">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                            <h6>{JsonReader.get('module.explanationPane.title')}</h6>
                            <p>{JsonReader.get('module.explanationPane.description')}</p>
                        </div>
                    </div>
                    <div className="row">
                        {JsonReader.getExplanations().map((_elem, _index) => (
                            <div key={_index} className="col-xs-12 col-sm-4 col-md-4 info-entry">
                                <img src={`${ASSETS_PATH}/explanationPane/module${moduleId}/${_index}.jpg`} />
                                <div className="text">
                                    <div className="title">
                                        <span className="number">{`0${_index + 1}.`}</span>
                                        {_elem.title}
                                    </div>
                                    <p>{_elem.description}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="main-container bg-white">
                <div className="wrapper container-homework">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <div className="content">
                                <h5>{JsonReader.get('timeline.homeworkPane.title')}</h5>
                                <p className="gym-p">{JsonReader.get('module.homeworkPane.description')}</p>
                                <ul className="custom-list">
                                    {JsonReader.getExercises().map((_elem, _index) => (
                                        <li key={_index} className="homework-entry">
                                            <div
                                                className="text_"
                                                dangerouslySetInnerHTML={{
                                                    __html: _elem,
                                                }}
                                            ></div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 spacing-no-p-r">
                            <div className="image-container image-border-radius">
                                <img src={`${ASSETS_PATH}/homeworkPane/${moduleId}.jpg`} />
                            </div>
                        </div>
                    </div>
                    <div className="row padding">
                        <div className="col-xs-12 col-sm-12 col-md-12 pdf-download">
                            <h5>{JsonReader.get('timeline.summaryPane.title')}</h5>
                            <p
                                className="gym-p"
                                dangerouslySetInnerHTML={{
                                    __html: JsonReader.get('timeline.summaryPane.description'),
                                }}
                            ></p>
                            <a
                                href={`${ASSETS_PATH}/pdfPane/${moduleId}.pdf`}
                                target="_blank"
                                rel="noreferrer"
                                className="btn-gym"
                            >
                                {JsonReader.get('timeline.summaryPane.button')}
                            </a>
                        </div>
                    </div>
                    <div className="row center-xs infos">
                        <div className="col-xs-12">
                            <p
                                className="gym-p"
                                dangerouslySetInnerHTML={{
                                    __html: sprintf(
                                        JsonReader.get('module.summaryPane.description'),
                                        '<a href="' +
                                            JsonReader.get('module.summaryPane.magazineURL') +
                                            '" target="_blank" rel="noreferrer">HIER</a>',
                                    ),
                                }}
                            ></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-container bg-quiz">
                <div className="wrapper container-quiz">
                    {!state.schedule.quizDone && (
                        <div className="btn-question">
                            <img
                                className="icon-question"
                                src={iconQuestionWhite}
                                onClick={() => handleModals(ModalActions.ShowQuizQuestion)}
                            />
                        </div>
                    )}
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                            <div className="title">{JsonReader.get('timeline.quizPane.title')}</div>
                            <div className="quiz-wrapper">
                                <form>
                                    {!state.schedule.quizDone && (
                                        <div className="quiz-entry">
                                            <div className="question">{JsonReader.get('module.quizPane.question')}</div>
                                            <div className="flex">
                                                {JsonReader.getQuizData().map((_elem, _index) => (
                                                    <span
                                                        key={_index}
                                                        className={`answer${quizAnswer === _index ? ' active' : ''}${
                                                            quizAnswer === _index && quizError ? ' false' : ''
                                                        }`}
                                                        onClick={() => handleSetQuizAnswer(_index)}
                                                    >
                                                        {_elem}
                                                    </span>
                                                ))}
                                            </div>
                                            {quizError && <Alert>{quizError}</Alert>}
                                            <Button
                                                disabled={quizAnswer === null || !!quizError}
                                                onClick={handleSubmitQuiz}
                                            >
                                                {JsonReader.get('timeline.quizPane.button')}
                                            </Button>
                                        </div>
                                    )}
                                    {state.schedule.quizDone && (
                                        <div className="quiz-entry">
                                            <div className="end">
                                                <img className="image" src={icVerified} />
                                                <h6>{JsonReader.get('timeline.quizDonePane.title')}</h6>
                                                <p>{JsonReader.get('timeline.quizDonePane.description')}</p>
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {modalType === ModalTypes.Player && (
                <div className="overlay-video-player">
                    <div className="overlay-video-player__container">
                        {MemorizedVideoPlayer}
                        <div className="overlay-video-player__container__close" onClick={() => handleModals()}>
                            &times;
                        </div>
                    </div>
                </div>
            )}
            {modalType === ModalTypes.QuizQuestion && (
                <Modal onClose={() => handleModals()} dialogClassName={'modal-quiz-done'} hideFooter>
                    <div>
                        Eine Bezuschussung des Kurses durch die Krankenkasse ist nur möglich,{' '}
                        <b>wenn du die Quizfragen beantwortest.</b>
                    </div>
                </Modal>
            )}
            {modalType === ModalTypes.ModuleFinishConfirm && (
                <Modal onClose={() => handleModals()} dialogClassName={'modal-quiz-done'} hideFooter>
                    <img className="image" src={icVerifiedGreen} />
                    <div className="title">{JsonReader.get('timeline.quizSuccessPane.title')}</div>
                    <div className="text">{JsonReader.get('timeline.quizSuccessPane.description')}</div>
                    <EndUnitForm
                        checkboxLabel={JsonReader.get('timeline.quizSuccessPane.checkbox')}
                        buttonLabel={JsonReader.get('timeline.quizSuccessPane.button')}
                        handleAccept={handleSetQuiz}
                    />
                </Modal>
            )}
            {modalType === ModalTypes.ModuleFinished && (
                <Modal onClose={() => handleModals()} dialogClassName={'modal-quiz-done end'} hideFooter>
                    <div className="title">{JsonReader.get('timeline.completedModulePane.title')}</div>
                    <div className="text">{JsonReader.get('timeline.completedModulePane.description')}</div>
                    <img className="image-pic" src={ModalProgramComplete} />
                    <a
                        className="btn-gym"
                        href={`${ASSETS_PATH}/pdfPane/${moduleId}.pdf`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {JsonReader.get('timeline.completedModulePane.button')}
                    </a>
                </Modal>
            )}
            {modalType === ModalTypes.ProgramFinished && (
                <Modal onClose={() => handleModals()} dialogClassName={'modal-quiz-done end'} hideFooter>
                    <div className="title">
                        {sprintf(JsonReader.get('timeline.completedProgramPane.title'), moduleId)}
                    </div>
                    <div className="text">{JsonReader.get('timeline.completedProgramPane.description')}</div>
                    <img className="image-pic" src={ModalProgramComplete} />
                    <Link className="btn-gym" to={PATHS.PARTICIPATION_CONFIRMATION}>
                        {JsonReader.get('timeline.completedProgramPane.button')}
                    </Link>
                </Modal>
            )}
        </>
    );
};
export default Timeline;
