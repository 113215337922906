import React, { useRef } from 'react';
import { LoadingSpinner } from '@gymondo/frontend-core/components';
import { PAYMENT_METHODS } from 'state/configPayment';
import PaymentTemplate from '../PaymentTemplate';
import usePaymentMethod from '../use-payment-method';
import { Tracker } from '@gymondo/frontend-core/utils';

const METHOD = PAYMENT_METHODS.PAYPAL;

const Paypal: React.FC = () => {
    const ref = useRef(null);
    const { isLoading, errorMessage, paymentSuccess } = usePaymentMethod({
        paymentMethod: METHOD,
        config: {
            blockPayPalPayLaterButton: true,
            blockPayPalCreditButton: true,
            intent: 'authorize',
        },
        ref,
    });

    return (
        <div>
            <PaymentTemplate
                paymentMethod={METHOD}
                errorMessage={errorMessage}
                button={
                    <div>
                        {!paymentSuccess && (
                            <div
                                // eslint-disable-next-line react/button-has-type
                                ref={ref}
                                data-evt={Tracker.EVENT_TYPES.CLICK}
                                data-category="checkout"
                                data-action="payment_cta_clicked"
                                data-label={METHOD}
                            />
                        )}
                        {(isLoading || paymentSuccess) && <LoadingSpinner />}
                    </div>
                }
                legalDisclaimerId="subscribe_legal_disclaimer_paypal"
            />
        </div>
    );
};

export default Paypal;
