/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useCallback, useContext, useEffect, useState } from 'react';
import { dispatch, DispatchActions } from 'state/actions';
import { AppStateContext } from 'state/context';
import { createAdyenCheckout } from 'utils/payment';
import { PAYMENT_METHODS, REFUSAL_REASONS } from 'state/configPayment';
import { PATHS } from '../../state/config';
import { redirect } from 'utils/system';
import { getStoredChannelId, getStoredProgramId, getStoredProductId } from 'state/session';

interface IProps {
    paymentMethod: string;
    config: Record<string, unknown>;
    ref: React.MutableRefObject<null>;
    additionalConfig?: Record<string, unknown>;
}

const paymentMethodToComponentTypeMap = {
    [PAYMENT_METHODS.CARD]: 'securedfields',
    [PAYMENT_METHODS.SEPA]: 'sepadirectdebit',
    [PAYMENT_METHODS.PAYPAL]: 'paypal',
    [PAYMENT_METHODS.GOOGLE_PAY]: 'paywithgoogle',
};

const usePaymentMethod = ({ paymentMethod, config, ref, additionalConfig = {} }: IProps) => {
    const [formState, setFormState] = useState<any>();
    const [checkoutInstance, setCheckoutInstance] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isValid, setValid] = useState(!!formState);
    const [fieldsStatus, setFieldsStatus] = useState<Record<string, unknown>>({});
    const program_id = getStoredProgramId();
    const product_id = getStoredProductId();
    const channelId = getStoredChannelId();
    const context = useContext(AppStateContext);
    const { session } = context.appState.paymentSession;
    const sessionLoading = session === null;
    const [paymentSuccess, setPaymentSuccess] = useState(false);
    const product = context.appState.user.products.find((p) => p.id === product_id);

    const handleError = useCallback((e: any) => {
        const refusalReason = e.refusalReason as string | undefined;

        switch (refusalReason) {
            case REFUSAL_REASONS.THREE_D_SECURE_AUTH_FAILED:
            case REFUSAL_REASONS.THREE_D_NOT_AUTHENTICATED:
                setErrorMessage('subscribe_card_three_d_error');
                break;
            case REFUSAL_REASONS.INVALID_CARD_NUMBER:
                setErrorMessage('subscribe_card_invalid_card_number');
                break;
            case REFUSAL_REASONS.CVC_DECLINED:
                setErrorMessage('subscribe_card_cvc_declined');
                break;
            default:
                setErrorMessage('subscribe_card_other_error');
        }
    }, []);

    const submitPaymentData = useCallback(
        async (additionalData?) => {
            const paymentMethod = {
                ...formState?.data.paymentMethod,
                ...additionalData,
            };
            const paymentData = {
                ...formState?.data,
                paymentMethod,
            };

            setErrorMessage('');

            try {
                setIsLoading(true);
                checkoutInstance?.submitPayment(paymentData);
            } catch (e) {
                console.log('error', e);
                handleError(e);
                setIsLoading(false);
            }
        },
        [checkoutInstance, formState?.data, handleError],
    );

    useEffect(() => {
        if (!session) {
            return;
        }
        const createCheckout = async () => {
            const checkout = await createAdyenCheckout(additionalConfig, session);
            if (checkout) {
                const instance = checkout.create(paymentMethodToComponentTypeMap[paymentMethod], {
                    ...config,
                    onValid(state: any) {
                        setFormState(state);
                        setValid(true);
                    },
                    onLoad() {},
                    onChange() {
                        setErrorMessage('');
                    },
                    onConfigSuccess() {},
                    onFieldValid(field: any) {
                        setFieldsStatus((prevState) => ({
                            ...prevState,
                            [field.fieldType]: field.valid,
                        }));
                    },
                    onBrand() {},
                    onError(state: any) {
                        console.log('errorState', state);
                        setFieldsStatus((prevState) => ({
                            ...prevState,
                            [state.fieldType]: state.error ? false : undefined,
                        }));
                        setValid(false);
                    },
                    onFocus() {},
                    onBinValue() {},
                    onPaymentCompleted(state: any) {
                        if (state?.resultCode === 'Authorised') {
                            dispatch(DispatchActions.resetPaymentSession, {}, context);
                            const handleSuccess = async () => {
                                await dispatch(DispatchActions.trackPaymentSuccess, {
                                    programId: program_id,
                                    userId: context.appState.user.account.id,
                                });
                                await dispatch(DispatchActions.pollPurchase, { program_id });
                                await dispatch(DispatchActions.pollPlan, { program_id });
                                if (context.appState.user.isLoaded && context.appState.user.onboardingCompleted) {
                                    redirect(PATHS.HOMEPAGE);
                                    return;
                                }
                                redirect(PATHS.ONBOARDING);
                                return;
                            };
                            setPaymentSuccess(true);
                            handleSuccess();
                        } else {
                            console.log('onPaymentCompleted not authorised', state);
                            setIsLoading(false);
                            handleError(state);
                        }
                    },
                });
                setCheckoutInstance(instance);
            } else {
                setErrorMessage('error.generic');
            }
        };
        createCheckout();

        // eslint-disable-next-line consistent-return
        return () => {
            dispatch(DispatchActions.resetPaymentSession, {}, context);
        };
    }, [session]);

    useEffect(() => {
        dispatch(
            DispatchActions.paymentSession,
            { product, payment_method: paymentMethod, channel_id: channelId },
            context,
        );
    }, [paymentMethod, dispatch]);

    useEffect(() => {
        if (!checkoutInstance || !ref.current) {
            return;
        }
        checkoutInstance?.mount(ref.current);
    }, [checkoutInstance, ref]);

    return {
        submitData: submitPaymentData,
        isLoading: isLoading || sessionLoading,
        isValid,
        errorMessage,
        fieldsStatus,
        paymentSuccess,
    };
};

export default usePaymentMethod;
