import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AccountNavigation, ParticipationConfirmationItem } from '..';
import { AppStateContext, PurchaseHistoryItemProps, UnitStaticDataProps } from 'state/context';
import {
    AccountBackround,
    AccountBackroundMobile,
    GymondoLogoSmallWhite,
    LPBackfitLogo,
    LPFullBodyLogo,
} from '../../assets';

import { PATHS, PROGRAM_STATUS } from 'state/config';
import { Link } from 'react-router-dom';
import { getBackfitProgram, getGanzkoerperProgram, getProgram, getProgramPath } from 'state/helpers/program';
import { redirect } from 'utils/system';
import { dispatch, DispatchActions } from 'state/actions';
import { ReaderPostLogin } from 'state/helpers/json';
import { Alert } from '@gymondo/frontend-core/components';

interface endedPlan {
    id: string;
    endedAt: number;
    programId: number;
    completedWorkouts: number;
    totalWorkouts: number;
}

const ParticipationConfirmation: React.FC = () => {
    const [error, setError] = useState('');
    const [hasFinishedCurrentPlan, setHasFinishedCurrentPlan] = useState(false);
    const [CopyData, setCopyData] = useState<UnitStaticDataProps>({} as UnitStaticDataProps);
    const [endedPlans, setEndedPlans] = useState<endedPlan[]>([]);
    const context = useContext(AppStateContext);
    const user = context.appState.user;
    const isFreebie = user.isFreebie;

    const [finishedPlans, setFinishedPlans] = useState<PurchaseHistoryItemProps[]>([]);

    const program_id = user.programId;
    const program = getProgram(user.programId);

    useEffect(() => {
        const fetchCopy = async () => {
            const _data = await dispatch(DispatchActions.getContentPostLogin, { program_id });
            const _staticData = {
                ...CopyData,
                program: _data.program,
            } as UnitStaticDataProps;
            setCopyData(_staticData);
        };
        const getEndedPlans = async () => {
            // get completed programs
            const _endedPlansResponse = await dispatch(DispatchActions.getEndedPlans);
            const _endedPlans = (_endedPlansResponse.content as []).reduce(
                (acc, item: Record<string, unknown>) => [
                    ...acc,
                    {
                        id: item.id as string,
                        endedAt: item.endedAt as number,
                        programId: (item.program as Record<string, unknown>).id as number,
                        completedWorkouts: (item.statistics as Record<string, unknown>).completedWorkouts as number,
                        totalWorkouts: (item.statistics as Record<string, unknown>).totalWorkouts as number,
                    },
                ],
                [] as endedPlan[],
            );
            setEndedPlans(_endedPlans);
        };
        fetchCopy();
        getEndedPlans();
    }, [program_id]);

    /* AFTER loading of CopyData */
    const JsonReader = new ReaderPostLogin(CopyData);

    const handleGotoLandingpage = useCallback(async () => {
        // sign out and go to LP
        try {
            await dispatch(DispatchActions.signOut);
            // redirect
            const path =
                user.programId === getGanzkoerperProgram().ID
                    ? getProgramPath(getBackfitProgram().NAME)
                    : getProgramPath(getGanzkoerperProgram().NAME);
            redirect(path);
        } catch (error: unknown) {
            const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
            console.log(_errData);
        }
    }, []);

    // TODO handle create new purchase
    /*const handleRestartProgram = useCallback(async () => {
        try {
            await dispatch(DispatchActions.createProgramPlan, { program_id });
            redirect(PATHS.HOMEPAGE);
        } catch (error: unknown) {
            const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
            console.log(_errData);
            setError('Du hast bereits ein laufendes Program.');
        }
    }, []);*/

    const currentPlanPurchaseHistory = useMemo(() => {
        const _currentPlan = user.purchaseHistory.find((_purchase) => _purchase.resourceId === user.programId);
        if (_currentPlan?.status === PROGRAM_STATUS.PURCHASED && user.programStatus === PROGRAM_STATUS.FINISHED) {
            setHasFinishedCurrentPlan(true);
        }
        return _currentPlan ? { ..._currentPlan, totalWorkouts: user.dashboard.schedules.length } : null;
    }, []);

    useEffect(() => {
        // we got no way to properly map endedPlans to purchase history, this helps us avoid duplicates
        const associatedPlans = new Set();
        const finishedPlansHistory = user.purchaseHistory.reduce((acc, purchase) => {
            const endedItem = endedPlans.find(
                (ep) => ep.programId === purchase.resourceId && !associatedPlans.has(ep.id),
            );
            associatedPlans.add(endedItem?.id);

            if (endedItem && endedItem.completedWorkouts / endedItem.totalWorkouts >= purchase.awardRate) {
                const purchaseItem: PurchaseHistoryItemProps = {
                    ...purchase,
                    endDate: endedItem.endedAt,
                    totalUnits: endedItem.totalWorkouts,
                    lastDoneUnit: endedItem.completedWorkouts,
                    startDate: purchase.startDate ?? purchase.purchaseDate,
                    totalWorkouts: endedItem.totalWorkouts,
                };
                return [...acc, purchaseItem];
            }

            return acc;
        }, [] as PurchaseHistoryItemProps[]);

        setFinishedPlans(finishedPlansHistory);
    }, [endedPlans]);

    return (
        <>
            <div className="container-fluid no-padding first-container">
                <div className="row center-xs">
                    <div className="image-module col">
                        <h1>Mein Konto</h1>
                        <div className="image__background">
                            <img src={AccountBackround} />
                            <img src={AccountBackroundMobile} className="background-mobile" />
                        </div>
                    </div>
                </div>
            </div>
            <AccountNavigation isFreebie={isFreebie} />
            <div className="container wrapper spacing-pt-pb-0 participation-wrapper">
                <div className="row center-xs">
                    <div className="col-xs-12 col-sm-8 col-md-7 spacing-tb-xl">
                        {hasFinishedCurrentPlan && (
                            <>
                                <h6>Bleib bei uns und trainiere mit Gymondo!</h6>
                                <a href="https://www.gymondo.de" target="_blank" rel="noreferrer">
                                    <div className="box-program box-primary-light">
                                        <div className="logo-wrapper">
                                            <img className="logo" src={GymondoLogoSmallWhite} />
                                        </div>
                                        Entdecke über 100 Trainingsprogramme für ein fittes, aktives und gesundes Leben
                                    </div>
                                </a>
                                <div
                                    className="box-program box-nutrition"
                                    onClick={handleGotoLandingpage}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <div className="logo-wrapper">
                                        <img
                                            className="logo"
                                            src={
                                                user.programId === getBackfitProgram().ID
                                                    ? LPFullBodyLogo
                                                    : LPBackfitLogo
                                            }
                                        />
                                    </div>
                                    Teste unseren zweiten offiziell zertifizierten Präventionskurs
                                </div>
                                <div className="divider"></div>
                            </>
                        )}
                        <h6>Teilnahmebestätigung</h6>
                        {error && (
                            <>
                                <Alert>{error}</Alert>
                                <br />
                            </>
                        )}
                        {/* TODO: revisit after confirming the expired purchase status options
                          * currently logic is flawed as we allow anyone (even paid) to recreate the plan
                          * and the recreated plan is never associated with a Purchase.
                          * more context here: https://gymondo.slack.com/archives/C01G8PVH5FB/p1707319524243299
                        {user.programStatus === PROGRAM_STATUS.EXPIRED && (
                            <>
                                <div className="box-red">
                                    <h5>{JsonReader.get('program.expiredPane.title')}</h5>
                                    <p>
                                        <strong>{JsonReader.get('program.expiredPane.description')}</strong>
                                    </p>
                                    <div onClick={handleRestartProgram} className="btn-gym btn--inverse">
                                        {JsonReader.get('program.expiredPane.button')}
                                    </div>
                                </div>
                                <div className="divider"></div>
                            </>
                        )}*/}
                        {hasFinishedCurrentPlan && (
                            <>
                                <div className="box-green">
                                    <h5>{JsonReader.get('program.completedPane.title')}</h5>
                                    <strong>{JsonReader.get('program.completedPane.description')}</strong>
                                    <br />
                                    <ul className="custom-list">
                                        {JsonReader.getProgramCompletedItems().map((_elem, _index) => (
                                            <li key={_index}>{_elem}</li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="divider"></div>
                            </>
                        )}
                        {hasFinishedCurrentPlan && currentPlanPurchaseHistory && (
                            <ParticipationConfirmationItem
                                item={currentPlanPurchaseHistory}
                                account={user.account}
                                isFreebie={isFreebie}
                            />
                        )}
                        {!hasFinishedCurrentPlan && user.programStatus === PROGRAM_STATUS.PURCHASED && (
                            <div className="account-item">
                                <div>
                                    Du hast{' '}
                                    <b>
                                        <Link className="account-link" to={PATHS.HOMEPAGE}>
                                            {program?.TITLE}
                                        </Link>
                                    </b>{' '}
                                    laufend.
                                </div>
                            </div>
                        )}
                        {finishedPlans.map((purchase) => {
                            // check current has just ended
                            if (purchase.id === currentPlanPurchaseHistory?.id) {
                                return null;
                            }

                            return (
                                <ParticipationConfirmationItem
                                    key={purchase.id}
                                    item={purchase}
                                    account={user.account}
                                    isFreebie={isFreebie}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    );
};
export default ParticipationConfirmation;
