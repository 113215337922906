import React, { useContext } from 'react';
import { Text } from '@gymondo/frontend-core/typography';

import text from 'lang';
import { getEnvVar } from 'utils/env';
import { GYM_12M_REWARD_PRODUCTS, INFLUENCER_CHANNEL_URL } from 'state/config';
import { AppStateContext } from 'state/context';
import style from './RewardBanner.module.scss';
import { Button } from '@gymondo/frontend-core/components';

const LeFloidRewardBanner: React.FC = () => {
    return (
        <div className={style.leFloid}>
            <div className={style.bannerText}>
                <Text color="inverted" size="l" className={style.title}>
                    {text('gym_sub_postlogin_banner_lefloid')}
                </Text>
                <a target="_blank" className={style.button} rel="noreferrer" href={getEnvVar('LE_FLOID_URL')}>
                    <Button>{text('gym_sub_postlogin_banner_lefloid_cta')}</Button>
                </a>
            </div>
        </div>
    );
};

const DefaultRewardBanner: React.FC = () => {
    return (
        <a
            target="_blank"
            className={style.bannerPartnerUser}
            rel="noreferrer"
            href={getEnvVar('FRONTEND_POSTLOGIN_URL') + '/train/timeline'}
        >
            <Text color="primary">{text('gym_sub_postlogin_banner')}</Text>
        </a>
    );
};

const RewardBanner: React.FC = () => {
    const { user } = useContext(AppStateContext).appState;

    if (!GYM_12M_REWARD_PRODUCTS.includes(user.productId)) {
        return null;
    }

    if (user.channelId === INFLUENCER_CHANNEL_URL.LEFLOID) {
        return <LeFloidRewardBanner />;
    }

    return <DefaultRewardBanner />;
};

export default RewardBanner;
