import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import config from '../../state/config';
import {
    icCheck,
    RueckenCheck,
    GanzkoerperCheck,
    ImgBenefits,
    LPGanzkoerperRewardLogo,
    LPNewBackfitLogo,
    ArrowLeft,
    RueckenPreview,
    GanzkoerperPreview,
    Offer1,
    Offer2,
    Plus,
    CalendarIcon,
    PlayerIcon,
    LogoIcon,
    PartnerAOK,
    PartnerBig,
    PartnerIKK,
    PartnerKnappschaft,
    PartnerVividaBKK,
    PartnerBahnBKK,
    PartnerDAK,
    PartnerMobil,
    PartnerBarmer,
    PartnerFreudenbergBKK,
    PartnerKKH,
    PartnerTK,
    PartnerHeimat,
    PartnerPronova,
    PartnerHEK,
    PartnerIK,
    PartnerBKK24,
    PartnerViactiv,
    PartnerMHplus,
    PreventionLogo,
    Seal,
    PhoneWithBadge,
    PriceBadge,
} from '../../assets';
import { AppStateContext } from 'state/context';
import { getStoredPartnerKey, getStoredProgramId } from 'state/session';
import { scrollTo } from 'utils/system';
import { ReaderPreLogin } from 'state/helpers/json';
import { getPartner, getProgramPath, getRegisterProgramUrl } from 'state/helpers/program';
import ReimbursementRateCalculator from 'components/common/ReimbursementRateCalculator';
import VideoPreview from './VideoPreview';
import ReimbursementInfo from 'components/common/ReimbursementInfo';
import Carousel from '@gymondo/frontend-core/components/carousel';
import FAQ from './FAQ';
import { AnimatedOffer } from 'components/static/AnimatedOffer';

const Homepage: React.FC = () => {
    const {
        appState: { staticData },
    } = useContext(AppStateContext);

    const [videoIndex, setVideoIndex] = useState<number>();
    const [showFixedCta, setShowFixedCta] = useState(false);

    const program_id = getStoredProgramId();
    const partner_key = getStoredPartnerKey();
    const partner = getPartner(program_id, partner_key);
    const isFreebieUser = partner ? partner.IS_FREEBIE : false;

    const JsonReader = new ReaderPreLogin(staticData);

    const showVideoPreview = (index: number) => {
        setVideoIndex(index);
    };

    const hideVideoPreview = () => {
        setVideoIndex(undefined);
    };

    const handleScroll = () => {
        const element = document.getElementById('selectProgram');
        const header = document.getElementsByTagName('header')[0];
        const top = element ? element.offsetTop - (header ? header.offsetHeight : 0) : 0;

        const position = window.pageYOffset;

        if (position === top) {
            setShowFixedCta(false);
        } else {
            setShowFixedCta(true);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const partners = [
        {
            name: 'TK',
            logo: PartnerTK,
        },
        {
            name: 'Barmer',
            logo: PartnerBarmer,
        },
        {
            name: 'DAK',
            logo: PartnerDAK,
        },
        {
            name: 'AOK',
            logo: PartnerAOK,
        },
        {
            name: 'KKH',
            logo: PartnerKKH,
        },
        {
            name: 'IKK',
            logo: PartnerIKK,
        },
        {
            name: 'Big',
            logo: PartnerBig,
        },
        {
            name: 'Knappschaft',
            logo: PartnerKnappschaft,
        },
        {
            name: 'Vivida BKK',
            logo: PartnerVividaBKK,
        },
        {
            name: 'Bahn BKK',
            logo: PartnerBahnBKK,
        },
        {
            name: 'Mobil',
            logo: PartnerMobil,
        },
        {
            name: 'Freudenberg BKK',
            logo: PartnerFreudenbergBKK,
        },
        {
            name: 'Heimat',
            logo: PartnerHeimat,
        },
        {
            name: 'Pronova',
            logo: PartnerPronova,
        },
        {
            name: 'HEK',
            logo: PartnerHEK,
        },
        {
            name: 'IK',
            logo: PartnerIK,
        },
        {
            name: 'BKK24',
            logo: PartnerBKK24,
        },
        {
            name: 'Viactiv',
            logo: PartnerViactiv,
        },
        {
            name: 'MHplus',
            logo: PartnerMHplus,
        },
    ];

    return (
        <div className="homepage">
            <div className="main-container bg-white">
                <div className="homepage-hero first-container">
                    <div className="wrapper">
                        <div className="row">
                            <div className="col-xs-12 col-sm-6 flex">
                                <div className="logos">
                                    <img className="prevention" src={PreventionLogo} />
                                    <img className="seal" src={Seal} />
                                </div>
                                <h2
                                    dangerouslySetInnerHTML={{
                                        __html: JsonReader.get('heroPane.title'),
                                    }}
                                ></h2>

                                <div className="row">
                                    <div className="col-xs-8 col-sm-10 col-md-12">
                                        <ul>
                                            {JsonReader.getHeroSubtitles().map((_elem, _index) => (
                                                <li key={_index}>
                                                    {_elem.boldDescription ? (
                                                        <strong>{_elem.boldDescription}</strong>
                                                    ) : (
                                                        _elem.description
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-xs-4 col-sm-2 col-md-0 badge"></div>
                                </div>

                                <div className="button-container">
                                    <div
                                        className="btn-gym"
                                        onClick={() => {
                                            scrollTo('selectProgram');
                                        }}
                                    >
                                        {JsonReader.get('heroPane.cta1')}
                                    </div>

                                    <div
                                        className="btn-gym btn--border"
                                        onClick={() => {
                                            scrollTo('reimbursement');
                                        }}
                                    >
                                        {JsonReader.get('heroPane.cta2')}
                                    </div>
                                </div>
                                <div className="col-xs-12 notice"> {JsonReader.get('heroPane.notice')}</div>
                            </div>
                            <div className="col-xs-12 col-sm-6 badge-container">
                                <img src={PhoneWithBadge} className="phone" />
                                <img src={PriceBadge} className="badge" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-programs">
                    <div className="row margin center-xs" id="selectProgram">
                        <div className="col-xs-12 center-xs">
                            <h4>{JsonReader.get('programsPane.title')}</h4>
                        </div>
                        {JsonReader.getPrograms().map((_elem, _index) => (
                            <div
                                key={_index}
                                className={`col-xs-12 col-sm-12 col-md-6 box-program ${
                                    _index === 0 ? 'ruecken' : 'ganzkoerper'
                                }`}
                            >
                                <div className="logo">
                                    <img
                                        className="home-header"
                                        src={_index === 0 ? LPNewBackfitLogo : LPGanzkoerperRewardLogo}
                                    />
                                </div>

                                <div className="text">
                                    <h6>{_elem.title}</h6>

                                    <img
                                        className="preview"
                                        src={_index === 0 ? RueckenPreview : GanzkoerperPreview}
                                        alt="Vorschau"
                                        onClick={() => showVideoPreview(_index)}
                                    />

                                    {_elem.items.map((_description, _descriptionIndex) => (
                                        <div key={_descriptionIndex} className="entry">
                                            <img
                                                className="image"
                                                src={_index === 0 ? RueckenCheck : GanzkoerperCheck}
                                            />
                                            <p>{_description}</p>
                                        </div>
                                    ))}

                                    <div className="buttons">
                                        <a
                                            href={getRegisterProgramUrl(config.PROGRAMS[_index].NAME)}
                                            className="btn-gym"
                                        >
                                            {_elem.ctaMain}
                                        </a>
                                        <Link
                                            to={getProgramPath(config.PROGRAMS[_index].NAME)}
                                            className="btn-gym btn-grey"
                                        >
                                            {_elem.ctaMore}
                                            <ArrowLeft />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-offer">
                    <div className="row margin center-xs">
                        <div className="container">
                            <h4>{JsonReader.get('offerPane.title')}</h4>
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 offer">
                            <img src={Offer1} />
                            <img src={Plus} className="plus" />
                        </div>

                        <div className="col-xs-12 col-sm-6 col-md-6 offer app">
                            <img src={Offer2} className="static" />
                            <AnimatedOffer className="offer-video" />
                        </div>
                    </div>

                    <div className="row margin center-xs">
                        {JsonReader.getOffers().map((_elem, _index) => (
                            <div key={_index} className="col-xs-12 col-sm-4 col-md-4 uspBox">
                                <div>
                                    <img
                                        className="image"
                                        src={
                                            _elem.icon === 'calendar'
                                                ? CalendarIcon
                                                : _elem.icon === 'player'
                                                ? PlayerIcon
                                                : LogoIcon
                                        }
                                    />
                                    <div className="text">
                                        <h6>{_elem.description}</h6>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="row margin center-xs cta">
                        <div
                            className="btn-gym"
                            onClick={() => {
                                scrollTo('selectProgram');
                            }}
                        >
                            {JsonReader.get('offerPane.cta')}
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white" id="reimbursement">
                <ReimbursementRateCalculator
                    JsonReader={JsonReader}
                    isFreebieUser={isFreebieUser}
                    action={
                        <div className="btn-gym" onClick={() => scrollTo('selectProgram')}>
                            {JsonReader.get('insurancePane.reimbursementRate.cta')}
                        </div>
                    }
                />
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-partner-list">
                    <div className="row margin center-xs">
                        <div className="container">
                            <h4>{JsonReader.get('partnerListPane.title')}</h4>
                        </div>
                    </div>
                    <div className="row margin center-xs">
                        <div className="slider">
                            <Carousel
                                items={partners}
                                renderItem={(partner, index) => (
                                    <img
                                        src={partner.logo}
                                        alt={partner.name}
                                        className={partner.name.replace(' ', '-')}
                                        key={index}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-reimbursement">
                    <ReimbursementInfo JsonReader={JsonReader} isFreebieUser={isFreebieUser} />
                </div>
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-benefits">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6 first">
                            <h4>{JsonReader.get('summaryPane.title')}</h4>
                            <img className="image-benefits" src={ImgBenefits} />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 second">
                            <h4>{JsonReader.get('summaryPane.title')}</h4>
                            {JsonReader.getSummaryItems().map((_elem, _index) => (
                                <div key={_index} className="entry">
                                    <img className="image" src={icCheck} />
                                    <p>{_elem}</p>
                                </div>
                            ))}
                            <div
                                className="btn-gym"
                                onClick={() => {
                                    scrollTo('selectProgram');
                                }}
                            >
                                {JsonReader.get('summaryPane.cta')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-white">
                <div className="wrapper homepage-faq">
                    <div className="row margin center-xs">
                        <div className="container">
                            <h4>{JsonReader.get('faqPane.title')}</h4>
                        </div>
                    </div>
                    <div className="row margin center-xs">
                        <div className="col-xs-12">
                            <FAQ items={JsonReader.getFAQs()} />
                        </div>
                    </div>
                </div>
            </div>

            {showFixedCta && (
                <div className="fixed-cta">
                    <div
                        className="btn-gym"
                        onClick={() => {
                            scrollTo('selectProgram');
                        }}
                    >
                        {JsonReader.get('fixedCta')}
                    </div>
                </div>
            )}

            {videoIndex !== undefined && (
                <div className="overlay-video-player">
                    <div className="overlay-video-player__container">
                        <VideoPreview url={JsonReader.program__getVideoByProgramIndex(videoIndex, 'heroPane')} />
                        <div className="overlay-video-player__container__close" onClick={hideVideoPreview}>
                            &times;
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Homepage;
