import { QA } from 'components/dashboard/FAQ';
import { StaticProps, UnitStaticDataProps } from '../context';

class ReadWrapper {
    staticData: unknown;
    placeholder = '';

    constructor(staticData: unknown) {
        this.staticData = staticData;
    }

    /* get data from JSONs, parameter: string of nested keys, i.e. 'key1.key11.key111' */
    getByKeys = (keys: string) => {
        const keysArr = keys.split('.');
        type _dataProps = Record<string, unknown> | string | number | null;
        let _data = Object.assign({}, this.staticData) as _dataProps;
        keysArr.forEach((key) => {
            if (typeof _data === 'object' && _data !== null) {
                if (key in _data) {
                    _data = _data[key] as _dataProps;
                } else {
                    _data = null;
                    return false;
                }
            }
        });
        return _data;
    };

    get = (keys: string) => {
        return (this.getByKeys(keys) as string) ?? this.placeholder;
    };
}

export class ReaderPreLogin extends ReadWrapper {
    programIndex = -1;

    constructor(staticData: StaticProps, programId?: number) {
        super(staticData);
        if (Object.keys(staticData).length > 0 && programId) {
            this.programIndex = (staticData.programs as unknown as Record<string, string>[]).findIndex(
                (_p: Record<string, unknown>) => {
                    return (_p.programIds as string[]).includes(`${programId}`);
                },
            );
        }
    }

    /* general */
    getHeroSubtitles = () => {
        return (this.getByKeys('heroPane.items') as unknown as Record<string, string>[]) ?? [];
    };

    getAdvantages = () => {
        return (this.getByKeys('uspPane.listItems') as unknown as Record<string, string>[]) ?? [];
    };

    getPrograms = () => {
        return (
            (this.getByKeys('programsPane.programs') as unknown as (Record<string, string> & { items: string[] })[]) ??
            []
        );
    };

    getOffers = () => {
        return (this.getByKeys('offerPane.items') as unknown as Record<string, string>[]) ?? [];
    };

    getReimbursements = (isPartner: boolean, isReward = false) => {
        const typeOfCustomer = isPartner ? 'partnerUsers' : 'payingUsers';
        const copyIdentifier = `reimbursementPane.${typeOfCustomer}.items`;
        const copy = (this.getByKeys(copyIdentifier) as unknown as Record<string, string>[]) ?? [];

        if (isReward) {
            return (
                (this.program__getByKeys(`rewardProgram.${copyIdentifier}`) as unknown as Record<string, string>[]) ??
                copy
            );
        }

        return copy;
    };

    getReimbursementRates = () => {
        const rates =
            (this.getByKeys('insurancePane.reimbursementRate.companies') as unknown as Record<string, string>[]) ?? [];
        // sorting a-z
        rates.sort((a, b) => a.name.localeCompare(b.name));
        return rates;
    };

    getFAQs = () => {
        return (this.getByKeys('faqPane.items') as unknown as QA[]) ?? [];
    };

    getSummaryItems = (isReward = false) => {
        const copy = (this.getByKeys('summaryPane.listItems') as unknown as string[]) ?? [];

        if (isReward) {
            return (this.program__getByKeys(`rewardProgram.summaryPane.listItems`) as unknown as string[]) ?? copy;
        }

        return copy;
    };
    /* end general */

    /* program special */
    program__getByKeys = (keys: string) => {
        const pkeys = `programs.${this.programIndex}.${keys}`;
        return this.getByKeys(pkeys);
    };
    program__get = (keys: string, rewardPrg = false) => {
        const copy = (this.program__getByKeys(keys) as string) ?? this.placeholder;
        if (rewardPrg) {
            return (this.program__getByKeys(`rewardProgram.${keys}`) as string) ?? copy;
        }
        return copy;
    };

    program__getVideoByProgramIndex = (programIndex: number, keys: string) => {
        return (this.getByKeys(`programs.${programIndex}.${keys}.videoURL`) as string) ?? this.placeholder;
    };

    program__getUsp = () => {
        return (this.program__getByKeys('uspPane') as unknown as Record<string, string>[]) ?? [];
    };

    program__getPriceListItems = (isReward = false) => {
        const copy = (this.program__getByKeys('pricePane.listItems') as unknown as string[]) ?? [];

        if (isReward) {
            return (this.program__getByKeys('rewardProgram.pricePane.listItems') as unknown as string[]) ?? copy;
        }

        return copy;
    };

    program__getTrainerContent = () => {
        return (this.program__getByKeys('trainerPane.content') as unknown as Record<string, string>[]) ?? [];
    };
}

export class ReaderPostLogin extends ReadWrapper {
    constructor(staticData: UnitStaticDataProps) {
        super(staticData);
    }

    getExplanations = () => {
        return (this.getByKeys('module.explanationPane.cards') as unknown as Record<string, string>[]) ?? [];
    };

    getExercises = () => {
        return (this.getByKeys('module.homeworkPane.listItems') as unknown as string[]) ?? [];
    };

    getQuizData = () => {
        return (this.getByKeys('module.quizPane.answers') as unknown as string[]) ?? [];
    };

    getProgramCompletedItems = () => {
        return (this.getByKeys('program.completedPane.listItems') as unknown as string[]) ?? [];
    };
}
