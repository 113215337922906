import config from '../state/config';

export class ApiError extends Error {
    constructor(error: Record<string, unknown>) {
        super();
        this.name = 'ApiError';
        let message = 'Houston, wir haben ein Problem!';
        const reason = error && error.data && ((error.data as Record<string, unknown>).reason as number);
        const code = error && error.data && (error.data as Record<string, unknown>).code;

        if (code === config.ERROR_CODES.INVALID_PASSWORD_EXCEPTION) {
            message =
                'Bitte verwende ein Passwort mit mindestens 8 Zeichen, einer Ziffer und einem Buchstaben (ohne Leerzeichen).';
        }
        if (code === config.ERROR_CODES.USER_NOT_FOUND_EXCEPTION) {
            message = 'Dieser Account existiert nicht.';
        }
        if (code === config.ERROR_CODES.DUPLICATE_USER_EXCEPTION) {
            message =
                'Ein Gymondo Account mit diesen Login-Daten existiert bereits. Bitte gib dein korrektes Passwort ein.';
        }
        if (code === config.ERROR_CODES.NOT_AUTHORIZED_EXCEPTION) {
            message = 'Bitte gib dein korrektes Passwort ein.';
        }
        if (code === config.ERROR_CODES.LIMIT_EXCEEDED_EXCEPTION) {
            message = 'Bitte versuchen Sie es nach einiger Zeit erneut.';
        }
        if (code === config.ERROR_CODES.INVALID_PARTNER_USER_EXCEPTION) {
            message = 'Die Krankenversicherungsnummer und die Benutzerinformationen sind leider ungültig.';
        }
        if (code === config.ERROR_CODES.ALREADY_REGISTERED_PARTNER_USER_EXCEPTION) {
            message =
                'Die Krankenversicherungsnummer und/oder Benutzerinformationen wurden bereits für ein anderes Präventionsprogramm verwendet.';
        }
        if (reason === config.ERROR_REASONS.INCOMPATIBLE_VOUCHER) {
            message = (error.data as Record<string, string>).message;
        }
        if (reason === config.ERROR_REASONS.VOUCHER_NOT_EXISTING) {
            message = 'Diesen Gutschein gibt es nicht.';
        }
        if (reason === config.ERROR_REASONS.NO_RIGHTS) {
            message = 'Dafür fehlt Dir leider die Berechtigung.';
        }
        if (reason === config.ERROR_REASONS.PROGRAM_ALREADY_BOUGHT) {
            message = 'Du hast dieses Programm bereits bezahlt.';
        }
        if (reason === config.ERROR_REASONS.INVALID_CHANNEL) {
            message = 'Channel konnte nicht gefunden werden.';
        }

        this.message = message;
    }
}
