import React, { useContext } from 'react';
import { AccountNavigation, PaymentConfirmationItem } from '..';
import { AppStateContext } from '../../state/context';
import { AccountBackround } from '../../assets';
import { AccountBackroundMobile } from '../../assets';

const PaymentConfirmation: React.FC = () => {
    const {
        appState: { user },
    } = useContext(AppStateContext);
    const isFreebie = user.isFreebie;

    return (
        <>
            <div className="container-fluid no-padding first-container">
                <div className="row center-xs">
                    <div className="image-module col">
                        <h1>Mein Konto</h1>
                        <div className="image__background">
                            <img src={AccountBackround} />
                            <img src={AccountBackroundMobile} className="background-mobile" />
                        </div>
                    </div>
                </div>
            </div>
            <AccountNavigation isFreebie={isFreebie} />
            <div className="container wrapper spacing-pt-pb-0 payment-wrapper">
                <div className="row center-xs">
                    <div className="col-xs-12 col-sm-8 col-md-7 spacing-tb-xl">
                        <h6>Zahlungsbestätigung</h6>
                        {user.purchaseHistory.map((item) => (
                            <PaymentConfirmationItem key={item.id as React.Key} item={item} account={user.account} />
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
};
export default PaymentConfirmation;
