import React, { useState } from 'react';
import Typography from '@gymondo/frontend-core/typography';
import classNames from 'classnames';
import { Icon } from '@gymondo/frontend-core/components';
import { ReactComponent as arrowIcon } from '@gymondo/frontend-core/svg/arrow-slim.svg';
import style from './Contraindications.module.scss';

const ContraindicationsNote: React.FC = () => {
    const [accordion, setAccordion] = useState(false);

    return (
        <>
            <Typography as="p" color="main">
                Bevor es weitergeht...
            </Typography>
            <Typography as="h2" color="main" variant="h4">
                Deine Gesundheit liegt uns am Herzen!
            </Typography>
            <Typography as="p" color="main" variant="h6">
                Hast du gesundheitliche Einschränkungen?
            </Typography>
            <Typography as="p" color="main">
                Die Übungen und Maßnahmen in diesem Präventionskurs sind nicht für jeden geeignet.
                <br />
                Du leidest unter chronischen oder akuten Erkrankungen oder bist schwanger?
                <br />
                Du bist dir nicht sicher, ob du aufgrund deines Gesundheitszustands am Kurs teilnehmen solltest?
            </Typography>
            <Typography as="p" color="main">
                <strong>Dann sprich bitte vor Beginn des Kurses mit deinem Arzt / deiner Ärztin!</strong>
                <br />
                <br />
            </Typography>
            <Typography as="p" color="main">
                Kontraindikationen: Bei folgenden Erkrankungen raten wir vehement von einer Kursteilnahme ab.
            </Typography>
            <div className={style.accordion}>
                <Typography
                    as="button"
                    color="main"
                    variant="button"
                    className={classNames(style.cta, { [style.active]: accordion })}
                    onClick={(e: { preventDefault: () => void }) => {
                        e.preventDefault();
                        setAccordion(!accordion);
                    }}
                >
                    Alle Kontraindikationen anzeigen
                    <Icon data={arrowIcon} className={style.arrow} />
                </Typography>
                <div className={classNames(style.list, { [style.active]: accordion })}>
                    <ul>
                        <Typography as="li" color="main">
                            Persönlichkeitsstörungen
                        </Typography>
                        <Typography as="li" color="main">
                            Psychische Erkrankungen
                        </Typography>
                        <Typography as="li" color="main">
                            Epilepsie
                        </Typography>
                        <Typography as="li" color="main">
                            Verletzungen am Herzen
                        </Typography>
                        <Typography as="li" color="main">
                            Bösartige Tumore
                        </Typography>
                        <Typography as="li" color="main">
                            Schweres Wirbelsäulentrauma
                        </Typography>
                        <Typography as="li" color="main">
                            Kürzliches Schädel-Hirn-Trauma
                        </Typography>
                        <Typography as="li" color="main">
                            Herzinfarkt
                        </Typography>
                        <Typography as="li" color="main">
                            Behandlungsbedürftige Erkrankungen des muskuloskelettalen Bewegungsapparates (z. B. Krebs,
                            Knochenbrüche, Infektionen, Cauda-equina-Syndrom)
                        </Typography>
                    </ul>
                </div>
            </div>
            <Typography as="p" color="main" variant="caption">
                Die Übungen des Präventionskurses sind kein Ersatz für eine ärztliche Untersuchung oder Behandlung.
            </Typography>
            <Typography as="p" color="main" variant="caption">
                Dieses Online-Programm wird auf eigene Gefahr genutzt.
            </Typography>
        </>
    );
};

export default ContraindicationsNote;
