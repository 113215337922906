import {
    VisaLogo,
    MastercardLogo,
    AmexLogo,
    PaypalLogo,
    ApplePayLogo,
    GooglePayLogo,
    SepaLogo,
} from '../assets/payment';
import { getEnvVar, isProdEnv } from 'utils/env';

export const MERCHANT_NAME = 'Gymondo GmbH';

export const COUNTRY_CODE = 'DE';

export const ADYEN_CONFIG = {
    locale: 'de-DE',
    environment: isProdEnv ? 'live' : 'test',
    clientKey: getEnvVar('ADYEN_CLIENT_KEY'),
    storePaymentMethod: true,
};

/* methods */
export const PAYMENT_METHODS = {
    CARD: 'card',
    PAYPAL: 'paypal',
    SEPA: 'sepa',
    APPLE_PAY: 'applepay',
    GOOGLE_PAY: 'paywithgoogle',
};

const CARD_TYPES = {
    VISA: { name: 'visa', logo: VisaLogo },
    MASTERCARD: { name: 'mc', logo: MastercardLogo },
    AMEX: { name: 'amex', logo: AmexLogo },
};

const WALLET_TYPES = {
    PAYPAL: { name: 'paypal', logo: PaypalLogo },
};

const MOBILE_PAYMENT_TYPES = {
    APPLE_PAY: {
        name: 'applepay',
        logo: ApplePayLogo,
    },
    GOOGLE_PAY: {
        name: 'paywithgoogle',
        logo: GooglePayLogo,
    },
};

const BANK_TRANSFER_TYPES = {
    SEPA: { name: 'sepa', logo: SepaLogo },
};

export interface CardTypeProps {
    name: string;
    logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

export interface PaymentMapProps {
    name: string;
    types: CardTypeProps[];
}

export const PAYMENT_MAP = [
    {
        name: PAYMENT_METHODS.CARD,
        types: [CARD_TYPES.VISA, CARD_TYPES.MASTERCARD, CARD_TYPES.AMEX],
    },
    {
        name: PAYMENT_METHODS.PAYPAL,
        types: [WALLET_TYPES.PAYPAL],
    },
    {
        name: PAYMENT_METHODS.SEPA,
        types: [BANK_TRANSFER_TYPES.SEPA],
    },
    {
        name: PAYMENT_METHODS.APPLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.APPLE_PAY],
    },
    {
        name: PAYMENT_METHODS.GOOGLE_PAY,
        types: [MOBILE_PAYMENT_TYPES.GOOGLE_PAY],
    },
];

export enum SIGNUP_STEPS {
    ONBOARDING = 'onboarding',
    PROVIDE_PAYMENT_INFO = 'providePaymentInfo',
}

export const RESULT_CODES = {
    AUTHENTICATION_FINISHED: 'AuthenticationFinished',
    AUTHORISED: 'Authorised',
    CANCELLED: 'Cancelled',
    CHALLENGE_SHOPPER: 'ChallengeShopper',
    ERROR: 'Error',
    IDENTIFY_SHOPPER: 'IdentifyShopper',
    PENDING: 'Pending',
    PRESENT_TO_SHOPPER: 'PresentToShopper',
    RECEIVED: 'Received',
    REDIRECT_SHOPPER: 'RedirectShopper',
    REFUSED: 'Refused',
};

export const REFUSAL_REASONS = {
    THREE_D_SECURE_AUTH_FAILED: '3d-secure: Authentication failed',
    THREE_D_NOT_AUTHENTICATED: '3D Not Authenticated',
    ACQUIRER_FRAUD: 'Acquirer Fraud',
    AUTHENTICATION_REQUIRED: 'Authentication Required',
    BLOCKED_CARD: 'Blocked Card',
    CANCELLED: 'Cancelled',
    CVC_DECLINED: 'CVC Declined',
    REFUSED: 'Refused',
    DECLINED_NON_GENERIC: 'Declined Non Generic',
    ACQUIRER_ERROR: 'Acquirer Error',
    EXPIRED_CARD: 'Expired Card',
    FRAUD: 'FRAUD',
    FRAUD_CANCELLED: 'FRAUD-CANCELLED',
    INVALID_AMOUNT: 'Invalid Amount',
    INVALID_CARD_NUMBER: 'Invalid Card Number',
    INVALID_PIN: 'Invalid Pin',
    ISSUER_SUSPECTED_FRAUD: 'Issuer Suspected Fraud',
    ISSUER_UNAVAILABLE: 'Issuer Unavailable',
    NOT_ENOUGH_BALANCE: 'Not enough balance',
    NOT_SUBMITTED: 'Not Submitted',
    NOT_SUPPORTED: 'Not supported',
    PENDING: 'Pending',
    PIN_TRIES_EXCEEDED: 'Pin tries exceeded',
    PIN_VALIDATION_NOT_POSSIBLE: 'Pin validation not possible',
    REFERRAL: 'Referral',
    RESTRICTED_CARD: 'Restricted Card',
    REVOCATION_OF_AUTH: 'Revocation Of Auth',
    SHOPPER_CANCELLED: 'Shopper Cancelled',
    WITHDRAWAL_COUNT_EXCEEDED: 'Withdrawal count exceeded',
    WITHDRAWAL_AMOUNT_EXCEEDED: 'Withdrawal amount exceeded',
    TRANSACTION_NOT_PERMITTED: 'Transaction Not Permitted',
    UNKNOWN: 'Unknown',
};
