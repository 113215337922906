import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@gymondo/frontend-core/components';
import { dispatch, DispatchActions } from '../../state/actions';
import { AppStateContext } from '../../state/context';
import { hasLocationPath, hasLocationSearchParam, isLocationPath, redirect } from '../../utils/system';
import text from '../../lang';

import { SignInModal } from '..';
import { HeaderLogo, HeaderLogoCoralMobile, HeaderLogoWhite, HeaderLogoWhiteMobile } from '../../assets';
import { PATHS } from '../../state/config';

let showLogin = false;
const minScrollForUpButton = 500;
const hasScrollToTop = false;

interface HeaderProps {
    transparent?: boolean;
    textWhite?: boolean;
}

const Header: React.FC<HeaderProps> = ({ transparent, textWhite }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [mobileNav, setMobileNav] = useState(false);
    const [showSignInModal, setShowSignInModal] = useState(false);

    const context = useContext(AppStateContext);
    const isAuthenticated = context.appState.isAuthenticated;
    const isInitiated = context.appState.user.paymentCompleted && context.appState.user.onboardingCompleted;

    const hasShowLoginParam = hasLocationSearchParam('show_login');
    if (!isAuthenticated && hasShowLoginParam && !showLogin) {
        showLogin = true;
        setShowSignInModal(true);
    }

    const handleSignInModal = useCallback(() => {
        setShowSignInModal(!showSignInModal);
    }, [showSignInModal]);

    const handleSignOut = useCallback(async () => {
        try {
            await dispatch(DispatchActions.signOut);
            // reload
            redirect(PATHS.HOMEPAGE);
        } catch (error: unknown) {
            const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
            console.log(_errData);
        }
    }, []);

    const handleMobileNav = useCallback(() => {
        setMobileNav(!mobileNav);
    }, [mobileNav]);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position);
    };

    useEffect(() => {
        if (!hasScrollToTop) {
            return;
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <header
                id="top"
                className={`header top-header${!transparent ? ' top-header--white' : ''}${
                    textWhite ? ' top-header--textwhite' : ''
                }${mobileNav ? ' menu-open' : ''}`}
            >
                <div className="top-header__wrapper_">
                    <section className="top-header__content">
                        <Link to={PATHS.HOMEPAGE} className="gym-logo">
                            {textWhite ? <HeaderLogoWhite /> : <HeaderLogo />}
                            {textWhite ? (
                                <HeaderLogoWhiteMobile className="logo-mobile" />
                            ) : (
                                <HeaderLogoCoralMobile className="logo-mobile" />
                            )}
                        </Link>
                        <nav className="top-nav">
                            {isInitiated ? (
                                <ul className="top-nav__list">
                                    <li
                                        className={`top-nav__item${
                                            hasLocationPath(PATHS.TIMELINE.replace(':moduleId', '')) ? ' active' : ''
                                        }`}
                                    >
                                        <Link to={PATHS.HOMEPAGE}>{text('your_session')}</Link>
                                    </li>
                                    <li
                                        className={`top-nav__item${
                                            isLocationPath(PATHS.ACCOUNT) ||
                                            isLocationPath(PATHS.PAYMENT_CONFIRMATION) ||
                                            isLocationPath(PATHS.PARTICIPATION_CONFIRMATION)
                                                ? ' active'
                                                : ''
                                        }`}
                                    >
                                        <Link to={PATHS.ACCOUNT}>{text('account_user_data')}</Link>
                                    </li>
                                    <li className={`top-nav__item${isLocationPath(PATHS.FAQ) ? ' active' : ''}`}>
                                        <Link to={PATHS.FAQ}>FAQ</Link>
                                    </li>
                                </ul>
                            ) : (
                                <ul className="top-nav__list">
                                    <li className={`top-nav__item${isLocationPath(PATHS.FAQ) ? ' active' : ''}`}>
                                        <Link to={PATHS.FAQ}>FAQ</Link>
                                    </li>
                                </ul>
                            )}
                            {isAuthenticated ? (
                                <Button className="btn--small btn--border" onClick={handleSignOut}>
                                    {text('signout_button')}
                                </Button>
                            ) : (
                                <Button className="btn--small btn--border" onClick={handleSignInModal}>
                                    {text('signin_button')}
                                </Button>
                            )}
                        </nav>
                        <nav className="menu-drawer__ctrls">
                            <ul className="menu-drawer-actions">
                                <li className="menu-drawer-actions__item">
                                    <button className="drawer-btn drawer-btn--black" onClick={handleMobileNav}>
                                        <div className="drawer-btn__bar"></div>
                                        <div className="drawer-btn__bar last"></div>
                                        <div className="drawer-btn__close"></div>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                        <nav className="menu-drawer">
                            <div className="menu-drawer__menu">
                                {isInitiated ? (
                                    <ul className="menu-drawer__list">
                                        <li
                                            className={`top-nav__item${
                                                hasLocationPath(PATHS.TIMELINE.replace(':moduleId', ''))
                                                    ? ' active'
                                                    : ''
                                            }`}
                                        >
                                            <Link to={PATHS.HOMEPAGE} onClick={handleMobileNav}>
                                                {text('your_session')}
                                            </Link>
                                        </li>
                                        <li
                                            className={`top-nav__item${
                                                isLocationPath(PATHS.ACCOUNT) ||
                                                isLocationPath(PATHS.PAYMENT_CONFIRMATION) ||
                                                isLocationPath(PATHS.PARTICIPATION_CONFIRMATION)
                                                    ? ' active'
                                                    : ''
                                            }`}
                                        >
                                            <Link to={PATHS.ACCOUNT} onClick={handleMobileNav}>
                                                {text('account_user_data')}
                                            </Link>
                                        </li>
                                        <li className={`top-nav__item${isLocationPath(PATHS.FAQ) ? ' active' : ''}`}>
                                            <Link to={PATHS.FAQ} onClick={handleMobileNav}>
                                                FAQ
                                            </Link>
                                        </li>
                                    </ul>
                                ) : (
                                    <ul className="menu-drawer__list">
                                        <li className={`top-nav__item${isLocationPath(PATHS.FAQ) ? ' active' : ''}`}>
                                            <Link to={PATHS.FAQ} onClick={handleMobileNav}>
                                                FAQ
                                            </Link>
                                        </li>
                                    </ul>
                                )}
                                {isAuthenticated ? (
                                    <Button className="btn--small btn--border" onClick={handleSignOut}>
                                        {text('signout_button')}
                                    </Button>
                                ) : (
                                    <Button className="btn--small btn--border" onClick={handleSignInModal}>
                                        {text('signin_button')}
                                    </Button>
                                )}
                            </div>
                        </nav>
                    </section>
                </div>
            </header>
            {hasScrollToTop && scrollPosition > minScrollForUpButton && (
                <a href="#top" className="scroll-top">
                    <div className="circle">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <g fill="#fff">
                                <path
                                    d="M16 5H3.83l2.88-2.88c.39-.39.39-1.02 0-1.41C6.32.32 5.69.32 5.3.71L.71 5.3c-.39.39-.39 1.02 0 1.41L5.3 11.3c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L3.83 7H16c.55 0 1-.45 1-1s-.45-1-1-1z"
                                    transform="translate(-1051 -718) translate(1041 708) rotate(-90 22 12) rotate(-180 10.5 9)"
                                />
                            </g>
                        </svg>
                    </div>
                    <div className="text">Zurück nach oben</div>
                </a>
            )}
            {showSignInModal && <SignInModal onClose={handleSignInModal} />}
        </>
    );
};

export default Header;
