import { Offer2AppWebm, Offer2AppMp4 } from 'assets';
import React, { useRef, useEffect, DetailedHTMLProps, VideoHTMLAttributes } from 'react';

export const AnimatedOffer = (props: DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>) => {
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        const videoElement = videoRef.current;

        if (!videoElement) {
            return;
        }

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    try {
                        if (entry.isIntersecting) {
                            videoElement.play();
                        } else {
                            videoElement.pause();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                });
            },
            { threshold: 0.25 }, // % of the video has to be visible to start playing
        );

        if (videoElement) {
            observer.observe(videoElement);
        }

        return () => {
            if (videoElement) {
                observer.unobserve(videoElement);
            }
        };
    }, []);

    return (
        <video ref={videoRef} loop playsInline muted {...props}>
            <source src={Offer2AppWebm} type="video/webm" />
            <source src={Offer2AppMp4} type="video/mp4" />
        </video>
    );
};
