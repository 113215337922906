import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PATHS } from '../../state/config';
import { AppStateContext } from '../../state/context';
import { getLocalDate } from '../../utils/string';

const PurchaseNotAllowed: React.FC = () => {
    const history = useHistory();
    const {
        appState: { user },
    } = useContext(AppStateContext);

    useEffect(() => {
        if (user.canPurchaseOn <= Date.now()) {
            history.push(PATHS.HOMEPAGE);
            return;
        }
    }, [user.canPurchaseOn]);

    if (user.canPurchaseOn === 0) {
        return <></>;
    }

    return (
        <>
            <div className="container_ wrapper legal first-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                        <h1>Bitte beachte, dass dir nur ein Präventionskurs pro Jahr erstattet wird.</h1>
                        <br />
                        <br />
                        Du kannst am <b>am {getLocalDate(user.canPurchaseOn)}</b> den nächsten Präventionskurs starten.
                    </div>
                </div>
            </div>
        </>
    );
};

export default PurchaseNotAllowed;
