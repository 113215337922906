import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import useScript from '@gymondo/frontend-core/hooks/useScript';
import { CardTypeProps, PAYMENT_MAP, PAYMENT_METHODS, RESULT_CODES } from 'state/configPayment';
import { createAdyenCheckout, isGooglePayAvailable } from 'utils/payment';
import { Alert, Button, LoadingSpinner } from '@gymondo/frontend-core/components';
import Methods from './Methods';
import { googleIsReadyToPayRequest } from './google-pay/config';
import text from '../../lang';
import { AppStateContext } from 'state/context';
import { dispatch, DispatchActions } from 'state/actions';
import { getFormattedPrice } from 'utils/string';
import { default as appConfig, GYM_12M_REWARD_PRODUCTS, PARTNER_KEYS, PATHS } from 'state/config';
import { ApplePay, Card, GooglePay, Paypal, Sepa } from './use-methods';
import { ApiError } from 'utils/error';
import { redirect } from 'utils/system';
import { getProgram } from 'state/helpers/program';
import {
    clearStoredInsuranceData,
    clearStoredRedeemedVoucher,
    getStoredChannelId,
    getStoredInsuranceData,
    getStoredPartnerKey,
    getStoredProductId,
    getStoredProgramId,
    getStoredRedeemedVoucher,
} from 'state/session';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-use';
import qs from 'query-string';

const components = {
    [PAYMENT_METHODS.CARD]: Card,
    [PAYMENT_METHODS.SEPA]: Sepa,
    [PAYMENT_METHODS.PAYPAL]: Paypal,
    [PAYMENT_METHODS.APPLE_PAY]: ApplePay,
    [PAYMENT_METHODS.GOOGLE_PAY]: GooglePay,
};

const Payment: React.FC = () => {
    const history = useHistory();
    const [canUseGooglePay, setCanUseGooglePay] = useState<boolean | null>(null);
    const [method, setMethod] = useState('');
    const [error, setError] = useState<{ message: string; reason: number } | null>(null);
    const [loading, setLoading] = useState(false);

    const context = useContext(AppStateContext);

    const program_id = getStoredProgramId();
    const program = getProgram(program_id);
    const product_id = getStoredProductId();
    const partnerKey = getStoredPartnerKey();
    const channel_id = getStoredChannelId();
    const product = context.appState.user.products.find((p) => p.id === product_id);
    const zeroPriceProduct = product && product.price === 0;
    const { search } = useLocation();

    const voucher = getStoredRedeemedVoucher();
    const isFreebieVoucher = voucher?.['@type'] === 'FreeSignup';
    const query = qs.parse(search || '');

    const Component = components[method];
    const showGymondoRewardInfo = GYM_12M_REWARD_PRODUCTS.includes(product_id);
    const shouldShowPaymentMethods = !Component && !error && !isFreebieVoucher && !zeroPriceProduct;

    const handleSuccess = useCallback(async () => {
        // Creates the plan right after the purchase is successful
        await dispatch(DispatchActions.trackPaymentSuccess, {
            pspReference: query.pspReference,
            programId: program_id,
            userId: context.appState.user.account.id,
        });
        await dispatch(DispatchActions.pollPurchase, { program_id });
        await dispatch(DispatchActions.pollPlan, { program_id });
        if (context.appState.user.isLoaded && context.appState.user.onboardingCompleted) {
            redirect(PATHS.HOMEPAGE);
            return;
        }
        redirect(PATHS.ONBOARDING);
    }, []);

    useEffect(() => {
        // eslint-disable-next-line func-names
        (async function () {
            if (context.appState.user.isLoaded && query.sessionId && query.redirectResult) {
                console.log('got redirect result');
                setLoading(true);
                const instance = await createAdyenCheckout(
                    {
                        // eslint-disable-next-line no-unused-vars
                        onPaymentCompleted: async (response: any, _component: never) => {
                            if (response.resultCode === RESULT_CODES.AUTHORISED) {
                                await handleSuccess();
                                return;
                            }
                            setLoading(false);
                            setError({ message: response.resultCode, reason: -1 });
                        },
                    },
                    { id: query.sessionId },
                );
                instance.submitDetails({
                    details: { redirectResult: query.redirectResult },
                });
            }
        })();
    }, [dispatch, context.appState.user]);

    useEffect(() => {
        (async function () {
            const purchaseProgram = async () => {
                try {
                    const insuranceData = getStoredInsuranceData();
                    if (insuranceData && program) {
                        const patchedUser = {
                            ...context.appState.user.account,
                            healthInsurance: insuranceData.insuranceNumber,
                        };

                        if (insuranceData.birthDate) {
                            patchedUser.birthDate = insuranceData.birthDate;
                        }
                        await dispatch(DispatchActions.patchInsurance, { account: patchedUser }, context);

                        clearStoredInsuranceData();
                    }
                    const voucher = getStoredRedeemedVoucher();
                    const redirectAfterPurchase = async () => {
                        await dispatch(DispatchActions.trackPaymentSuccess, {
                            programId: program_id,
                            userId: context.appState.user.account.id,
                        });
                        await dispatch(DispatchActions.pollPlan, { program_id });
                        if (context.appState.user.isLoaded && context.appState.user.onboardingCompleted) {
                            redirect(PATHS.HOMEPAGE);
                            return;
                        }
                        redirect(PATHS.ONBOARDING);
                        return;
                    };
                    if (isFreebieVoucher) {
                        setLoading(true);
                        await dispatch(DispatchActions.redeemVoucher, { product_id, voucher });
                        await redirectAfterPurchase();
                    }
                    if (zeroPriceProduct) {
                        setLoading(true);
                        await dispatch(DispatchActions.createPurchase, { program_id, product_id, channel_id });
                        await redirectAfterPurchase();
                    }
                    clearStoredRedeemedVoucher();
                } catch (error: unknown) {
                    const _err = error as Record<string, unknown>;
                    const _error = new ApiError(_err);
                    setError({ message: _error.message, reason: _err.reason as number });
                    // no need to set to false when path successful as redirect hits before
                    setLoading(false);
                }
            };
            // check if user can purchase
            if (context.appState.user.canPurchaseOn > Date.now()) {
                history.push(PATHS.PURCHASE_NOT_ALLOWED);
                return;
            }
            // if no order
            if (!context.appState.user.paymentCompleted && !query.sessionId && !query.redirectResult) {
                purchaseProgram();
            }
        })();
    }, [context, search, isFreebieVoucher]);

    const [googlePayScriptLoaded] = useScript('https://pay.google.com/gp/p/js/pay.js');

    // Disable Apple Pay for now (See https://gymondo.atlassian.net/browse/ET-72)
    const canUseApplePay = false; // isApplePayAvailable();

    useEffect(() => {
        const checkGooglePay = async () => {
            const isAvailable = await isGooglePayAvailable(googleIsReadyToPayRequest);
            setCanUseGooglePay(isAvailable);
        };
        if (googlePayScriptLoaded) {
            checkGooglePay();
        }
    }, [googlePayScriptLoaded]);

    const renderError = useMemo(() => {
        if (error === null) {
            return <></>;
        }
        return (
            <>
                <Alert>{error.message}</Alert>
                {(error.reason === appConfig.ERROR_REASONS.PROGRAM_ALREADY_BOUGHT ||
                    error.reason === appConfig.ERROR_REASONS.ACTIVE_SUBSCRIPTION) && (
                    <div className="err-button-wrapper">
                        <a className="link" href={PATHS.HOMEPAGE} title={text('your_session')}>
                            <Button className="err-button">{text('your_session')}</Button>
                        </a>
                        <a className="link" href={PATHS.ACCOUNT} title={text('account_user_data')}>
                            <Button className="err-button">{text('account_user_data')}</Button>
                        </a>
                    </div>
                )}
                {/* todo, if needed?: if on sign up the voucher should be send w/o redeem? then save in session, send as parameter on authorizePayment and make here remove voucher or enter new? */}
                {error.reason === appConfig.ERROR_REASONS.INVALID_VOUCHER && <></>}
            </>
        );
    }, [error]);

    const handleSelectMethod = (key: string) => {
        setMethod(key);
    };

    // loader
    if ((error === null && canUseGooglePay === null) || loading || !context.appState.user.isLoaded || !product) {
        return <LoadingSpinner type="page" />;
    }

    return (
        <div id="Payment">
            {error && renderError}
            {shouldShowPaymentMethods && (
                <div className="container wrapper spacing-pt-pb-0 first-container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-7 col-xs-offset-0 col-sm-offset-2 col-md-offset-2 spacing-tb-xl no-padding-bottom">
                            <div className="box-row">
                                <h5>{text('subscribe_title')}</h5>
                                <p>
                                    Starte jetzt deinen <b>{program?.TITLE}</b> für{' '}
                                    {getFormattedPrice(
                                        product?.price as number,
                                        // orderData.order.currencyCode as string,
                                        'EUR',
                                    )}
                                    {showGymondoRewardInfo && (
                                        <>
                                            . Du kannst dich sofort mit deinen Anmeldedaten auf Gymondo und der{' '}
                                            <a
                                                href="https://www.gymondo.com/de/"
                                                target="_blank"
                                                className="link"
                                                rel="noreferrer"
                                            >
                                                Gymondo
                                            </a>{' '}
                                            Präventionsplattform einloggen und loslegen.
                                        </>
                                    )}
                                </p>
                                <p>
                                    {text('subscribe_description')}
                                    {GYM_12M_REWARD_PRODUCTS.includes(product_id) &&
                                        text('subscribe_description_product_with_reward')}
                                </p>

                                <Methods
                                    paymentMap={PAYMENT_MAP}
                                    isApplePay={canUseApplePay}
                                    isGooglePay={Boolean(canUseGooglePay)}
                                    callbackSelect={handleSelectMethod}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {Component && (
                <div className="container wrapper spacing-pt-pb-0 first-container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-7 col-xs-offset-0 col-sm-offset-2 col-md-offset-2 spacing-tb-xl no-padding-bottom">
                            <div className="box-row">
                                <div onClick={() => handleSelectMethod('')}>
                                    <p style={{ cursor: 'pointer', textDecoration: 'underline' }}>
                                        {text('subscribe_change_payment_method')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-8 col-md-7 col-xs-offset-0 col-sm-offset-2 col-md-offset-2 spacing-tb-xl no-padding-top">
                            <div className="box-row">
                                <h5>
                                    Zahle jetzt {getFormattedPrice(product?.price as number, 'EUR')} mit{' '}
                                    {text(`subscribe_method_title_${method}`)}
                                </h5>
                                <Component
                                    paymentMethod={method}
                                    types={PAYMENT_MAP.find((_m) => _m.name === method)?.types as CardTypeProps[]}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Payment;
