import React from 'react';
import { Footer, FooterSmall, Header, HeaderSmall } from '..';

interface ContainerProps {
    smallHeader?: boolean;
    smallFooter?: boolean;
    transparentHeader?: boolean;
    textWhiteHeader?: boolean;
    transparentFooter?: boolean;
}

const Container: React.FC<ContainerProps> = ({
    smallHeader,
    smallFooter,
    transparentHeader,
    textWhiteHeader,
    transparentFooter,
    children,
}) => {
    return (
        <>
            {!smallHeader && <Header transparent={transparentHeader} textWhite={textWhiteHeader} />}
            {smallHeader && <HeaderSmall />}
            {children}
            {!smallFooter && <Footer />}
            {smallFooter && <FooterSmall transparent={transparentFooter} />}
        </>
    );
};
export default Container;
