import { InsuranceData, Voucher } from './api.types';

const NAME = 'gymondo-prevention';
interface StorageProps {
    partnerKey: string;
    programId: number;
    productId: number;
    voucherCode: string;
    noContraindication: boolean;
    showVoucher?: boolean;
    redeemedVoucher?: Voucher;
    insuranceData?: InsuranceData;
    channelId?: number;
}
export const initialStorage: StorageProps = {
    partnerKey: '',
    programId: 0,
    productId: 0,
    voucherCode: '',
    noContraindication: false,
};

class Storage {
    static init() {
        sessionStorage.setItem(NAME, JSON.stringify(initialStorage));
    }
    static set(key: keyof StorageProps, value: string | number | boolean) {
        const _data = this.getDataObject();
        _data[key as keyof StorageProps] = value;
        sessionStorage.setItem(NAME, JSON.stringify(_data));
    }

    static get(key: keyof StorageProps) {
        const _data = this.getDataObject() as StorageProps;
        return _data[key as keyof StorageProps];
    }

    static remove(key: keyof StorageProps) {
        const _data = this.getDataObject() as StorageProps;
        delete _data[key];
        sessionStorage.setItem(NAME, JSON.stringify(_data));
    }

    static getDataObject() {
        const value = sessionStorage.getItem(NAME);
        if (!value) {
            return initialStorage;
        }
        try {
            return JSON.parse(value);
        } catch (error: unknown) {
            return initialStorage;
        }
    }

    static clear() {
        sessionStorage.removeItem(NAME);
    }

    static isSet() {
        return sessionStorage.getItem(NAME) !== null;
    }
}

export const initStorage = () => {
    Storage.init();
};

export const clearStorage = () => {
    Storage.clear();
};

export const isStorageSet = () => {
    return Storage.isSet();
};

export const storePartnerKey = (partner: string) => {
    Storage.set('partnerKey', partner);
};

export const getStoredPartnerKey = () => {
    return String(Storage.get('partnerKey'));
};

export const storeProgramId = (programId: number) => {
    Storage.set('programId', programId);
};

export const getStoredProgramId = () => {
    return Number(Storage.get('programId'));
};

export const storeProductId = (productId: number) => {
    Storage.set('productId', productId);
};

export const getStoredProductId = () => {
    return Number(Storage.get('productId'));
};

export const storeVoucherCode = (voucherCode: string) => {
    Storage.set('voucherCode', voucherCode);
};

export const getStoredVoucherCode = () => {
    return String(Storage.get('voucherCode'));
};

export const storeChannelId = (channelId: number) => {
    Storage.set('channelId', channelId);
};

export const getStoredChannelId = () => {
    return Number(Storage.get('channelId'));
};

export const storeShowVoucher = (showVoucher: boolean) => {
    Storage.set('showVoucher', showVoucher);
};

export const getStoredShowVoucher = () => {
    return Boolean(Storage.get('showVoucher'));
};

export const storeRedeemedVoucher = (voucher: Voucher) => {
    Storage.set('redeemedVoucher', JSON.stringify(voucher));
};

export const getStoredRedeemedVoucher = (): Voucher | null => {
    const redeemedVoucher = Storage.get('redeemedVoucher');
    return redeemedVoucher ? JSON.parse(String(redeemedVoucher)) : null;
};

export const clearStoredRedeemedVoucher = () => {
    Storage.remove('redeemedVoucher');
};

export const storeInsuranceData = (insuranceData: InsuranceData) => {
    Storage.set('insuranceData', JSON.stringify(insuranceData));
};

export const getStoredInsuranceData = (): InsuranceData | null => {
    const insuranceData = Storage.get('insuranceData');
    return insuranceData ? JSON.parse(String(insuranceData)) : null;
};

export const clearStoredInsuranceData = () => {
    Storage.remove('insuranceData');
};

export const storeContraindications = () => {
    Storage.set('noContraindication', true);
};

export const getStoredContraindications = () => {
    return Boolean(Storage.get('noContraindication'));
};
