const location = window.location;

export const useLocationSearchParam = (key: string, def?: string): string => {
    const defaultValue = def || '';
    return new URLSearchParams(location.search).get(key) || defaultValue;
};

export const hasLocationSearchParam = (key: string): boolean => {
    return new URLSearchParams(location.search).has(key);
};

export const isLocationPath = (path: string): boolean => {
    return location.pathname === path;
};

export const hasLocationPath = (path: string): boolean => {
    return location.pathname.includes(path);
};

export const redirect = (url: string) => {
    location.assign(url);
};

export const scrollTo = (id: string) => {
    const element = document.getElementById(id);
    const header = document.getElementsByTagName('header')[0];
    const _top = element ? element.offsetTop - (header ? header.offsetHeight : 0) : 0;
    setTimeout(() => {
        window.scrollTo({
            behavior: 'smooth',
            top: _top,
        });
    }, 100);
};

export const getCurrentHost = () => `${window?.location.protocol}//${window?.location.host}`;
