import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { scrollTo } from '../../utils/system';

class RouterWrapper extends React.Component<RouteComponentProps> {
    componentDidMount = () => this.handleScroll();

    componentDidUpdate(prevProps: Readonly<RouteComponentProps>): void {
        const {
            location: { pathname, hash },
        } = this.props;
        if (pathname !== prevProps.location.pathname || hash !== prevProps.location.hash) {
            this.handleScroll();
        }
    }

    handleScroll = () => {
        const {
            location: { hash },
        } = this.props;
        const id = hash.replace('#', '');
        scrollTo(id);
    };

    render() {
        return this.props.children;
    }
}

export default withRouter(RouterWrapper);
