import { getEnvVar } from '../../../utils/env';

export const SUPPORTED_CARD_TYPES = ['AMEX', 'MASTERCARD', 'VISA'];

export const googleIsReadyToPayRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
        {
            type: 'CARD',
            parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: SUPPORTED_CARD_TYPES,
            },
            tokenizationSpecification: {
                type: 'PAYMENT_GATEWAY',
                parameters: {
                    gateway: 'adyen',
                    gatewayMerchantId: getEnvVar('GOOGLE_PAY_GATEWAY_MERCHANT_ID'),
                },
            },
        },
    ],
};
