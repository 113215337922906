import React from 'react';
import { Link } from 'react-router-dom';
import { PATHS } from 'state/config';
import {
    icCheck,
    ImgBenefits,
    Lp02Program01,
    Lp02Program02,
    Lp02Program03,
    LPFullBodyHeader,
    LPFullBodyHeaderMobile,
    LPFullBodyLogo,
    LPPricing2,
    LPTrainer2,
} from 'assets';

import { cutAtChar } from 'utils/string';
import { getGanzkoerperProgram } from 'state/helpers/program';
import VideoPreview from './VideoPreview';
import useProgramPage from './useProgramPage';
import ReimbursementRateCalculator from 'components/common/ReimbursementRateCalculator';
import ReimbursementInfo from 'components/common/ReimbursementInfo';

const GanzkoerperPartner: React.FC = () => {
    const program_id = getGanzkoerperProgram().ID;

    const { handleVideoPreview, isFreebieUser, JsonReader, showVideo } = useProgramPage(program_id);

    return (
        <>
            <div className="container-fluid no-padding lp-hero first-container bg-white">
                <div className="pos-absolute">
                    <div className="wrapper">
                        <div className="row">
                            <div className="image-module col">
                                <img
                                    className="lp-header-image"
                                    src={LPFullBodyLogo}
                                    alt="Funktionelle Ganzkörperkräftigung"
                                />
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: JsonReader.program__get('heroPane.description'),
                                    }}
                                />
                                <div className="cta-wrapper">
                                    <Link to={PATHS.REGISTRATION} className="btn-gym">
                                        {JsonReader.program__get('heroPane.ctaMain')}
                                    </Link>
                                    <div className="btn-gym btn--withicon" onClick={handleVideoPreview}>
                                        <svg x="0px" y="0px" viewBox="0 0 12 16">
                                            <polygon points="12,8 0,0 0,16 " />
                                        </svg>
                                        {JsonReader.program__get('heroPane.ctaPreview')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="image__background">
                    <img className="lp-header-image" src={LPFullBodyHeader} alt="Kopfzeile" />
                    <img className="lp-header-image mobile" src={LPFullBodyHeaderMobile} alt="Mobile Kopfzeile" />
                </div>
            </div>
            <div className="bg-white">
                <div className="wrapper lp-programs">
                    <div className="row margin">
                        {JsonReader.program__getUsp().map((_elem, _index) => (
                            <div key={_index} className="col-xs-12 col-sm-4 col-md-4 box-program">
                                <img
                                    className="image"
                                    src={_index === 0 ? Lp02Program01 : _index === 1 ? Lp02Program02 : Lp02Program03}
                                    alt="USP"
                                />
                                <h6>{_elem.title}</h6>
                                <p dangerouslySetInnerHTML={{ __html: _elem.description }} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="bg-white lp-price-padding">
                <div className="wrapper homepage-benefits lp-price bg-beige">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            {!isFreebieUser && (
                                <div className="badge-price">
                                    <div>
                                        <span className="price">
                                            {cutAtChar(JsonReader.program__get('pricePane.price.amount'))}
                                            {JsonReader.program__get('pricePane.price.currency.symbol')}
                                        </span>
                                        <span className="small">
                                            {JsonReader.program__get('pricePane.price.taxes')}
                                        </span>
                                    </div>
                                </div>
                            )}
                            <img className="image-benefits" src={LPPricing2} alt="Deine Vorteile" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 padding-left">
                            <h4>{JsonReader.program__get('pricePane.title')}</h4>
                            {JsonReader.program__getPriceListItems().map((_elem, _index) => (
                                <div key={_index} className="entry">
                                    <img className="image" src={icCheck} alt="Vorteil" />
                                    <p>{_elem}</p>
                                </div>
                            ))}
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.program__get('pricePane.cta')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-white">
                <div className="wrapper homepage-programs lp-programs">
                    <ReimbursementInfo JsonReader={JsonReader} isFreebieUser={isFreebieUser} />
                </div>
            </div>
            <div className="bg-white">
                <ReimbursementRateCalculator JsonReader={JsonReader} isFreebieUser={isFreebieUser} />
                <div className="wrapper lp-coach">
                    <div className="row">
                        <div className="col-xs-12 col-sm-7 col-md-6 padding-left">
                            <h4>{JsonReader.program__get('trainerPane.title')}</h4>
                            {JsonReader.program__getTrainerContent().map((_elem, _index) => (
                                <p key={_index}>
                                    {_elem.title && <strong>{_elem.title}</strong>}
                                    {_elem.title && <br />}
                                    {_elem.description}
                                </p>
                            ))}
                            <div className="btn-gym btn--withicon" onClick={handleVideoPreview}>
                                <svg x="0px" y="0px" viewBox="0 0 12 16">
                                    <polygon points="12,8 0,0 0,16 " />
                                </svg>
                                {JsonReader.program__get('trainerPane.ctaPreview')}
                            </div>
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.program__get('trainerPane.ctaMain')}
                            </Link>
                        </div>
                        <div className="col-xs-12 col-sm-5 col-md-6 padding-left">
                            <img className="image" src={LPTrainer2} alt="Denise Harlass" />
                        </div>
                    </div>
                </div>
                <div className="wrapper homepage-benefits lp-benefits">
                    <div className="row">
                        <div className="col-xs-12 col-sm-6 col-md-6">
                            <img className="image-benefits" src={ImgBenefits} alt="Vorteile" />
                        </div>
                        <div className="col-xs-12 col-sm-6 col-md-6 last">
                            <h4>{JsonReader.get('summaryPane.title')}</h4>
                            {JsonReader.getSummaryItems().map((_elem, _index) => (
                                <div key={_index} className="entry">
                                    <img className="image" src={icCheck} alt="Vorteil" />
                                    <p>{_elem}</p>
                                </div>
                            ))}
                            <Link to={PATHS.REGISTRATION} className="btn-gym">
                                {JsonReader.get('summaryPane.cta')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            {showVideo && (
                <div className="overlay-video-player">
                    <div className="overlay-video-player__container">
                        <VideoPreview url={JsonReader.program__get('heroPane.videoURL')} />
                        <div className="overlay-video-player__container__close" onClick={handleVideoPreview}>
                            &times;
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
export default GanzkoerperPartner;
