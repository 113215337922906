import React, { useCallback, useRef, useState } from 'react';
import { Icon, InputLabel, Modal } from '@gymondo/frontend-core/components';
import { ADYEN_CONFIG, CardTypeProps, PAYMENT_METHODS } from '../../../state/configPayment';
import usePaymentMethod from '../use-payment-method';
import PaymentTemplate, { DEFAULT_STYLES, genericCTAButton } from '../PaymentTemplate';
import text from '../../../lang';
import { CardBack, CardFront } from '../../../assets/payment';
import SuccessIcon from '../SuccessIcon';
import { ReactComponent as questionMarkIcon } from '@gymondo/frontend-core/svg/bg-question.svg';

const renderModal = (handleModal: () => void) => (
    <Modal onClose={handleModal} dialogClassName="modal-dialog" header={text('subscribe_card_cvc_title')} hideFooter>
        {text('subscribe_card_cvc_description')}
        <div className="card-images">
            <Icon data={CardBack} className="card-image" />
            <Icon data={CardFront} className="card-image" />
        </div>
        <br />
    </Modal>
);

const METHOD = PAYMENT_METHODS.CARD;

interface CardProps {
    types: CardTypeProps[];
    paymentMethod: string;
}

const Card: React.FC<CardProps> = ({ types, paymentMethod }) => {
    const cardRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [holderName, setHolderName] = useState('');

    const handleModal = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal]);

    const handleHolder = useCallback((event) => {
        setHolderName(event.target.value);
    }, []);

    const config = {
        hasHolderName: true,
        holderNameRequired: true,
        type: 'card',
        brands: types.map((type) => type.name),
        styles: DEFAULT_STYLES,
    };

    const additionalConfig = {
        translations: {
            [ADYEN_CONFIG.locale]: {
                'creditCard.expiryDateField.placeholder': text('subscribe_card_cvc_expiry_date_field_placeholder'),
            },
        },
    };

    const { submitData, isLoading, isValid, errorMessage, fieldsStatus } = usePaymentMethod({
        paymentMethod,
        config,
        ref: cardRef,
        additionalConfig,
    });

    const isHolderNameValid = () => {
        const MIN_NAME_LENGHT = 2;
        return holderName?.trim().length > MIN_NAME_LENGHT;
    };

    return (
        <div ref={cardRef} className="payment-method-card">
            {showModal && renderModal(handleModal)}
            <PaymentTemplate
                errorMessage={errorMessage}
                paymentMethod={METHOD}
                button={genericCTAButton(!!(isValid && isHolderNameValid()), isLoading, METHOD, async () => {
                    await submitData({
                        holderName,
                    });
                })}
            >
                <div className="cards">
                    {types.map((creditCard) => (
                        <Icon key={creditCard.name} data={creditCard.logo} className="card-icon" />
                    ))}
                </div>
                <div>
                    <InputLabel htmlFor="cc-number">{text('subscribe_card_card_number')}</InputLabel>
                    <SuccessIcon isValid={Boolean(fieldsStatus.encryptedCardNumber)}>
                        <span
                            id="cc-number"
                            data-cse="encryptedCardNumber"
                            className={`input input--primary textfield${
                                fieldsStatus.encryptedCardNumber === false ? ' error-field' : ''
                            }`}
                        />
                    </SuccessIcon>
                </div>
                <div>
                    <InputLabel htmlFor="holderName">{text('subscribe_card_holder_name')}</InputLabel>
                    <SuccessIcon isValid={isHolderNameValid()}>
                        <input
                            autoComplete="cc-name"
                            className="input input--primary textfield"
                            id="holderName"
                            name="holderName"
                            placeholder={text('subscribe_card_holder_name_placeholder')}
                            onChange={handleHolder}
                        />
                    </SuccessIcon>
                </div>
                <div className="row">
                    <div className="col-sm-4 col-xs-6">
                        <InputLabel htmlFor="expiryDate">{text('subscribe_card_expiry_date')}</InputLabel>
                        <SuccessIcon isValid={Boolean(fieldsStatus.encryptedExpiryDate)}>
                            <span
                                id="expiryDate"
                                data-cse="encryptedExpiryDate"
                                className={`input input--primary textfield${
                                    fieldsStatus.encryptedExpiryDate === false ? ' error-field' : ''
                                }`}
                            />
                        </SuccessIcon>
                    </div>
                    <div className="col-sm-4 col-xs-6">
                        <InputLabel htmlFor="cc-csc" className="question-line">
                            {text('subscribe_card_security_code')}
                            <button
                                type="button"
                                onClick={handleModal}
                                className="question-cvc"
                                title={text('subscribe_card_cvc_title')}
                            >
                                <Icon data={questionMarkIcon} className="question-icon" />
                            </button>
                        </InputLabel>
                        <SuccessIcon isValid={Boolean(fieldsStatus.encryptedSecurityCode)}>
                            <span
                                id="cc-csc"
                                data-cse="encryptedSecurityCode"
                                className={`input input--primary textfield${
                                    fieldsStatus.encryptedSecurityCode === false ? ' error-field' : ''
                                }`}
                            />
                        </SuccessIcon>
                    </div>
                </div>
            </PaymentTemplate>
        </div>
    );
};

export default Card;
