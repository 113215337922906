import { ArrowDown } from 'assets';
import React, { useState } from 'react';
import './FAQ.scss';

export type QA = {
    question: string;
    answer: string;
};

const FAQItem = ({ question, answer }: QA) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleQuestion = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        setIsOpen(!isOpen);
    };

    return (
        <div className={`faq ${!isOpen ? 'closed' : 'open'}`} onClick={toggleQuestion}>
            <div className="question">
                <h6>{question}</h6>
                <img src={ArrowDown} className="arrow" />
            </div>
            <div className="answer" dangerouslySetInnerHTML={{ __html: answer }}></div>
        </div>
    );
};

const FAQ: React.FC<{ items: QA[] }> = ({ items }) => {
    return (
        <div className="faqs">
            {items.map((item, index) => {
                return <FAQItem key={index} {...item} />;
            })}
        </div>
    );
};

export default FAQ;
