import React, { useCallback, useState } from 'react';

interface SwipeProps {
    className?: string;
}

const Swipe: React.FC<SwipeProps> = ({ className, children }) => {
    const first = 0;
    const last = React.Children.count(children) - 1;
    const [active, setActive] = useState(first);
    const handleSwipe = useCallback(
        (add: number) => {
            const newActive = active + add;
            if (newActive >= first && newActive <= last) {
                setActive(newActive);
            }
        },
        [active],
    );
    return (
        <div className={className}>
            <div className={`arrow-bold-left${active === first ? ' inactive' : ''}`} onClick={() => handleSwipe(-1)}>
                <svg viewBox="0 0 500 500">
                    <path d="M135.1 253.8L345.9 16.1l44.9 39.8-175.3 197.6L390.7 449 346 489z" />
                </svg>
            </div>
            {React.Children.map(children, (child, index) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        className: `${child.props.className}${index === active ? ' slide-show' : ' slide-hide'}`,
                    });
                }
                return child;
            })}
            <div className={`arrow-bold${active === last ? ' inactive' : ''}`} onClick={() => handleSwipe(1)}>
                <svg viewBox="0 0 500 500">
                    <path d="M179.9 489l-44.7-40 175.2-195.5L135.1 55.9 180 16.1l210.8 237.7z" />
                </svg>
            </div>
        </div>
    );
};

export default Swipe;
