import React from 'react';

const Terms: React.FC = () => {
    return (
        <>
            <div className="container_ wrapper legal first-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3 html">
                        <h1>Gymondo Allgemeine Geschäftsbedingungen für Online-Präventionskurse („AGB“)</h1>
                        <p className="gym-p">
                            Willkommen bei den Online-Präventionsprogrammen (nachfolgend zusammen
                            <strong>„Gymondo PRÄVENTION“</strong> genannt), welche von der Gymondo GmbH, mit
                            Geschäftssitz Ritterstraße 12-14, 10969 Berlin, Deutschland (nachfolgend: „Gymondo”)
                            betrieben werden. Die jeweils aktuell von Gymondo angebotenen Präventionsprogramme sind auch
                            der Webseite von Gymondo ersichtlich.
                        </p>
                        <p className="gym-p">
                            Zusätzliche Kontaktdaten und Angaben zur gewerblichen Eintragung sowie zu den Vertretern der
                            Gymondo GmbH finden Sie im Bereich Impressum. Der Begriff <strong>„Nutzer”</strong> bezieht
                            sich auf solche Besucher von Online-Präventionsprogrammen von Gymondo, die sich hierfür nach
                            Maßgabe dieser AGB registriert haben.
                        </p>
                        <h6>1. Leistungen von Gymondo / Registrierung</h6>
                        <p className="gym-p">
                            (1) Unter Gymondo PRÄVENTION bietet Gymondo seinen Nutzern verschiedene Online-Programme für
                            modernes Gesundheitspräventionstraining an. Über insgesamt 10 Wochen wird der Nutzer jede
                            Woche für eine neue Trainingseinheit freigeschaltet. Die wöchentlichen Trainings bestehen
                            jeweils aus einer theoretischen Lern- und einer praktischen Übungseinheit. Mit
                            Trainingsvideos und informativer Textlektüre wird Wissenswertes rund um die verschiedenen
                            Trainings- und Präventionszwecke vermittelt.
                        </p>
                        <p className="gym-p">
                            (2) Die Gymondo-Webseiten werden fortlaufend aktualisiert. Es besteht kein Anspruch auf
                            Aufrechterhaltung einzelner Funktionalitäten von Gymondo oder auf Aufrechterhaltung der
                            Gymondo-Webseiten an sich und der Gymondo übernimmt keine Verantwortung oder Haftung, falls
                            Gymondo (z.B. aus technischen Gründen) zeitweise nicht aufrufbar sein sollte. Gymondo ist
                            jederzeit berechtigt, einzelne Dienstleistungen und Funktionalitäten von Gymondo PRÄVENTION
                            mit oder ohne Angabe von Gründen zu ändern, zu entfernen und neue Dienste und
                            Funktionalitäten verfügbar zu machen oder den Gymondo Dienst insgesamt einzustellen. Hierbei
                            werden die berechtigten Interessen der Nutzer berücksichtigt und wesentliche Änderungen
                            vorab in geeigneter Weise angekündigt.
                        </p>
                        <p className="gym-p">
                            (3) Die Inanspruchnahme der Angebote und Dienstleistungen von Gymondo für das ausgewählte
                            Online-Präventionsprogramm setzt eine Registrierung und die Erstellung eines Nutzerkontos
                            auf <a href="www.gymondo-praevention.de">www.gymondo-praevention.de</a> voraus. Im Rahmen
                            dieser Registrierung sind vom Nutzer eine gültige E-Mail-Adresse, Vor- und Nachname sowie
                            Geburtsdatum anzugeben und ein Passwort festzulegen. Im Folgenden sind dann die Zahldaten
                            (bspw. Paypal-Konto, Kreditkartendaten oder Bankdaten) anzugeben. Über die notwendigen
                            Pflichtangaben hinaus kann ein Nutzer weitere persönliche Informationen übermitteln, die für
                            eine Anmeldung nicht zwingend erforderlich sind. Dem Nutzer steht es hierbei jederzeit frei,
                            persönliche Informationen zu ändern. Für die Nutzung des Angebotes wird ein aktueller
                            Internetbrowser und Internetzugang benötigt (ein Breitbandanschluss ermöglicht eine optimale
                            Nutzbarkeit von Gymondo PRÄVENTION). Hierfür können dem Nutzer zusätzliche Kosten entstehen.
                        </p>
                        <p className="gym-p">
                            (4) Der Nutzer ist vollständig alleinverantwortlich für die Geheimhaltung der von ihm im
                            Registrierungsprozess angegebenen Passwörter und verpflichtet sich, den eigenen
                            Benutzernamen sowie die dazugehörigen Passwörter nicht an Dritte weiterzugeben.
                        </p>
                        <p className="gym-p">
                            (5) Der Nutzer versichert, dass alle von ihm angegebenen persönlichen Daten der Wahrheit
                            entsprechen und Daten der Person des Nutzers sind. Der Nutzer verpflichtet sich, keine
                            Accounts anderer Nutzer zu verwenden und sein Passwort keinen unberechtigten Dritten
                            mitzuteilen. Der Nutzer wird Gymondo unverzüglich informieren, falls er den Verdacht einer
                            unbefugten Nutzung seines Accounts oder Passwortes hat, sowie wenn sich an den angegebenen
                            persönlichen Daten oder Zahlungsdaten etwas ändert.
                        </p>
                        <p className="gym-p">
                            (6) Die Anmeldung bei Gymondo ist volljährigen Nutzern vorbehalten. Der Gymondo ist
                            jederzeit berechtigt, die Identität von Nutzern durch Vorlage offizieller Dokumente zu
                            überprüfen und behält sich vor, rechtliche Schritte gegen diejenigen Nutzer einzuleiten, die
                            falsche Angaben über persönliche Informationen oder ihre Identität machen. Ebenso können
                            Nutzerkonten mit ungenauen oder unvollständigen Angaben oder aus sonstigen Gründen entfernt
                            werden, sofern dies nach dem Ermessen von Gymondo erforderlich ist.
                        </p>
                        <p className="gym-p">
                            (7) Mit der Anmeldung bei Gymondo versichert der Nutzer, die angegebenen Zahldaten
                            rechtmäßig zu verwenden. Zur beiderseitigen Sicherheit von Nutzer und Gymondo ist der
                            Gymondo zur Speicherung der IP-Adresse des Internetzugangs berechtigt, von dem die Anmeldung
                            erfolgt ist.
                        </p>
                        <h6>2. Vertragsschluss / Vertragsdauer</h6>
                        <p className="gym-p">
                            Der potentielle Nutzer gibt mit dem Klicken auf die Schaltfläche „Zahlungspflichtig
                            bestellen” ein Angebot zum Kauf des ausgewählten Online-Programms Gymondo PRÄVENTION ab.
                            Dieses Angebot kann Gymondo entweder mit der Gewährung des Zuganges zum Nutzerbereich des
                            Online-Programms oder dem Versand einer E-Mail mit der Bestätigung des Kaufs annehmen. Der
                            Vertragsschluss kann ausschließlich in deutscher Sprache erfolgen. Ab dem Tag des
                            Programmstarts hat der Nutzer maximal 12 Wochen lang Zugang zu den wöchentlichen Trainings.
                            Nach Ablauf der 12 Wochen endet die Nutzungsmöglichkeit automatisch, ohne dass es einer
                            Kündigung bedarf.
                        </p>
                        <h6>3. Preise</h6>
                        <p className="gym-p">
                            (1) Es gelten die zum Zeitpunkt des Vertragsschlusses jeweils gültigen und gegenüber dem
                            Nutzer über <a href="www.gymondo-praevention.de">www.gymondo-praevention.de</a> nebst
                            Unterseiten bekannt gemachten Preise.
                        </p>
                        <p className="gym-p">
                            (2) Der Preis für das ausgewählte Gymondo PRÄVENTION Programm ist verbindlich und wird
                            unmittelbar nach Vertragsschluss fällig und zu diesem Zeitpunkt auch abgebucht. Der Gymondo
                            ist berechtigt, Rechnungen und Mahnungen per E-Mail an Nutzer zu übersenden.
                        </p>
                        <p className="gym-p">
                            (3) Die Zahlung kann durch die verschiedenen auf der Webseite von Gymondo angebotenen
                            Zahlungsmethoden erfolgen. Gymondo behält sich vor, zur Absicherung des Kreditrisikos
                            bestimmte Zahlarten auszuschließen. Im Fall einer vom Kunden verursachten Rücklastschrift
                            (z.B. bei nicht ausreichender Deckung des Kontos oder einer Rückbelastung durch den Nutzer)
                            ist Gymondo berechtigt, die dadurch zusätzlich anfallenden Kosten (z.B. Bank- und
                            Bearbeitungsgebühren) in Rechnung zu stellen. Dem Nutzer bleibt der Nachweis gestattet, dass
                            keine bzw. geringere Kosten entstanden sind.
                        </p>
                        <p className="gym-p">
                            (4) Der Zugang zu Gymondo kann gesperrt werden, wenn sich die angegebenen
                            Zahlungsinformationen des Nutzers als falsch oder unzulässig herausstellen. Das Recht von
                            Gymondo zur Geltendmachung weitergehender Schadensersatzansprüche und der Rücktritt vom
                            Vertrag bei fortdauerndem Zahlungsverzug bleiben unberührt.
                        </p>
                        <p className="gym-p">
                            (5) Sofern nicht anders angegeben sind alle Gebühren in Euro und inklusive MwSt. Für Nutzer
                            mit Wohnsitz in Drittstaaten (d.h. Staaten außerhalb der Europäischen Union gemäß dem
                            deutschen Umsatzsteuergesetz) sind die angegebenen Beträge Nettopreise.
                        </p>
                        <h6>4. Widerrufsrecht</h6>
                        <p className="gym-p">
                            Sofern sich Nutzer bei Gymondo PRÄVENTION zu einem Zweck registrieren, der weder ihrer
                            gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit zugerechnet werden kann,
                            gelten für jene Nutzer als Verbraucher im Sinne von § 13 BGB die folgenden Bestimmungen:
                        </p>
                        <h6>- BEGINN DER WIDERRUFSBELEHRUNG -</h6>
                        <h6>(1) Widerrufsbelehrung</h6>
                        <p className="gym-p">
                            <strong>
                                Der Nutzer hat das Recht, binnen 14 Tagen ohne Angabe von Gründen diesen Vertrag zu
                                widerrufen. Die Widerrufsfrist beträgt 14 Tage ab dem Tag des Vertragsschlusses.
                            </strong>
                        </p>
                        <p className="gym-p">
                            <strong>
                                Um sein Widerrufsrecht auszuüben, muss der Nutzer Gymondo (Gymondo GmbH, Ritterstraße
                                12-14, 10969 Berlin) mittels einer eindeutigen Erklärung (z. B. ein mit der Post
                                versandter Brief oder E-Mail) über seinen Entschluss, diesen Vertrag zu widerrufen,
                                informieren. Der Nutzer kann dafür das beigefügte Muster- Widerrufsformular verwenden,
                                das jedoch nicht vorgeschrieben ist. Zur Wahrung der Widerrufsfrist reicht es aus, dass
                                der Nutzer die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der
                                Widerrufsfrist absendet.
                            </strong>
                        </p>
                        <h6>(2) Folgen des Widerrufs</h6>
                        <p className="gym-p">
                            <strong>
                                Wenn der Nutzer diesen Vertrag widerruft, hat Gymondo ihm alle Zahlungen, die Gymondo
                                vom Nutzer erhalten hat, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen
                                Kosten, die sich daraus ergeben, dass der Nutzer eine andere Art der Lieferung als die
                                von Gymondo angebotene, günstigste Standardlieferung gewählt hat), unverzüglich und
                                spätestens binnen 14 Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über dem
                                Widerruf dieses Vertrags bei Gymondo eingegangen ist. Für diese Rückzahlung verwendet
                                Gymondo dasselbe Zahlungsmittel, das der Nutzer bei der ursprünglichen Transaktion
                                eingesetzt hat, es sei denn, mit dem Nutzer wurde ausdrücklich etwas anderes vereinbart;
                                in keinem Fall werden dem Nutzer wegen dieser Rückzahlung Entgelte berechnet.
                            </strong>
                        </p>
                        <p className="gym-p">
                            <strong>
                                Hat der Nutzer verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen
                                sollen, so hat der Nutzer Gymondo einen angemessenen Betrag zu zahlen, der dem Anteil
                                der bis zu dem Zeitpunkt, zu dem der Nutzer Gymondo von der Ausübung des Widerrufsrechts
                                hinsichtlich dieses Vertrags unterrichtet, bereits Dienstleistungen im Vergleich zum
                                Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht.
                            </strong>
                        </p>
                        <h6>- ENDE DER WIDERRUFSBELEHRUNG -</h6>
                        <h6>(3) Widerrufsformular des Kunden</h6>
                        <p className="gym-p">
                            Wenn der Kunde den Vertrag widerrufen will, soll er bitte dieses Formular ausfüllen und es
                            zurücksenden:
                            <br />
                            <br />
                            An Gymondo GmbH, Ritterstraße 12-14, 10969 Berlin,{' '}
                            <a href="mailto:service@gymondo.de">service@gymondo.de</a>
                            <br />
                            <br />
                            Hiermit widerrufe (n) ich/wir (*) den von mir/uns (*) abgeschlossenen Vertrag über den Kauf
                            der folgenden Waren (*) / Dienstleistungen (*)
                            <br />
                            Bestellt am (*) / erhalten am (*)
                            <br />
                            Name des/der Verbraucher(s)
                            <br />
                            Anschrift des/der Verbraucher(s)
                            <br />
                            Unterschrift des/ der Verbraucher(s) (nur bei Mitteilung auf Papier)
                            <br />
                            <br />
                            Datum
                            <br />
                            ____________
                            <br />
                            (*) unzutreffendes streichen
                        </p>
                        <h6>5. Teilnahmevoraussetzungen</h6>
                        <p className="gym-p">
                            Es wird ausdrücklich darauf hingewiesen, dass nicht alle in den Online Präventionsprogrammen
                            angebotenen Übungen und Angebote für jeden geeignet sind und die Nutzung des jeweils
                            ausgewählten Online Präventionsprogrammes auf eigene Gefahr erfolgt. Vor der Aufnahme
                            körperlicher Aktivitäten sollte im Zweifel ein Arzt konsultiert werden. Die Übungen ersetzen
                            keine ärztliche Untersuchung oder Behandlung. Mögliche Einschränkungen aufgrund chronischer
                            oder akuter Krankheiten oder für Frauen in und nach der Schwangerschaft sind unbedingt zu
                            beachten. Kontraindikationen bzw. Ausschlusskriterien: Behandlungsbedürftige Erkrankungen
                            des Bewegungsapparates.
                        </p>
                        <h6>6. Inhalt / Urheberrechte</h6>
                        <p className="gym-p">
                            (1) Gymondo und alle Gymondo-Webseiten sind urheberrechtlich geschützt - © 2014 Gymondo
                            GmbH. Alle Rechte bleiben vorbehalten.
                        </p>
                        <p className="gym-p">
                            (2) Die auf den Gymondo-Webseiten bereitgestellten Inhalte wie z.B. Informationen, Texte,
                            Dateien, Bilder, Fotos, Videos, Filme, Töne und Musik sowie sonstiges Material jedweder Art
                            und Form, können sowohl Inhalte von Gymondo sein als auch Inhalte, die Dritten gehören und
                            die diese an Gymondo lizenziert haben (nachfolgend zusammenfassend:
                            <strong> „Inhalte”</strong>).
                        </p>
                        <p className="gym-p">
                            (3) Nutzern ist es nicht gestattet, Urheberrechte, Markenrechte, Warenzeichen oder sonstige
                            Kennzeichenrechte, die in die Gymondo-Webseiten oder damit verbundenen Produkten oder
                            Dienstleistungen eingebunden sind bzw. damit einhergehen, zu ändern, zu entfernen, zu
                            unterdrücken oder auf sonstige Weise zu beeinträchtigen. Nutzern ist ohne vorherige
                            schriftliche Zustimmung von Gymondo nicht gestattet, Inhalte von Gymondo zu vervielfältigen,
                            zu verändern, zu bearbeiten, zu veröffentlichen, zu verkaufen, zu lizenzieren, oder auf
                            sonstige Weise kommerziell auszuwerten.
                        </p>
                        <p className="gym-p">
                            (4) Sämtliche Rechte und Ansprüche, einschließlich sämtlicher weltweiter Rechte am geistigen
                            Eigentum an den Inhalten der Gymondo-Webseiten, einschließlich Markenrechte, Warenzeichen
                            und Logos, die in den Gymondo-Webseiten enthalten sind, sofern es sich hierbei nicht um von
                            Nutzern übermittelte oder Inhalte von Dritten handelt, stehen allein Gymondo zu. Sofern an
                            anderer Stelle nicht ausdrücklich anderes geregelt, ist jede Form der Verbreitung und/oder
                            Vervielfältigung von Inhalten der Gymondo-Webseiten oder Teilen hiervon ohne vorherige
                            schriftliche Zustimmung von Gymondo ausdrücklich untersagt.
                        </p>
                        <h6>7. Verhaltenspflichten der Nutzer</h6>
                        <p className="gym-p">
                            (1) Jeder Nutzer ist verpflichtet, Handlungen zu unterlassen, die geeignet sind, den Betrieb
                            von Gymondo oder der dahinterstehenden technischen Infrastruktur zu beeinträchtigen und/oder
                            übermäßig zu belasten oder geschützte Inhalte von Gymondo widerrechtlich zu nutzen. Hierzu
                            zählen insbesondere:
                        </p>
                        <ul className="custom-list">
                            <li>
                                die Verwendung von Software, Skripten, Viren oder anderen Computercodes, Daten oder
                                Programmen, die darauf abzielen, Gymondo und die Funktionalität einer Software oder
                                Hardware oder andere Ausstattungen der Gymondo-Webseiten zu unterbrechen, zu zerstören
                                und/oder zu einzuschränken;
                            </li>
                            <li>
                                das Blockieren, Überschreiben, Modifizieren, Kopieren von Daten und/oder sonstigen
                                Inhalten, oder den Datenfluss auf Gymondo zu stören oder zu unterbrechen oder auf
                                sonstige Weise die Nutzung der Gymondo-Webseiten zu beeinträchtigen, soweit dies nicht
                                für die vertragsgemäße Nutzung von Gymondo erforderlich ist;
                            </li>
                            <li>
                                die Vervielfältigung und/oder Verbreitung und/oder öffentliche Wiedergabe von auf
                                Gymondo verfügbaren durch das Urheberrecht, das Markenrecht oder durch sonstige
                                gesetzliche Vorschriften des geistigen Eigentums geschützten Inhalten, soweit dies den
                                Nutzern nicht ausdrücklich gestattet oder als erlaubte Nutzung vorgesehen ist.
                            </li>
                        </ul>
                        <p className="gym-p">
                            (2) Gymondo und die Gymondo-Dienstleistungen und -angebote sind grundsätzlich zum
                            persönlichen Gebrauch der Nutzer bestimmt. Eine Verwendung für gewerbliche Zwecke, die nicht
                            ausdrücklich auf Gymondo oder in diesen AGB benannt und vorgesehen sind, ist ausdrücklich
                            nicht gestattet.
                        </p>
                        <p className="gym-p">
                            (3) Der Nutzer ist verpflichtet, bei der Bereitstellung von eigenen Inhalten alle
                            anwendbaren Gesetze und sonstigen Rechtsvorschriften der Bundesrepublik Deutschland zu
                            beachten. Unabhängig davon, ob hierdurch ein Straftatbestand verwirklicht wird, ist es
                            generell untersagt, Inhalte bereit zu stellen, die pornografischer, sexueller,
                            gewalttätiger, rassistischer, volksverhetzender, diskriminierender, nicht jugendfreier,
                            beleidigender und/oder verleumderischer Natur sind.
                        </p>
                        <h6>8. Haftung und Haftungsbeschränkung von Gymondo</h6>
                        <p className="gym-p">
                            (1) Gymondo übernimmt keine Gewähr oder Garantie jedweder Art, dass das vom Nutzer
                            gewünschte Trainings- oder sonstige Ergebnis durch Nutzung der Leistungen von Gymondo
                            erzielt wird. Einen konkreten Erfolg schuldet Gymondo nicht. Gymondo übernimmt ferner keine
                            Haftung für Verluste oder Schäden (insbesondere nicht für Folgeschäden oder sonstige
                            Verluste und Schäden) aufgrund von Datenverlusten beim Nutzer.
                        </p>
                        <p className="gym-p">
                            (2) Gymondo übernimmt keine inhaltliche Gewähr für die über die Gymondo-Webseiten abrufbaren
                            Inhalte, soweit diese vom Nutzer oder von sonstigen Dritten hochgeladen wurden,
                            insbesondere, weil eine Vorabkontrolle der Inhalte nicht erfolgt und von Gymondo naturgemäß
                            nicht geleistet werden kann. Gymondo haftet weder für deren Richtigkeit, Aktualität bzw.
                            Vollständigkeit, noch dafür, dass sie frei von Rechten Dritter sind und/oder der Nutzer
                            diese rechtmäßig erworben hat.
                        </p>
                        <p className="gym-p">
                            3) Gymondo übernimmt ferner keine Verantwortung für Werbung von Dritten auf den Gymondo-
                            Webseiten oder im Zusammenhang mit Leistungen von Gymondo sowie auch keine Verantwortung für
                            Angebote, die in derartiger Werbung enthalten sind.
                        </p>
                        <p className="gym-p">
                            (4) Gymondo haftet im Falle entgeltlich erbrachter Leistungen unbeschränkt für Vorsatz und
                            grobe Fahrlässigkeit sowie wegen schuldhafter Verletzung von Körper, Leben und/oder
                            Gesundheit, bei Verletzung einer ausdrücklich als „Garantie“ zu bezeichnenden Garantie, und
                            im Falle zwingender gesetzlicher Haftung nach dem Produkthaftungsgesetz.
                        </p>
                        <p className="gym-p">
                            (5) In anderen als den in Ziffer (4) beschriebenen Fällen ist die Haftung von Gymondo im
                            Fall leichter Fahrlässigkeit auf die Verletzung wesentlicher Vertragspflichten beschränkt.
                            Wesentliche Vertragspflichten sind solche, deren Erfüllung die Durchführung des Vertrages
                            erst ermöglichen und auf deren Erfüllung der Nutzer sich deswegen regelmäßig verlassen darf.
                        </p>
                        <p className="gym-p">
                            (6) Die Haftung von Gymondo für die leicht fahrlässige Verletzung wesentlicher
                            Vertragspflichten ist auf den nach der Art der Dienstleistung vorhersehbaren,
                            vertragstypischen Schaden beschränkt. Dies gilt auch bei leicht fahrlässigen
                            Pflichtverletzungen der gesetzlichen Vertreter oder Erfüllungsgehilfen von Gymondo.
                        </p>
                        <p className="gym-p">(7) Im Übrigen ist die Haftung von Gymondo ausgeschlossen.</p>
                        <p className="gym-p">
                            (8) Eine Änderung der Beweislast zum Nachteil des Nutzers ist mit den vorstehenden
                            Regelungen gem. der Absätze (4) bis (7) nicht verbunden.
                        </p>
                        <h6>9. Haftung des Nutzers</h6>
                        <p className="gym-p">
                            (1) Der Kunde stellt Gymondo von sämtlichen Ansprüchen frei, die andere Nutzer oder sonstige
                            Dritte gegenüber Gymondo wegen Verletzung ihrer Rechte aufgrund vom Nutzer eingestellter
                            Inhalte oder allgemein aufgrund der Nutzung der Gymondo-Plattform durch den Nutzer geltend
                            machen, es sei denn der Nutzer hat die Rechtsverletzung nicht zu vertreten. Diese
                            Freistellung gilt insbesondere für die Verletzung urheber-, marken-, kennzeichen-, patent-,
                            namensrechtlicher und/oder sonstiger Schutzrechte.
                        </p>
                        <p className="gym-p">
                            (2) Die Freistellungspflicht gemäß vorstehendem Absatz (1) gilt ferner in den Fällen, in
                            denen der Nutzer seine in Ziffer 8 dieser AGB geregelten Pflichten verletzt. Die vorstehend
                            geregelte Freistellungspflicht gilt auch für eine Inanspruchnahme von Gymondo durch
                            Behörden, Rechtsverfolgungsbehörden oder sonstige öffentliche Stellen aufgrund von Inhalten,
                            die der Nutzer unter Verletzung der Regelungen dieser AGB auf Gymondo-Plattform eingestellt
                            hat. Ferner verpflichtet sich der Nutzer, Gymondo alle erforderlichen Aufwendungen zu
                            erstatten, die Gymondo dadurch entstehen, dass Dritte wegen der Verletzung ihrer Rechte
                            durch die vom Nutzer eingestellten Inhalte berechtigterweise gegen Gymondo vorgehen. Dies
                            umfasst insbesondere die notwendigen Kosten einer angemessenen Rechtsverteidigung.
                        </p>
                        <p className="gym-p">
                            (3) Wird Gymondo von Dritten wegen etwaiger Verletzung ihrer Rechte durch die vom Nutzer
                            eingestellten Inhalte in Anspruch genommen, wird der Nutzer Gymondo nach besten Kräften bei
                            der Verteidigung gegen die von den Dritten geltend gemachten Ansprüche unterstützen. Dies
                            umfasst insbesondere, dass der Nutzer Gymondo auf entsprechende schriftliche Aufforderung
                            durch Gymondo alle bei ihm vorhandenen Unterlagen in Bezug auf die angeblich
                            rechtsverletzenden Inhalte in Kopie zur Verfügung stellt.
                        </p>
                        <h6>10. Änderungen dieser AGB</h6>
                        <p className="gym-p">
                            Gymondo ist berechtigt, diese AGB während der Laufzeit des Vertrages mit Wirkung für die
                            Zukunft zu ändern und anzupassen, wenn (i) dies aufgrund von veränderten gesetzlichen,
                            behördlichen oder technischen Rahmenbedingungen erforderlich wird und die Änderungen unter
                            Berücksichtigung von Treu und Glauben für den Nutzer zumutbar sind oder (ii) wenn
                            ausschließlich neue Leistungen von Gymondo (wie z.B. die Ausweitung des Angebotes durch die
                            Bereitstellung weiterer Dienste) eingeführt werden. Gymondo wird dem Nutzer die geänderten
                            Bedingungen vor dem geplanten Inkrafttreten in Textform übermitteln und auf die
                            Neuregelungen sowie das Datum des Inkrafttretens besonders hinweisen. Zugleich wird Gymondo
                            dem Nutzer eine angemessene, mindestens vier (4) Wochen lange Frist für die Erklärung
                            einräumen, ob er die geänderten Nutzungsbedingungen für die weitere Inanspruchnahme der
                            Leistungen akzeptiert. Erfolgt innerhalb dieser Frist, welche ab Erhalt der Nachricht in
                            Textform zu laufen beginnt, keine Erklärung, so gelten die geänderten Bedingungen als
                            vereinbart. Gymondo wird den Nutzer bei Fristbeginn gesondert auf diese Rechtsfolge, d.h.
                            das Widerspruchsrecht, die Widerspruchsfrist und die Bedeutung des Schweigens hinweisen.
                            Sollte der Nutzer einer Änderung der AGB widersprechen, so behält sich Gymondo das Recht
                            vor, den Vertrag mit dem entsprechenden Nutzer zu kündigen. Dieser Änderungsmechanismus gilt
                            nicht für Änderungen der vertraglichen Hauptleistungspflichten der Parteien (mit Ausnahme
                            reiner Erweiterungen der Vertragsleistungen von Gymondo).
                        </p>
                        <h6>11. Anwendbares Recht / Gerichtsstand</h6>
                        <p className="gym-p">
                            (1) Für die vertraglichen Beziehungen zwischen den Parteien gilt das Recht der
                            Bundesrepublik Deutschland unter Ausschluss des einheitlichen UN-Kaufrechts (CISG). Sofern
                            der Nutzer Verbraucher ist und keinen Wohnsitz in der Bundesrepublik Deutschland hat,
                            unterliegt das Vertragsverhältnis ausschließlich dem Recht der Bundesrepublik Deutschland
                            unter Ausschluss des CISG, sofern nicht zwingende Bestimmungen des Rechts des Staates, in
                            dem der Verbraucher seinen gewöhnlichen Aufenthalt hat, etwas anderes vorsehen.
                        </p>
                        <p className="gym-p">
                            (2) Wenn der Nutzer keinen allgemeinen Gerichtsstand in Deutschland oder in einem anderen
                            EU-Mitgliedsstaat hat oder er Kaufmann oder eine juristische Person des öffentlichen Rechts
                            ist oder seinen festen Wohnsitz nach Wirksamwerden dieser AGB für Verbraucher ins Ausland
                            verlegt oder der Wohnsitz oder gewöhnliche Aufenthaltsort im Zeitpunkt der Klageerhebung
                            nicht bekannt ist, ist ausschließlicher Gerichtsstand für sämtliche Streitigkeiten aus
                            diesem Vertrag der Sitz von Gymondo.
                        </p>
                        <h6>
                            12. Alternative Streitbeilegung gemäß Verbraucherstreitbeilegungsgesetz und
                            Schlussbestimmungen
                        </h6>
                        <p className="gym-p">
                            (1) Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung bereit, die
                            der Nutzer unter{' '}
                            <a href="http://www.ec.europa.eu/consumers/odr" target="_blank" rel="noreferrer">
                                http://www.ec.europa.eu/consumers/odr
                            </a>{' '}
                            erreichen kann. Gymondo ist zur Teilnahme an einem Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle nicht verpflichtet und nicht bereit.
                        </p>
                        <p className="gym-p">
                            (2) Gegenüber Zahlungsansprüchen von Gymondo kann der Nutzer nur aufrechnen, wenn die zur
                            Aufrechnung gestellte Forderung des Nutzers unbestritten oder rechtskräftig festgestellt ist
                            oder im Gegenseitigkeitsverhältnis mit den Ansprüchen von Gymondo steht. Gleiches gilt für
                            die Ausübung eines Zurückbehaltungsrechtes durch den Nutzer.
                        </p>
                        <p className="gym-p">
                            (3) Sollte eine Bestimmung dieser AGB unwirksam oder undurchführbar sein oder werden oder
                            sollten diese AGB eine Lücke enthalten, wird dadurch die Rechtswirksamkeit der AGB im
                            Übrigen nicht berührt.
                        </p>
                        <p className="gym-p">
                            Diese AGB sind in der jeweils geltenden Fassung innerhalb der Webseite abrufbar und können
                            durch einen Klick auf den Link AGB jederzeit aufgerufen, gedruckt, als pdf heruntergeladen
                            und/oder abgespeichert werden. Zum Öffnen einer PDF-Datei ist unter Umständen ein spezielles
                            Programm wie der kostenlose Acrobat Reader oder ein vergleichbares Programm erforderlich,
                            das den Umgang mit PDF-Dateien beherrscht.
                            <br />
                            <br />
                            Stand: April 2021
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Terms;
