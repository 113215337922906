import React, { useCallback, useContext } from 'react';
import { dispatch, DispatchActions } from '../../state/actions';
import { AccountProps, AppStateContext } from '../../state/context';
import { AccountForm, AccountNavigation } from '..';
import { AccountBackround } from '../../assets';
import { AccountBackroundMobile } from '../../assets';

const Account: React.FC = () => {
    const context = useContext(AppStateContext);
    const isFreebie = context.appState.user.isFreebie;
    const account = context.appState.user.account;

    const handleSubmit = useCallback(
        async (data: AccountProps) => {
            try {
                const _account = data as unknown as Record<string, unknown>;
                await dispatch(DispatchActions.saveAccount, _account, context);
            } catch (error: unknown) {
                const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
                console.log(_errData);
            }
        },
        [context],
    );

    return (
        <>
            <div className="container-fluid no-padding first-container">
                <div className="row center-xs">
                    <div className="image-module col">
                        <h1>Mein Konto</h1>
                        <div className="image__background">
                            <img src={AccountBackround} />
                            <img src={AccountBackroundMobile} className="background-mobile" />
                        </div>
                    </div>
                </div>
            </div>
            <AccountNavigation isFreebie={isFreebie} />
            <div className="row">
                <div className="col-xs-12">
                    <div className="box-row">
                        <AccountForm account={account} isFreebie={isFreebie} onSubmit={handleSubmit} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Account;
