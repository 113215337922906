import React from 'react';
import { Link } from 'react-router-dom';
import { HeaderLogoWhite, HeaderLogoWhiteMobile } from '../../assets';
import { PATHS } from 'state/config';

const HeaderSmall: React.FC = () => {
    return (
        <header className="header top-header">
            <div className="top-header__wrapper_">
                <section className="top-header__content">
                    <Link to={PATHS.HOMEPAGE} className="gym-logo">
                        <HeaderLogoWhite />
                        <HeaderLogoWhiteMobile className="logo-mobile" />
                    </Link>
                </section>
            </div>
        </header>
    );
};
export default HeaderSmall;
