import { useCallback, useContext, useEffect, useState } from 'react';

import { AppStateContext } from 'state/context';
import { getStoredPartnerKey, storeProductId, storeProgramId } from 'state/session';
import { ReaderPreLogin } from 'state/helpers/json';
import { getPartner } from 'state/helpers/program';

const useProgramPage = (program_id: number) => {
    const [reimbursementRate, setReimbursementRate] = useState('');
    const [showVideo, setShowVideo] = useState(false);

    const {
        appState: { staticData },
    } = useContext(AppStateContext);

    const partner_key = getStoredPartnerKey();
    const partner = getPartner(program_id, partner_key);
    const productId = partner?.PRODUCT_ID;
    const isFreebieUser = partner ? partner.IS_FREEBIE : false;

    /* main data loader */
    useEffect(() => {
        storeProgramId(program_id);
        if (partner) {
            storeProductId(partner.PRODUCT_ID);
        }
    }, []);

    const handleVideoPreview = useCallback(() => {
        setShowVideo(!showVideo);
    }, [showVideo]);

    const JsonReader = new ReaderPreLogin(staticData, program_id);
    const ReimbursementRates = JsonReader.getReimbursementRates();
    const defaultReimbursementRate = ReimbursementRates[0]?.rate ?? '100%';

    return {
        ReimbursementRates,
        defaultReimbursementRate,
        handleVideoPreview,
        isFreebieUser,
        partner,
        reimbursementRate,
        setReimbursementRate,
        JsonReader,
        showVideo,
        productId,
    };
};
export default useProgramPage;
