import React, { useRef } from 'react';
import { Icon } from '@gymondo/frontend-core/components';
import { ADYEN_CONFIG, PAYMENT_METHODS } from '../../../state/configPayment';
import usePaymentMethod from '../use-payment-method';
import PaymentTemplate, { DEFAULT_STYLES, genericCTAButton } from '../PaymentTemplate';
import text from '../../../lang';
import { SepaLogo } from '../../../assets/payment';

const METHOD = PAYMENT_METHODS.SEPA;

const Sepa: React.FC = () => {
    const cardRef = useRef(null);

    const config = {
        styles: DEFAULT_STYLES,
    };

    const additionalConfig = {
        translations: {
            [ADYEN_CONFIG.locale]: {
                'sepa.ownerName': text('subscribe_method_sepa_owner_name'),
                'sepa.ibanNumber': text('subscribe_method_sepa_iban_number'),
                'sepaDirectDebit.nameField.placeholder': text('subscribe_card_holder_name_placeholder'),
            },
        },
    };

    const { submitData, isLoading, isValid, errorMessage } = usePaymentMethod({
        paymentMethod: METHOD,
        config,
        ref: cardRef,
        additionalConfig,
    });

    return (
        <PaymentTemplate
            errorMessage={errorMessage}
            button={genericCTAButton(isValid, isLoading, METHOD, async () => {
                await submitData();
            })}
            paymentMethod={METHOD}
        >
            <div className="cards">
                <Icon data={SepaLogo} className="card-icon" />
            </div>
            <div ref={cardRef} className="payment-method-sepa" />
        </PaymentTemplate>
    );
};

export default Sepa;
