/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useRef } from 'react';
import { LoadingSpinner } from '@gymondo/frontend-core/components';
import { PAYMENT_METHODS } from 'state/configPayment';
import PaymentTemplate from '../PaymentTemplate';
import { Tracker } from '@gymondo/frontend-core/utils';
import usePaymentMethod from '../use-payment-method';

const METHOD = PAYMENT_METHODS.APPLE_PAY;

const ApplePay: React.FC = () => {
    const ref = useRef(null);

    const { isLoading, errorMessage, paymentSuccess } = usePaymentMethod({
        paymentMethod: METHOD,
        config: {},
        ref,
    });

    return (
        <div>
            <PaymentTemplate
                paymentMethod={METHOD}
                errorMessage={errorMessage}
                button={
                    <>
                        {paymentSuccess && (
                            <div
                                // eslint-disable-next-line react/button-has-type
                                ref={ref}
                                data-evt={Tracker.EVENT_TYPES.CLICK}
                                data-category="checkout"
                                data-action="payment_cta_clicked"
                                data-label={METHOD}
                            />
                        )}
                        {(isLoading || paymentSuccess) && <LoadingSpinner />}
                    </>
                }
            />
        </div>
    );
};

export default ApplePay;
