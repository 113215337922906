import React from 'react';
import { Icon } from '@gymondo/frontend-core/components';
import { ReactComponent as arrowSvg } from '@gymondo/frontend-core/svg/arrow-bold.svg';

interface LogoProps {
    name: string;
    logo: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}
interface LogosProps {
    types: LogoProps[];
}

const Logos: React.FC<LogosProps> = ({ types }) => (
    <ul className="payment-method-logo-wrapper">
        {types.map((type) => (
            <li key={type.name as React.Key}>
                <Icon data={type.logo} className={`logo logo${type.name}`} />
            </li>
        ))}
        <li>
            <Icon data={arrowSvg} className="arrow" />
        </li>
    </ul>
);

export default Logos;
