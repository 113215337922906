import React, { useRef } from 'react';
import { LoadingSpinner } from '@gymondo/frontend-core/components';
import { MERCHANT_NAME, PAYMENT_METHODS } from '../../../state/configPayment';
import { SUPPORTED_CARD_TYPES } from './config';
import { getEnvVar } from '../../../utils/env';
import usePaymentMethod from '../use-payment-method';
import PaymentTemplate from '../PaymentTemplate';

const METHOD = PAYMENT_METHODS.GOOGLE_PAY;

const GooglePay: React.FC = () => {
    const cardRef = useRef(null);
    const { isLoading, errorMessage, paymentSuccess } = usePaymentMethod({
        paymentMethod: METHOD,
        config: {
            configuration: {
                merchantName: MERCHANT_NAME,
                gatewayMerchantId: getEnvVar('GOOGLE_PAY_GATEWAY_MERCHANT_ID'),
                merchantIdentifier: getEnvVar('GOOGLE_PAY_MERCHANT_IDENTIFIER'),
            },
            allowedCardNetworks: SUPPORTED_CARD_TYPES,
            environment: getEnvVar('GOOGLE_PAY_ENV'),
            existingPaymentMethodRequired: false,
            buttonColor: 'black',
        },
        ref: cardRef,
    });

    const googlePayButton = (
        <>
            {!paymentSuccess && (
                <div
                    ref={cardRef}
                    data-evt="click"
                    data-category="checkout"
                    data-action="payment_cta_clicked"
                    data-label={METHOD}
                />
            )}
            {(isLoading || paymentSuccess) && <LoadingSpinner />}
        </>
    );

    return <PaymentTemplate paymentMethod={METHOD} errorMessage={errorMessage} button={googlePayButton} />;
};

export default GooglePay;
