import React, { useEffect } from 'react';
import { dispatch, DispatchActions } from 'state/actions';
import { Button } from '@gymondo/frontend-core/components';
import text from 'lang';
import { getEnvVar } from 'utils/env';
import { PATHS } from '../../state/config';

const PurchaseNotAllowed: React.FC = () => {
    useEffect(() => {
        dispatch(DispatchActions.signOut);
    });

    return (
        <div className="cannot_purchase">
            <p>
                Es sieht so aus, als bist du bereits Mitglied bei Gymondo! Du kannst deine Mitgliedschaft jederzeit auf
                der Gymondo-Homepage unter <i>Dein Account</i> verwalten.
            </p>
            <div className="row end-xs">
                <a href={getEnvVar('FRONTEND_POSTLOGIN_URL')} className="gym-btn no-margin">
                    <Button>{text('cannot_purchase_product_to_gymondo')}</Button>
                </a>
                <a href={PATHS.HOMEPAGE} className="no-margin">
                    <Button className="btn--border">{text('cannot_purchase_product_to_homepage')}</Button>
                </a>
            </div>
        </div>
    );
};

export default PurchaseNotAllowed;
