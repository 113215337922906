import Api from './api';
import { setCookiesToken } from '@gymondo/frontend-core/api';
import jsCookie from 'js-cookie';
import { AccountProps, AppProps, initialAppStateContext, ProtectedAppProps } from './context';
import COOKIE_NAMES from '@gymondo/frontend-core/enums/cookie-names.enum';
import { SignUpArgs, Voucher } from './api.types';
import moment from 'moment';
import { Tracker } from '@gymondo/frontend-core/utils';
import { getProgram } from 'state/helpers/program';
import { waitFor } from '../utils/async';

interface TokenProps {
    access_token: string;
    refresh_token: string;
    id_token: string;
}

export const isAuthenticated = () => {
    return Boolean(jsCookie.get(COOKIE_NAMES.OAUTH2_REFRESH_TOKEN));
};

const _saveToken = (
    token: TokenProps,
    appState: ProtectedAppProps,
    refreshAppState: (appState: ProtectedAppProps) => void,
) => {
    setCookiesToken(token);
    refreshAppState({ ...appState, isAuthenticated: true });
};

const _setFreebieUser = (
    isFreebie: boolean,
    appState: ProtectedAppProps,
    refreshAppState: (appState: ProtectedAppProps) => void,
) => {
    refreshAppState({ ...appState, user: { ...appState.user, isFreebie } });
};

const _setPaymentSession = (
    data: unknown,
    appState: ProtectedAppProps,
    refreshAppState: (appState: ProtectedAppProps) => void,
) => {
    const session = data as Record<string, any> | null;
    refreshAppState({ ...appState, paymentSession: { ...appState.paymentSession, session } });
};

const _setUserAccount = (
    data: unknown,
    appState: ProtectedAppProps,
    refreshAppState: (appState: ProtectedAppProps) => void,
) => {
    const account = data as AccountProps;
    refreshAppState({ ...appState, user: { ...appState.user, account } });
};

// public api actions w/o auth
export const dispatchSignIn = async (args: { email: string; password: string }, context: AppProps) => {
    const { appState, refreshAppState } = context;
    try {
        const response = await Api.signIn(args.email, args.password);
        _saveToken(response, appState, refreshAppState);
    } catch (error: unknown) {
        throw error;
    }
};

export interface DispatchSignUpArgs extends SignUpArgs {
    birthDate?: number;
    isFreebie?: boolean;
}

export const dispatchSignUp = async (
    { email, password, insurance_number, isFreebie = false }: DispatchSignUpArgs,
    context: AppProps,
) => {
    const { appState, refreshAppState } = context;
    try {
        await new Api().signUp({ email, password, insurance_number });
        _setFreebieUser(isFreebie, appState, refreshAppState);
    } catch (error: unknown) {
        throw error;
    }
};

export const dispatchResetPassword = async (email: string, forwardUrl: string) => {
    try {
        await new Api().resetPassword(email, forwardUrl);
    } catch (error: unknown) {
        throw error;
    }
};

export const dispatchCheckVoucher = async (voucher_code: string, storeProduct: number) => {
    try {
        return await new Api().checkVoucher(voucher_code, storeProduct);
    } catch (error: unknown) {
        throw error;
    }
};

export const dispatchGetLocations = async (postal_code: string) => {
    try {
        return await new Api().getLocations(postal_code);
    } catch (error: unknown) {
        throw error;
    }
};

// end public

// api actions with token and update app state

export enum DispatchActions {
    createInsuranceRegistration = '_createInsuranceRegistration',
    endProgramPlan = '_endProgramPlan',
    getEndedPlans = '_getEndedPlans',
    trackPaymentSuccess = '_trackPaymentSuccess',
    getAccount = '_getAccount',
    getProducts = '_getProducts',
    getContentPostLogin = '_getContentPostLogin',
    getContentPreLogin = '_getContentPreLogin',
    getDashboard = '_getDashboard',
    getQuiz = '_getQuiz',
    getResource = '_getResource',
    onboardingSave = '_onboardingSave',
    onboardingStatus = '_onboardingStatus',
    paymentSession = '_paymentSession',
    resetPaymentSession = '_resetPaymentSession',
    purchaseHistory = '_purchaseHistory',
    redeemVoucher = '_redeemVoucher',
    saveAccount = '_saveAccount',
    patchInsurance = '_patchInsurance',
    sendPaymentDetails = '_sendPaymentDetails',
    setQuiz = '_setQuiz',
    setWorkoutCompleted = '_setWorkoutCompleted',
    signOut = '_signout',
    pollPurchase = '_pollPurchase',
    pollPlan = '_pollPlan',
    createPurchase = '_createPurchase',
}

/* MAIN FACTORY */
export const dispatch = async (
    action: string,
    args: Record<string, unknown> = {},
    context: AppProps = initialAppStateContext,
) => {
    const { appState, refreshAppState } = context;
    const ProtectedApi = new Api();
    try {
        let response;

        if (action === DispatchActions.signOut) {
            response = await ProtectedApi.signOut();
        }
        if (action === DispatchActions.paymentSession) {
            const product = args.product as Record<string, any>;
            const payment_method = args.payment_method as string;
            const channel_id = args.channel_id as string;
            response = await ProtectedApi.paymentSession(product, payment_method, channel_id);
            _setPaymentSession(response, appState, refreshAppState);
        }
        if (action === DispatchActions.resetPaymentSession) {
            _setPaymentSession(null, appState, refreshAppState);
        }
        if (action === DispatchActions.onboardingSave) {
            const account = args as unknown as AccountProps;
            response = await ProtectedApi.onboardingSave(account);
            // not from response, cause empty!
            _setUserAccount(account, appState, refreshAppState);
        }
        if (action === DispatchActions.saveAccount) {
            const account = args as unknown as AccountProps;
            response = await ProtectedApi.saveAccount(account);
            // not from response, cause no address!
            _setUserAccount(account, appState, refreshAppState);
        }
        if (action === DispatchActions.patchInsurance) {
            const account = args.account as AccountProps;
            const refresh = args.refresh as boolean | null;
            response = await ProtectedApi.saveAccount(account);
            if (refresh) {
                _setUserAccount(account, appState, refreshAppState);
            }
        }
        if (action === DispatchActions.trackPaymentSuccess) {
            const pspReference = args.pspReference as string | undefined;
            const programID = args.programId as number;
            const userId = args.userId as number;
            const price = getProgram(programID)?.PRICE.replace(',', '.');
            Tracker.push({
                event: 'registrationSuccess',
                pageCategory: 'success',
                userId,
                user_id: userId,
                category: 'prevention',
                price,
                pspReference,
            });
        }
        if (action === DispatchActions.getAccount) {
            response = await ProtectedApi.getAccount();
        }
        if (action === DispatchActions.onboardingStatus) {
            response = await ProtectedApi.onboardingStatus();
        }
        if (action === DispatchActions.purchaseHistory) {
            response = await ProtectedApi.purchaseHistory();
        }
        if (action === DispatchActions.endProgramPlan) {
            const plan_id = args.plan_id as string;
            response = await ProtectedApi.endProgramPlan(plan_id);
        }
        if (action === DispatchActions.getEndedPlans) {
            response = await ProtectedApi.getEndedPlans();
        }
        if (action === DispatchActions.getDashboard) {
            response = await ProtectedApi.getDashboard();
        }
        if (action === DispatchActions.getResource) {
            const resource_id = args.resource_id as number;
            response = await ProtectedApi.getResource(resource_id);
        }
        if (action === DispatchActions.getContentPreLogin) {
            response = await ProtectedApi.getContentPreLogin();
        }
        if (action === DispatchActions.getContentPostLogin) {
            const program_id = args.program_id as number;
            response = await ProtectedApi.getContentPostLogin(program_id);
        }
        if (action === DispatchActions.getProducts) {
            response = await ProtectedApi.getProducts();
        }
        if (action === DispatchActions.getQuiz) {
            const schedule_id = args.schedule_id as string;
            response = await ProtectedApi.getQuiz(schedule_id);
        }
        if (action === DispatchActions.setQuiz) {
            const schedule_id = (args.schedule_id as string) || '';
            response = await ProtectedApi.setQuiz(schedule_id);
        }
        if (action === DispatchActions.setWorkoutCompleted) {
            const schedule_id = args.schedule_id as string;
            const program_id = args.program_id as number;
            const resource_id = args.resource_id as number;
            const finish_date = args.finish_date as number;
            response = await ProtectedApi.setWorkoutCompleted(schedule_id, program_id, resource_id, finish_date);
        }
        if (action === DispatchActions.redeemVoucher) {
            const voucher = args.voucher as Voucher;
            const product_id = args.product_id as number;
            response = await ProtectedApi.redeemVoucher(voucher, product_id);
        }
        if (action === DispatchActions.pollPurchase) {
            const program_id = args.program_id as number;
            await waitFor(async () => {
                response = await ProtectedApi.purchaseHistory();
                return response.content.some((p: any) => p.storeProduct.resourceId === program_id);
            });
        }
        if (action === DispatchActions.pollPlan) {
            const program_id = args.program_id as number;
            await waitFor(async () => {
                response = await ProtectedApi.getDashboard();
                return response.plans?.length > 0 && response.plans.some((p: any) => p.program.id === program_id);
            });
        }
        if (action === DispatchActions.createPurchase) {
            const program_id = args.program_id as number;
            const product_id = args.product_id as number;
            const channel_id = args.channel_id as number;
            response = await ProtectedApi.createPurchase(program_id, product_id, channel_id);
        }

        return response;
    } catch (error: unknown) {
        throw error;
    }
};

// end secure
