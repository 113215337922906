import React from 'react';
import { Link } from 'react-router-dom';
import text from '../../lang';
import { PATHS } from 'state/config';
import { isLocationPath } from 'utils/system';

interface NavProps {
    isFreebie?: boolean;
}

const Navigation: React.FC<NavProps> = ({ isFreebie }) => {
    return (
        <div className="container">
            <div className="row center-xs navigation-tabs-container">
                <div className="col-xs-12">
                    <div className="box-row">
                        <div className="navigation-tabs">
                            <Link to={PATHS.ACCOUNT} className={`${isLocationPath(PATHS.ACCOUNT) ? ' active' : ''}`}>
                                {text('account_user_data')}
                            </Link>
                            {!isFreebie && (
                                <>
                                    {' '}
                                    <Link
                                        to={PATHS.PAYMENT_CONFIRMATION}
                                        className={`${isLocationPath(PATHS.PAYMENT_CONFIRMATION) ? ' active' : ''}`}
                                    >
                                        {text('account_payment_confirmation')}
                                    </Link>
                                </>
                            )}{' '}
                            <Link
                                to={PATHS.PARTICIPATION_CONFIRMATION}
                                className={`${isLocationPath(PATHS.PARTICIPATION_CONFIRMATION) ? ' active' : ''}`}
                            >
                                {text('account_participation_confirmation')}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Navigation;
