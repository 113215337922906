import React, { useState } from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Button, Input } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { FormSubmissionError } from '..';

interface ResetPasswordFormData {
    email: string;
}

interface ResetPasswordFormProps {
    onSubmit: (data: ResetPasswordFormData) => void;
}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onSubmit }) => {
    const [formSubmissionError, setFormSubmissionError] = useState('');
    return (
        <>
            <div>{text('reset_password_form_note')}</div>
            <Formik
                initialValues={{
                    email: '',
                }}
                validationSchema={Yup.object({
                    email: Yup.string().required(text('validation_email_note')).email(text('validation_email_invalid')),
                })}
                onSubmit={async (data, { setSubmitting }) => {
                    try {
                        await onSubmit(data);
                    } catch (error: unknown) {
                        setSubmitting(false);
                        const _message = (error as Record<string, unknown>).message as string;
                        setFormSubmissionError(_message);
                    }
                }}
            >
                {({ dirty, isSubmitting, isValid }) => (
                    <Form>
                        {formSubmissionError && <FormSubmissionError message={formSubmissionError} className="" />}
                        <Field name="email">
                            {({ field, form: { errors, touched } }: FieldProps) => (
                                <Input
                                    field={{
                                        ...field,
                                        placeholder: text('placeholder_your_email'),
                                        type: 'email',
                                    }}
                                    form={{
                                        errors: errors,
                                        touched: touched,
                                    }}
                                    multiline={false}
                                />
                            )}
                        </Field>
                        <Button htmlType="submit" disabled={!dirty || isSubmitting || !isValid} fluid>
                            {text('request_button')}
                        </Button>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default ResetPasswordForm;
