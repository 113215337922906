import React from 'react';

const FooterNote: React.FC = () => {
    return (
        <>
            Ganzkörper-Kurs-ID: KU-BE-SLPFXF <br />
            Rücken-Kurs-ID: KU-BE-8LQKAM <br />
            Unsere Gymondo-Präventionskurse sind offiziell nach § 20 Abs. 1 SGB V zertifiziert. Die meisten gesetzlichen
            Krankenkassen erstatten bis zu 100% der Teilnahmekosten.
            <br />
            <br />
            <b>Hinweis:</b> Es wird ausdrücklich darauf hingewiesen, dass die Übungen und Maßnahmen, die in diesem
            Präventionskurs empfohlen werden, nicht für jeden geeignet sind und dass dieses Online-Programm auf eigene
            Gefahr genutzt wird. Im Zweifelsfall sollte vor Beginn des Kurses medizinisches Fachpersonal (Arzt oder
            Physiotherapeut) konsultiert werden. Die Übungen des Präventionskurses sind kein Ersatz für eine ärztliche
            Untersuchung oder Behandlung. Mögliche Einschränkungen aufgrund chronischer oder akuter Erkrankungen sowie
            für Frauen während und nach der Schwangerschaft sind zu beachten.
        </>
    );
};

export default FooterNote;
