import React from 'react';
import useProgramPage from 'components/dashboard/useProgramPage';
import { getGanzkoerperProgram } from 'state/helpers/program';
import { GanzkoerperPartner, GanzkoerperReward } from 'components/index';

const Ganzkoerper: React.FC = () => {
    const program_id = getGanzkoerperProgram().ID;

    const { partner } = useProgramPage(program_id);

    if (partner?.KEY) {
        return <GanzkoerperPartner />;
    }

    return <GanzkoerperReward />;
};
export default Ganzkoerper;
