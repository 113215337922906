import React, { useCallback, useContext, useState } from 'react';
import { Button } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { AccountProps, AppStateContext } from 'state/context';
import { dispatch, DispatchActions } from 'state/actions';
import { LPBackfitLogo, LPFullBodyLogo, OnboardingBackground, OnboardingBackgroundMobile } from '../../assets';

import { AccountForm } from '..';
import { PATHS } from '../../state/config';
import { redirect } from 'utils/system';
import { getBackfitProgram, getGanzkoerperProgram, getPartner, getProgram } from 'state/helpers/program';
import { getStoredPartnerKey } from 'state/session';

const STEP_WELCOME = 'welcome';
const STEP_ACCOUNT = 'account';
const STEP_START = 'start';

const Onboarding: React.FC = () => {
    const [step, setStep] = useState(STEP_WELCOME);
    const context = useContext(AppStateContext);
    const account = context.appState.user.account;

    const program_id = context.appState.user.programId;
    const partner_key = getStoredPartnerKey();

    const program = getProgram(program_id);
    const partner = getPartner(program_id, partner_key);

    const isFreebie = context.appState.user.isFreebie;

    const handleWelcome = useCallback(() => {
        setStep(STEP_ACCOUNT);
    }, []);

    const handleAccountOnboarding = useCallback(
        async (data: AccountProps) => {
            try {
                // save onboarding
                const _account = data as unknown as Record<string, unknown>;
                await dispatch(DispatchActions.onboardingSave, _account, context);
                setStep(STEP_START);
            } catch (error: unknown) {
                const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
                console.log(_errData);
            }
        },
        [context],
    );

    const handleStart = useCallback(() => {
        redirect(PATHS.HOMEPAGE);
    }, []);

    return (
        <>
            <div className="wrapper onboarding">
                {step === STEP_WELCOME && (
                    <div className="row first">
                        <div className="col-xs-12 d-flex center-xs middle-xs">
                            <div className="box-row title">
                                {program_id === getBackfitProgram().ID && (
                                    <img className="onboarding-logo" src={LPBackfitLogo} />
                                )}
                                {program_id === getGanzkoerperProgram().ID && (
                                    <img className="onboarding-logo" src={LPFullBodyLogo} />
                                )}
                                <h1>
                                    Willkommen beim Gymondo <br />
                                    {program?.TITLE}!
                                </h1>
                                <p>
                                    Schön, dass du da bist. Wir benötigen einige Informationen von dir, damit wir deine
                                    Teilnahmebestätigung zusammenstellen können, die du bei erfolgreichem Abschluss des
                                    Programms erhältst.
                                </p>
                                <Button onClick={handleWelcome}>{text('further_button')}</Button>
                            </div>
                        </div>
                    </div>
                )}
                {step === STEP_ACCOUNT && (
                    <div className="row second">
                        <div className="col-xs-12 d-flex center-xs middle-xs">
                            <div className="box-row form">
                                <AccountForm
                                    account={account}
                                    isFreebie={isFreebie}
                                    hasBirthDateField={!partner?.REQUIRE_BIRTHDATE_ON_SIGNUP}
                                    onSubmit={handleAccountOnboarding}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {step === STEP_START && (
                    <div className="row third">
                        <div className="col-xs-12 d-flex center-xs middle-xs">
                            <div className="box-row title">
                                <h4>Toll! Jetzt kannst du deinen Kurs {program?.TITLE} starten</h4>
                                <p>
                                    Über 10 Wochen hinweg schalten wir jede Woche eine neue Trainingseinheit für dich
                                    frei. Die einzelnen Wochenmodule bestehen aus jeweils einer theoretischen
                                    Lerneinheit und einer praktischen Übungseinheit.
                                    <br />
                                    <br />
                                    Du erfährst durch professionelle Trainingsvideos und informative Texte Wissenswertes
                                    rund um den menschlichen Bewegungsapparat und erlernst wichtige Übungen und
                                    Präventionsmaßnahmen für einen gesunden Körper.
                                </p>
                                <Button onClick={handleStart}>{text('start_now_button')}</Button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="onboarding-background">
                <img src={OnboardingBackground} />
                <img src={OnboardingBackgroundMobile} className="onboarding-background-mobile" />
            </div>
        </>
    );
};
export default Onboarding;
