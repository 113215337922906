import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface ProtectedRouteProps extends RouteProps {
    isAuthenticated: boolean;
    isAllowed?: boolean;
    restrictedPath?: string;
    authenticationPath: string;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = (props) => {
    let redirectPath: string | undefined;
    if (!props.isAuthenticated) {
        redirectPath = props.authenticationPath;
    }
    if (props.isAuthenticated && !props.isAllowed) {
        redirectPath = props.restrictedPath;
    }

    if (redirectPath) {
        return <Redirect to={redirectPath} />;
    } else {
        return <Route {...props} />;
    }
};

export interface SignedInRouteProps extends RouteProps {
    isAuthenticated: boolean;
    redirectPath?: string;
}

export const SignedInRoute: React.FC<SignedInRouteProps> = (props) => {
    let redirectPath: string | undefined;
    if (props.isAuthenticated) {
        redirectPath = props.redirectPath;
    }

    if (redirectPath) {
        return <Redirect to={redirectPath} />;
    } else {
        return <Route {...props} />;
    }
};
