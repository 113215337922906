const TEST_ENVIRONMENT = 'test';
const STAGE_ENVIRONMENT = 'stage';
const PRODUCTION_ENVIRONMENT = 'prod';

export const getCurrentEnv = () => (process && process.env.REACT_APP_ENVIRONMENT) || TEST_ENVIRONMENT;

export const isTestEnv = getCurrentEnv() === TEST_ENVIRONMENT;
export const isStageEnv = getCurrentEnv() === STAGE_ENVIRONMENT;
export const isProdEnv = getCurrentEnv() === PRODUCTION_ENVIRONMENT;

export const getEnvVar = (name: string) => {
    const _name = `REACT_APP_${name}`;
    return String(process.env[_name] || '');
};
