import { AxiosError, AxiosRequestConfig, Method } from 'axios';
import { getQueryStringFromObject } from 'utils/string';
import { getEnvVar } from 'utils/env';
import { AccountProps } from './context';
import { clearLoginData, crudGeneric } from '@gymondo/frontend-core/api';
import LOCALES from '@gymondo/frontend-core/enums/locales.enum';
import COOKIE_NAMES from '@gymondo/frontend-core/enums/cookie-names.enum';
import { SignUpArgs, Voucher } from './api.types';
import preloginData from '../lang/preloginData.json';
import { getCurrentHost } from '../utils/system';

const OAUTH_URL = getEnvVar('OAUTH_URL');
const OAUTH_USERNAME = getEnvVar('OAUTH_USERNAME');
const OAUTH_PASSWORD = getEnvVar('OAUTH_PASSWORD');
const API_URL = getEnvVar('API_URL');

enum REQUEST_METHOD {
    GET = 'get',
    POST = 'post',
    PATCH = 'patch',
}

export const refreshAccessTokenError = () => {
    clearLoginData();
};

export const isAuthNeeded = () => true;

const LANG_FALLBACK = LOCALES.DE_DE;
export const getUserLocale = () => LANG_FALLBACK;

const TOKEN_ENDPOINT = '/token';

const constants = {
    OAUTH_URL,
    TOKEN_ENDPOINT,
    OAUTH_USERNAME,
    OAUTH_PASSWORD,
    GOOGLE_APP_ID: '',
    FACEBOOK_APP_ID: '',
    FACEBOOK_VERSION: '',
    OAUTH2_ACCESS_TOKEN: COOKIE_NAMES.OAUTH2_ACCESS_TOKEN,
    OAUTH2_REFRESH_TOKEN: COOKIE_NAMES.OAUTH2_REFRESH_TOKEN,
    OAUTH2_REALM: COOKIE_NAMES.OAUTH2_REALM,
};

const funcs = {
    isAuthNeeded,
    getUserLocale,
    refreshAccessTokenError,
};

const axiosInstance = crudGeneric(constants, funcs);
axiosInstance.defaults.baseURL = API_URL;

export default class Api {
    // wrapper
    private _requestWrapper = async (
        endPoint: string,
        method: Method,
        data?: unknown,
        additionalConfig?: AxiosRequestConfig,
    ) => {
        try {
            const config: AxiosRequestConfig = { url: endPoint, method: method, ...additionalConfig };
            if (data) {
                config.data = data;
            }
            const response = await axiosInstance.request(config);
            return response.data;
        } catch (error: unknown) {
            const _err = error as AxiosError;
            throw _err.response;
        }
    };

    // main oauth: static w/o default request wrapper & axios instance
    static signIn = async (email: string, password: string) => {
        const endPoint = `${OAUTH_URL}/token`;
        const _payload = {
            username: email,
            password,
        };
        const payload = getQueryStringFromObject(_payload);
        const config: AxiosRequestConfig = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        };
        try {
            const response = await axiosInstance.post(endPoint, payload, config);
            return response.data;
        } catch (error: unknown) {
            const _err = error as Record<string, unknown>;
            throw _err.response;
        }
    };

    signOut = async () => {
        const endPoint = `${OAUTH_URL}/logout`;
        const response = await this._requestWrapper(endPoint, REQUEST_METHOD.POST);
        clearLoginData();

        return response;
    };

    resetPassword = async (email: string, forwardUrl: string) => {
        const endPoint = `/v2/users/reset-password`;
        const payload = {
            forwardUrl,
            username: email,
        };
        return await this._requestWrapper(endPoint, REQUEST_METHOD.POST, payload);
    };

    checkVoucher = async (voucher_code: string, storeProduct: number): Promise<Voucher> => {
        const endPoint = `/v1/public/vouchers/${voucher_code}/valid?app=PREVENTION&productId=${storeProduct}`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    signUp = async ({ email, password, insurance_number }: SignUpArgs) => {
        const endPoint = `/v2/users`;
        const payload = {
            email,
            password,
            username: email,
            healthInsurance: insurance_number,
            registeredVia: 'WEBAPP',
            locale: 'de_DE',
            newsletter: true,
        };

        return await this._requestWrapper(endPoint, REQUEST_METHOD.POST, payload);
    };

    paymentSession = async (product: unknown, paymentMethod: string, channelId: string) => {
        const endPoint = `/v3/payments/sessions`;
        const payload = {
            product,
            paymentMethod,
            channelId,
            transactionType: 'RESOURCE',
        };
        return this._requestWrapper(endPoint, REQUEST_METHOD.POST, payload);
    };
    getLocations = async (postal_code: string) => {
        const endPoint = `/v1/public/locations?q=countryId==80;postalCode==${postal_code}*&size=100`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getAccount = async () => {
        const endPoint = `/v3/secured/users/me?include=address,healthInsurance`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    saveAccount = async (account: AccountProps) => {
        const endPoint = `/v3/secured/users/${account.id}`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.PATCH, account);
    };

    onboardingStatus = async () => {
        const endPoint = `/v3/secured/onboarding/status`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    onboardingSave = async (account: AccountProps) => {
        const endPoint = `/v3/secured/onboarding`;
        const user = {
            user: account,
        };
        return await this._requestWrapper(endPoint, REQUEST_METHOD.PATCH, user);
    };

    purchaseHistory = async () => {
        const endPoint = `/v3/secured/programs/prevention/purchasehistory`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    // program

    endProgramPlan = async (plan_id: string) => {
        const endPoint = `/v3/plans/prevention/${plan_id}/end`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.POST);
    };

    getEndedPlans = async () => {
        const endPoint = `/v3/prevention/plans/ended?size=10`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getDashboard = async () => {
        const endPoint = `/v3/prevention/dashboard`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getResource = async (resource_id: number) => {
        const endPoint = `/v3/secured/resources/${resource_id}`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getContentPreLogin = async () => {
        // TODO: break json down and use translations library instead
        // localize prelogin copies, no need for backend flow complications
        return preloginData;
    };

    getContentPostLogin = async (program_id: number) => {
        const endPoint = `/v1/prevention/programs/${program_id}`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getProducts = async () => {
        const endPoint = `/v1/public/prevention/products`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    getQuiz = async (schedule_id: string) => {
        const endPoint = `/v3/secured/prevention/${schedule_id}/quizzes`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.GET);
    };

    setQuiz = async (schedule_id: string) => {
        const endPoint = `/v3/secured/prevention/${schedule_id}/quizzes`;
        return await this._requestWrapper(endPoint, REQUEST_METHOD.POST);
    };

    setWorkoutCompleted = async (schedule_id: string, program_id: number, resource_id: number, finish_date: number) => {
        const endPoint = `/v3/training-activities`;
        const payload = {
            scheduleId: schedule_id,
            programId: program_id,
            workoutId: resource_id,
            finishDate: finish_date,
        };
        return await this._requestWrapper(endPoint, REQUEST_METHOD.POST, payload);
    };

    redeemVoucher = async (voucher: Voucher, productId: number) => {
        const endPoint = `/v3/secured/vouchers/${voucher.code}/redeem`;
        const qs = {
            app: 'PREVENTION',
            productId,
        };
        try {
            return await this._requestWrapper(endPoint, REQUEST_METHOD.POST, undefined, { params: qs });
        } catch (err) {
            throw err;
        }
    };

    createPurchase = async (programId: number, productId: number, channelId: number) => {
        const endPoint = `/v3/secured/programs/${programId}/purchase/${productId}`;
        const qs: any = {
            productId,
            abortLink: getCurrentHost(),
        };
        if (!isNaN(channelId)) {
            qs.channelId = channelId;
        }
        try {
            return await this._requestWrapper(endPoint, REQUEST_METHOD.GET, undefined, { params: qs });
        } catch (err) {
            throw err;
        }
    };
}
