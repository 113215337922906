const LOCALE = 'de-DE';

export const getQueryStringFromObject = (json: Record<string, string | number>) => {
    const str = Object.keys(json)
        .map(function (key) {
            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
        })
        .join('&');
    return str;
};

export const getLocalDate = (milliseconds?: number) => {
    const _date = milliseconds ? new Date(milliseconds) : new Date();
    return _date.toLocaleDateString(LOCALE, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
};

/* calculate remaining weeks of 'total' from start (ms) till now */
export const getWeeksRemaining = (total: number, start: number) => {
    if (start === 0) {
        return total;
    }
    const diff = Date.now() - start;
    return total - Math.floor(diff / (7 * 24 * 60 * 60 * 1000));
};

export const getDatePlusInterval = (days: number) => {
    const ms = days * 24 * 60 * 60 * 1000;
    const now = Date.now();
    return getLocalDate(now + ms);
};

enum Salutations {
    MALE = 'Herr',
    FEMALE = 'Frau',
}

export const getSalutation = (gender?: string) => {
    let salutation = '';
    return !gender ? '' : (salutation = Salutations[gender as keyof typeof Salutations]) ? salutation : '';
};

export const sprintf = (value: string, ...args: string[]) => {
    args.forEach((arg) => {
        value = value.replace('%s', arg);
    });
    return value;
};

export const getFormattedPrice = (value: number, currency: string) => {
    return value.toLocaleString(LOCALE, {
        minimumFractionDigits: 2,
        style: 'currency',
        currency,
    });
};

export const cutAtChar = (value: string, char = ',') => {
    const [str] = value.split(char);
    return str;
};

export const getFormattedDuration = (seconds: number) => {
    const format = (val: number) => `0${Math.floor(val)}`.slice(-2);
    const hours = seconds / 3600;
    const minutes = (seconds % 3600) / 60;
    if (hours > 1) {
        return [hours, minutes, seconds % 60].map(format).join(':');
    }
    return [minutes, seconds % 60].map(format).join(':');
};
