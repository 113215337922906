import React from 'react';
import ReactDOM from 'react-dom';
import './main.scss';
import '@adyen/adyen-web/dist/adyen.css';
import App from './App';
import { Tracker } from '@gymondo/frontend-core/utils';
// import reportWebVitals from './reportWebVitals';

Tracker.registerTracker();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
