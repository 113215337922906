import { Listeners, VideoPlayer } from 'components/timeline/VideoPlayer';
import { ApiError } from 'utils/error';
import { getWeeksRemaining } from 'utils/string';
import { dispatch, DispatchActions } from '../actions';
import config, { PATHS } from '../config';
import { AppProps, ScheduleProps, UnitStaticDataProps } from '../context';

export const getModuleUrl = (moduleId: number) => {
    return PATHS.TIMELINE.replace(':moduleId', String(moduleId));
};

/* find first missed unit position or current position as an active position */
export const getActivePos = (schedules: ScheduleProps[]) => {
    const firstAvailablePos = schedules.find((_s) => {
        // completely done
        const _done = _s.doneAt !== null && _s.quizDone;

        return _s.interval <= config.TIMELINE.MIN_ALLOWED_INTERVAL && _done === false;
    });

    if (firstAvailablePos) {
        return firstAvailablePos.position;
    }

    // get the last completed module position
    const lastFinishedPos = schedules
        .slice()
        .reverse()
        .find((_s) => {
            return _s.doneAt !== null && _s.quizDone;
        });

    return lastFinishedPos ? lastFinishedPos.position : schedules.length - 1;
};

/* check if schedule element for UI allowed */
export const isAllowed = (_card: ScheduleProps, _activePos: number) => {
    return _card.position <= _activePos;
};

/* load current timeline state */
export const fetchState = async (moduleId: string, context: AppProps) => {
    const user = context.appState.user;
    const schedules = user.dashboard.schedules;
    /* find active unit position */
    const activePos = getActivePos(schedules);
    /* find schedule for current unit, if allowed */
    const schedule = schedules.find((_card) => {
        return _card.position + 1 === Number(moduleId) && isAllowed(_card, activePos);
    });

    if (schedule) {
        const resource = user.resources.find((_r) => {
            return _r.id === schedule.workout.id;
        });
        if (resource) {
            /* get program weeks remaining */
            const weeksRemaining = getWeeksRemaining(config.TIMELINE.WEEKS_REMAINING, schedules[0].doneAt ?? 0);
            try {
                // load static data
                const program_id = user.programId;
                const _data = await dispatch(DispatchActions.getContentPostLogin, { program_id });
                const _moduleData = _data.modules.find((_m: { position: number }) => {
                    return _m.position === schedule.position;
                });
                const staticData = {
                    timeline: _data.timeline,
                    program: _data.program,
                    module: _moduleData,
                } as UnitStaticDataProps;
                return { schedule, resource, staticData, activePos, weeksRemaining };
            } catch (error: unknown) {
                const _err = error as Record<string, unknown>;
                throw new ApiError(_err);
            }
        }
        throw new Error(`Resource with ID ${schedule.workout.id} not found`);
    }
    throw new Error(`Module with ID ${moduleId} not allowed`);
};

export const getVListeners = (onEnded: (timestamp: number) => void): Listeners => {
    let isDone = false;
    return {
        loadstart: () => undefined,
        timeupdate: (player: VideoPlayer) => {
            const TRACK_COMPLETED_MIN_PROGRESS = 0.8;
            const currentTime = player.currentTime();
            const duration = player.duration();
            if (
                !isDone &&
                currentTime > 0 &&
                Math.floor(currentTime) >= Math.floor(duration * TRACK_COMPLETED_MIN_PROGRESS)
            ) {
                isDone = true;
                onEnded(Date.now());
            }
        },
        ended: () => undefined,
        fullscreenchange: (player: VideoPlayer) => {
            const missingTime = player.duration() - player.currentTime();

            const CONSIDER_AS_DONE_TIME = 3;
            if (!isDone && missingTime < CONSIDER_AS_DONE_TIME) {
                isDone = true;
                onEnded(Date.now());
            }
        },
    };
};
