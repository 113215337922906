import React from 'react';
import { Footer as FooterContainer } from '@gymondo/frontend-core/components';
import { Link } from 'react-router-dom';
import { PATHS } from '../../state/config';
import text from '../../lang';

interface FooterSmallProps {
    transparent?: boolean;
}

const FooterSmall: React.FC<FooterSmallProps> = ({ transparent }) => {
    return (
        <FooterContainer className={`footer-small${!transparent ? ' footer-small--white' : ''}`}>
            Es gelten unsere{' '}
            <Link to={PATHS.TERMS} className="link">
                {text('terms')}
            </Link>{' '}
            und{' '}
            <a href="https://www.gymondo.com/de/privacy/" className="link" target="_blank" rel="noreferrer">
                {text('privacy')}
            </a>{' '}
            —{' '}
            <Link to={PATHS.IMPRINT} className="link">
                {text('imprint')}
            </Link>
        </FooterContainer>
    );
};
1;
export default FooterSmall;
