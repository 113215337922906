import React, { useCallback, useEffect, useState } from 'react';
import { Formik, Field, Form, FieldProps } from 'formik';
import * as Yup from 'yup';
import { Button, Input, Radio, DatePicker } from '@gymondo/frontend-core/components';
import text from '../../lang';
import { AccountProps } from '../../state/context';
import { FormSubmissionError } from '..';
import { dispatchGetLocations } from '../../state/actions';

interface AccountFormProps {
    account: AccountProps;
    isFreebie: boolean;
    hasBirthDateField?: boolean;
    onSubmit: (data: AccountProps) => void;
}

const AccountForm: React.FC<AccountFormProps> = ({ account, isFreebie, hasBirthDateField = true, onSubmit }) => {
    const [locations, setLocations] = useState([]);
    const [locationId, setLocationId] = useState(0);
    const [formSubmissionError, setFormSubmissionError] = useState('');

    useEffect(() => {
        const _locId = (account.address?.location.id as number) || 0;
        if (_locId !== 0) {
            setLocationId(_locId);
        }
    }, [account]);

    const handleInputPostalCode = useCallback(async (postal_code: string) => {
        try {
            const _locations = await dispatchGetLocations(postal_code);
            setLocationId(0);
            setLocations(_locations.content);
        } catch (error: unknown) {
            const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
            console.log(_errData);
        }
    }, []);

    const getSelectedLocation = (id: number) => {
        return locations.find((loc: { id: number }) => loc.id === id) || {};
    };

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('bitte eingeben'),
        lastName: Yup.string().required('bitte eingeben'),
        birthDate: Yup.string().required('bitte auswählen'),
        street: Yup.string().concat(!isFreebie ? Yup.string().required('bitte eingeben') : Yup.string().notRequired()),
        number: Yup.string().concat(!isFreebie ? Yup.string().required('bitte eingeben') : Yup.string().notRequired()),
        postalCode: Yup.string().concat(
            !isFreebie
                ? Yup.string()
                      .required('bitte eingeben')
                      .test(
                          'is-pc',
                          'nicht gültig',
                          (value) => (value || '').length === 5 && (locationId > 0 || locations.length > 0),
                      )
                : Yup.string().notRequired(),
        ),
        location: Yup.string().concat(
            !isFreebie ? Yup.string().required('bitte eingeben') : Yup.string().notRequired(),
        ),
        gender: Yup.string().required('bitte auswählen'),
    });

    return (
        <Formik
            initialValues={{
                firstName: account.firstName || '',
                lastName: account.lastName || '',
                birthDate: account.birthDate || 0,
                street: account.address?.street || '',
                number: account.address?.number || '',
                postalCode: account.address?.location?.postalCode || '',
                location: account.address?.location?.location || '',
                gender: account.gender || '',
            }}
            enableReinitialize={true}
            validationSchema={validationSchema}
            onSubmit={async (data, { setSubmitting }) => {
                try {
                    const _account = {
                        id: account.id,
                        username: account.username,
                        firstName: data.firstName,
                        lastName: data.lastName,
                        birthDate: data.birthDate,
                        gender: data.gender,
                    } as AccountProps;
                    if (locationId !== 0) {
                        _account.address = {
                            street: data.street,
                            number: data.number,
                            location: {
                                id: locationId,
                                postalCode: data.postalCode,
                                location: data.location,
                            },
                        };
                        if (account.address && (account.address.id as number) !== 0) {
                            _account.address.id = account.address.id;
                        }
                    }
                    await onSubmit(_account);
                } catch (error: unknown) {
                    setSubmitting(false);
                    const _message = (error as Record<string, unknown>).message as string;
                    setFormSubmissionError(_message);
                }
            }}
        >
            {({ dirty, isSubmitting, isValid, setFieldValue }) => (
                <div className="container wrapper spacing-pt-pb-0">
                    <div className="row center-xs">
                        <div className="col-xs-12 col-sm-8 col-md-7 spacing-tb-xl account-wrapper">
                            <h6>{account.username}</h6>
                            {formSubmissionError && <FormSubmissionError message={formSubmissionError} className="" />}
                            <Form className="form-account">
                                <div className="row">
                                    <div className="col-xs-12 col-md">
                                        <Field name="firstName">
                                            {({ field, form: { errors, touched } }: FieldProps) => (
                                                <Input
                                                    field={{
                                                        ...field,
                                                        type: 'text',
                                                    }}
                                                    form={{
                                                        errors: errors,
                                                        touched: touched,
                                                    }}
                                                    label="Vorname"
                                                    multiline={false}
                                                />
                                            )}
                                        </Field>
                                        <div className="box-row"></div>
                                    </div>
                                    <div className="col-xs-12 col-md">
                                        <Field name="lastName">
                                            {({ field, form: { errors, touched } }: FieldProps) => (
                                                <Input
                                                    field={{
                                                        ...field,
                                                        type: 'text',
                                                    }}
                                                    form={{
                                                        errors: errors,
                                                        touched: touched,
                                                    }}
                                                    label="Nachname"
                                                    multiline={false}
                                                />
                                            )}
                                        </Field>
                                        <div className="box-row"></div>
                                    </div>
                                </div>
                                {hasBirthDateField && (
                                    <Field name="birthDate">
                                        {({
                                            field,
                                            form: { errors, touched, setFieldTouched, setFieldValue },
                                        }: FieldProps) => (
                                            <DatePicker
                                                field={{
                                                    ...field,
                                                }}
                                                form={{
                                                    setFieldTouched: setFieldTouched,
                                                    setFieldValue: setFieldValue,
                                                    errors: errors,
                                                    touched: touched,
                                                }}
                                                i18nValues={{
                                                    day: 'Tag',
                                                    month: 'Monat',
                                                    year: 'Jahr',
                                                }}
                                                label="Geburtstag"
                                                variant="primary"
                                            />
                                        )}
                                    </Field>
                                )}

                                {!isFreebie && (
                                    <>
                                        <div className="row">
                                            <div className="col-xs-12 col-md">
                                                <div className="box-first box-container">
                                                    <div className="row">
                                                        <div className="col-xs-8">
                                                            <Field name="street">
                                                                {({ field, form: { errors, touched } }: FieldProps) => (
                                                                    <Input
                                                                        field={{
                                                                            ...field,
                                                                            type: 'text',
                                                                        }}
                                                                        form={{
                                                                            errors: errors,
                                                                            touched: touched,
                                                                        }}
                                                                        label="Straße"
                                                                        multiline={false}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <div className="box-nested"></div>
                                                        </div>
                                                        <div className="col-xs-4">
                                                            <Field name="number">
                                                                {({ field, form: { errors, touched } }: FieldProps) => (
                                                                    <Input
                                                                        field={{
                                                                            ...field,
                                                                            type: 'text',
                                                                        }}
                                                                        form={{
                                                                            errors: errors,
                                                                            touched: touched,
                                                                        }}
                                                                        label="Nr."
                                                                        multiline={false}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <div className="box-nested"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-row"></div>
                                            </div>
                                            <div className="col-xs-12 col-md">
                                                <div className="box-first box-container">
                                                    <div className="row">
                                                        <div className="col-xs-4">
                                                            <Field name="postalCode">
                                                                {({ field, form: { errors, touched } }: FieldProps) => (
                                                                    <Input
                                                                        field={{
                                                                            ...field,
                                                                            type: 'text',
                                                                            onChange: (e: {
                                                                                target: { value: string };
                                                                            }) => {
                                                                                const value = e.target.value;
                                                                                const regex = new RegExp(
                                                                                    /^[0-9]{0,5}$/,
                                                                                );
                                                                                if (regex.test(value)) {
                                                                                    setFieldValue('postalCode', value);
                                                                                    // search only full postalcode
                                                                                    if (value.length === 5) {
                                                                                        handleInputPostalCode(value);
                                                                                    } else {
                                                                                        setFieldValue('location', '');
                                                                                    }
                                                                                }
                                                                            },
                                                                        }}
                                                                        form={{
                                                                            errors: errors,
                                                                            touched: touched,
                                                                        }}
                                                                        label="PLZ"
                                                                        multiline={false}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <div className="box-nested"></div>
                                                        </div>
                                                        <div className="col-xs-8 location">
                                                            <Field name="location">
                                                                {({ field, form: { errors, touched } }: FieldProps) => (
                                                                    <Input
                                                                        field={{
                                                                            ...field,
                                                                            type: 'text',
                                                                            //disabled: true,
                                                                        }}
                                                                        form={{
                                                                            errors: errors,
                                                                            touched: touched,
                                                                        }}
                                                                        label="Ort"
                                                                        multiline={false}
                                                                    />
                                                                )}
                                                            </Field>
                                                            {locations.length > 0 && (
                                                                <div className="location-list">
                                                                    <ul className="location-list-ul">
                                                                        {locations.map(
                                                                            (loc: Record<string, unknown>) => {
                                                                                return (
                                                                                    <li
                                                                                        key={loc.id as React.Key}
                                                                                        onClick={() => {
                                                                                            const _location: Record<
                                                                                                string,
                                                                                                unknown
                                                                                            > = getSelectedLocation(
                                                                                                loc.id as number,
                                                                                            );
                                                                                            if (
                                                                                                Object.keys(_location)
                                                                                                    .length > 0
                                                                                            ) {
                                                                                                setFieldValue(
                                                                                                    'postalCode',
                                                                                                    _location.postalCode,
                                                                                                );
                                                                                                setFieldValue(
                                                                                                    'location',
                                                                                                    _location.location,
                                                                                                );
                                                                                                setLocationId(
                                                                                                    _location.id as number,
                                                                                                );
                                                                                                setLocations([]);
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        {loc.location as string}
                                                                                    </li>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                            <div className="box-nested"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box-row"></div>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <Field name="gender">
                                    {({ field, form: { errors, touched } }: FieldProps) => (
                                        <Radio
                                            className="radio-container"
                                            field={{
                                                ...field,
                                            }}
                                            form={{
                                                errors: errors,
                                                touched: touched,
                                            }}
                                            options={[
                                                {
                                                    label: 'Weiblich',
                                                    value: 'FEMALE',
                                                },
                                                {
                                                    label: 'Männlich',
                                                    value: 'MALE',
                                                },
                                            ]}
                                            label="Geschlecht"
                                        />
                                    )}
                                </Field>
                                <Button htmlType="submit" disabled={!dirty || isSubmitting || !isValid}>
                                    {text('save_button')}
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            )}
        </Formik>
    );
};

export default AccountForm;
