import { Alert, Button } from '@gymondo/frontend-core/components';
import React from 'react';
import text from '../../lang';

export const DEFAULT_STYLES = {
    base: {
        fontSize: '16px',
        fontSmoothing: 'antialiased',
        color: '#555',
    },
    error: {
        color: '#d34548',
    },
    placeholder: {
        color: '#ddd',
    },
};

export const genericCTAButton = (
    isValid: boolean,
    isLoading: boolean,
    paymentMethod: string,
    onClick: () => void,
    ctaTextId = 'subscribe_card_pay',
    btnProps?: Record<string, unknown>,
) => (
    <Button
        disabled={!isValid || isLoading}
        loading={isLoading}
        onClick={onClick}
        {...btnProps}
        {...(!isValid || isLoading
            ? {}
            : {
                  'data-evt': 'click',
                  'data-category': 'checkout',
                  'data-action': 'payment_cta_clicked',
                  'data-label': paymentMethod,
              })}
    >
        {text(ctaTextId)}
    </Button>
);

interface TemplateProps {
    paymentMethod: string;
    legalDisclaimerId?: string;
    errorMessage: string;
    nextAction?: boolean;
    button: JSX.Element;
}

const PaymentTemplate: React.FC<TemplateProps> = ({
    legalDisclaimerId,
    errorMessage,
    nextAction,
    button,
    children,
}) => {
    return (
        <div className="payment-template">
            {!nextAction && (
                <div>
                    {errorMessage && <Alert>{text(errorMessage)}</Alert>}
                    {children}
                    <div className="button">{button}</div>
                    <p>{legalDisclaimerId && <div>{text(legalDisclaimerId)}</div>}</p>
                    <div className="encryption">{text('subscribe_encryption_disclaimer')}</div>
                </div>
            )}
            {nextAction && errorMessage && <Alert>{text(errorMessage)}</Alert>}
        </div>
    );
};

export default PaymentTemplate;
