import React, { useCallback, useContext, useState } from 'react';
import { Modal } from '@gymondo/frontend-core/components';
import { dispatchResetPassword, dispatchSignIn } from '../../state/actions';
import { AppStateContext } from '../../state/context';
import text from '../../lang';
import { ApiError } from '../../utils/error';

import { ResetPasswordForm, SignInForm } from '..';
import { PATHS } from '../../state/config';
import { redirect } from '../../utils/system';

interface SignInModalProps {
    onClose: () => void;
    resetPassword?: boolean;
}

const SHOW_RESET_PASSWORD_FORM = 'reset_password_form';
const SHOW_RESET_PASSWORD_INFO = 'reset_password_info';

const SignInModal: React.FC<SignInModalProps> = ({ onClose, resetPassword }) => {
    const [showResetPassword, setShowResetPassword] = useState(resetPassword ? SHOW_RESET_PASSWORD_FORM : '');
    const context = useContext(AppStateContext);

    const handleSignIn = useCallback(
        async ({ email, password }: { email: string; password: string }) => {
            try {
                await dispatchSignIn({ email, password }, context);
                onClose();
                // to timeline
                redirect(PATHS.HOMEPAGE);
            } catch (error: unknown) {
                const _err = error as Record<string, unknown>;
                throw new ApiError(_err);
            }
        },
        [context],
    );

    const handleClickResetPassword = useCallback(() => {
        // show reset password form
        setShowResetPassword(SHOW_RESET_PASSWORD_FORM);
    }, []);

    const handleResetPassword = useCallback(async ({ email }: { email: string }) => {
        const forwardUrl = window.location.href.replace(/#+$/, '');
        try {
            await dispatchResetPassword(email, forwardUrl);
            setShowResetPassword(SHOW_RESET_PASSWORD_INFO);
        } catch (error: unknown) {
            const _err = error as Record<string, unknown>;
            throw new ApiError(_err);
        }
    }, []);

    let Header, Component;
    switch (showResetPassword) {
        case SHOW_RESET_PASSWORD_FORM:
            Header = text('reset_password_text');
            Component = (
                <div className="p">
                    <ResetPasswordForm onSubmit={handleResetPassword} />
                </div>
            );
            break;
        case SHOW_RESET_PASSWORD_INFO:
            Header = text('reset_password_text');
            Component = <div className="p">{text('reset_password_success_note')}</div>;
            break;
        default:
            Header = text('signin_button');
            Component = (
                <div className="p">
                    <SignInForm onSubmit={handleSignIn} onClickResetPassword={handleClickResetPassword} />
                </div>
            );
    }

    return (
        <Modal dialogClassName="signin-modal" header={Header} hideFooter onClose={onClose}>
            <div
                style={{
                    width: '282px',
                }}
            >
                {Component}
            </div>
        </Modal>
    );
};

export default SignInModal;
