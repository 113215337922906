import React from 'react';
import { PROGRAM_STATUS } from 'state/config';
import { Product } from './api.types';
/* purchase history type */
export interface PurchaseHistoryItemProps {
    id: number;
    courseId: string;
    endDate: number;
    purchaseDate: number;
    paidPurchase: boolean;
    startDate: number;
    status: PROGRAM_STATUS.PURCHASED | PROGRAM_STATUS.INVALID;
    partner: string;
    price: number;
    currency: { code: string; symbol: string };
    productId: number;
    resourceId: number;
    title: string;
    type: string;
    trainer: { name: string; qualification: string };
    totalUnits: number;
    lastDoneUnit: number;
    awardRate: number;
    totalWorkouts?: number;
    channelId: number;
}
/* account type */
export interface AccountProps {
    id: number;
    username: string;
    firstName: string;
    lastName: string;
    birthDate: number;
    address?: {
        id?: number;
        street: string;
        number: string;
        location: { id: number; location: string; postalCode: string };
    };
    gender: string;
    healthInsurance?: string;
    roles?: string[];
}
/* prevention plan props */
export interface PlanProps {
    id: string;
    program: {
        id: number;
        goal: { name: string };
        title: string;
    };
    statistics: {
        completedWorkouts: number;
        completedWorkoutsDurationInSeconds: number;
        totalWorkouts: number;
    };
    status: string;
}
/* schedule type */
export interface ScheduleProps {
    doneAt: number;
    id: string;
    interval: number;
    position: number;
    status: string;
    workout: {
        id: number;
        description: string;
        durationSeconds: number;
        goal: {
            name: string;
        };
        images: {
            web: {
                thumbnail: {
                    large: string;
                    medium: string;
                };
            };
        };
        title: string;
    };
    quizDone: boolean;
}
/* static timeline unit data type */
export interface UnitStaticDataProps {
    timeline: Record<string, Record<string, string>>;
    program: Record<string, Record<string, string | string[]>>;
    module: Record<string, number & Record<string, string | number | string[] | Record<string, string>[]>>;
}
/* dashboard type */
export interface DashboardProps {
    plans: PlanProps[];
    schedules: ScheduleProps[];
}
/* resource type */
export interface ResourceProps {
    description: string;
    id: number;
    imageUrl: string;
    title: string;
    urls: Record<string, unknown>[];
    annotations: Record<string, unknown>[];
}
/* user type */
export interface UserProps {
    isLoaded: boolean;
    isFreebie: boolean;
    canPurchaseOn: number;
    onboardingCompleted: boolean;
    paymentCompleted: boolean;
    programStatus: PROGRAM_STATUS;
    purchaseHistory: PurchaseHistoryItemProps[];
    programId: number;
    productId: number;
    channelId?: number;
    planId: string;
    awardRate: number;
    account: AccountProps;
    dashboard: DashboardProps;
    resources: ResourceProps[];
    hasEndedPlans: boolean;
    products: Product[];
}

export interface PaymentSessionProps {
    session: Record<string, unknown> | null;
}
/* static json */
export interface StaticProps {
    [key: string]: Record<string, number & Record<string, string | number | string[] | Record<string, string>[]>>;
}
/* protected app type */
export interface ProtectedAppProps {
    isAuthenticated: boolean;
    user: UserProps;
    staticData: StaticProps;
    paymentSession: PaymentSessionProps;
}
/* app type */
export interface AppProps {
    appState: ProtectedAppProps;
    refreshAppState: (appState: ProtectedAppProps) => void;
}

export const initialUser = {
    isLoaded: false,
    isFreebie: false,
    canPurchaseOn: 0,
    onboardingCompleted: false,
    paymentCompleted: false,
    programStatus: PROGRAM_STATUS.UNSET,
    purchaseHistory: [] as PurchaseHistoryItemProps[],
    programId: 0,
    productId: 0,
    planId: '',
    awardRate: 1,
    account: {} as AccountProps,
    dashboard: { plans: [], schedules: [] } as DashboardProps,
    resources: [] as ResourceProps[],
    hasEndedPlans: false,
    products: [] as Product[],
};
export const initialPaymentSession = {
    session: null,
};
export const initialAppState = {
    isAuthenticated: false,
    user: initialUser,
    paymentSession: initialPaymentSession as PaymentSessionProps,
    staticData: {} as StaticProps,
};

export const initialAppStateContext = {
    appState: initialAppState,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    refreshAppState: (appState: ProtectedAppProps) => {
        /** */
    },
};

/* context */
export const AppStateContext: React.Context<AppProps> = React.createContext(initialAppStateContext);
