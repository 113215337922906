import React, { useCallback, useState } from 'react';
import { dispatch, DispatchActions } from 'state/actions';
import { PATHS } from 'state/config';
import { redirect } from 'utils/system';
import { Button } from '@gymondo/frontend-core/components';

const NotFound: React.FC = () => {
    const [isSubmitting, SetIsSubmitting] = useState(false);
    const handleGotoStart = useCallback(async () => {
        // sign out and go to LP
        try {
            SetIsSubmitting(true);
            await dispatch(DispatchActions.signOut);
            redirect(PATHS.HOMEPAGE);
        } catch (error: unknown) {
            const _errData = (error as Record<string, unknown>).data as Record<string, unknown>;
            console.log(_errData);
        }
    }, []);

    return (
        <>
            <div className="container_ wrapper legal first-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                        <h1>Beginne dein Präventionstraining</h1>
                        <p className="not_found-text">
                            Du hast auf dieser Plattform noch kein Präventionsprogramm gestartet.
                            <br />
                            Mach noch heute den ersten Schritt in ein fittes, gesundes Leben!
                        </p>
                        <Button onClick={handleGotoStart} disabled={isSubmitting} fluid className="not_found-cta">
                            Programm wählen
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
