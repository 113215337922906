import config, { Partner, PATHS } from '../config';

const getProgramConfig = (index: number) => {
    return config.PROGRAMS[index];
};

export const getBackfitProgram = () => {
    return getProgramConfig(0);
};

export const getGanzkoerperProgram = () => {
    return getProgramConfig(1);
};

export const getProgram = (id: number) => {
    return config.PROGRAMS.find((pr) => pr.ID === id) || null;
};

export const getProgramByName = (name: string) => {
    return config.PROGRAMS.find((pr) => pr.NAME === name) || null;
};

export const getRegisterProgramUrl = (programName: string) => {
    return PATHS.REGISTRATION_PROGRAM.replace(':programName', programName);
};

export const getProgramPath = (programName: string) => {
    return `/${programName}`;
};

/* partner and product */

export const getPartner = (program_id: number, partner_key = '') => {
    const program = getProgram(program_id);
    if (program === null) {
        return null;
    }
    return program.PARTNERS.find((p) => p.KEY === partner_key) || null;
};

export const isPartnerKeyExists = (partner_key: string) => {
    let exists = false;
    for (const pr of config.PROGRAMS) {
        const _partner = pr.PARTNERS.find((p) => p.KEY === partner_key);
        if (_partner) {
            exists = true;
        }
    }
    return exists;
};

export const getPartnerByProductId = (product_id: number): Partner | null => {
    let partner = null;
    for (const pr of config.PROGRAMS) {
        const _partner = pr.PARTNERS.find((p) => p.PRODUCT_ID === product_id);
        if (_partner) {
            partner = _partner;
            break;
        }
    }
    return partner;
};
