import React from 'react';
import { Link } from 'react-router-dom';

interface ConditionalLinkProps {
    to: string;
    condition: boolean;
}

const ConditionalLink: React.FC<ConditionalLinkProps> = ({ to, condition, children }) => {
    return condition ? <Link to={to}>{children}</Link> : <>{children}</>;
};

export default ConditionalLink;
